import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Subject, Observable } from "rxjs";
import { tap, takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { url } from "./../../../assets/environment_urls/environment_urls";
import { logoutUrl } from "./../../../assets/environment_urls/logout_urls";
import { isNullOrUndefined } from "util";

@Injectable()
export class ApiService {
  triggerConfigureDevice = new BehaviorSubject<boolean>(false);
  getTriggerConfigureDevice = this.triggerConfigureDevice.asObservable();
  closeConfigureDeviceModal = new BehaviorSubject<boolean>(false);
  getCloseConfigureDeviceModal = this.closeConfigureDeviceModal.asObservable();
  configurationData = new BehaviorSubject<any>("");
  getConfigurationData = this.configurationData.asObservable();
  featureFileConfigData = this.configurationData.asObservable();
  getconfigData = this.configurationData.asObservable();
  aloConfigurationData = this.configurationData.asObservable();
  setZtvValues = new BehaviorSubject<any>("");
  getZtvValues = this.setZtvValues.asObservable();
  setztvoutputValues = new BehaviorSubject<any>("");
  getztvoutputValues = this.setztvoutputValues.asObservable();
  setZtvEdited = new BehaviorSubject<any>("");
  getZtvEdited = this.setZtvEdited.asObservable();
  setztvoutputEdited = new BehaviorSubject<any>("");
  readDeviceSubject = new BehaviorSubject<any>("");
  getReadDeviceSubject = this.readDeviceSubject.asObservable();
  getztvoutputEdited = this.setztvoutputEdited.asObservable();
  setztvInputError = new BehaviorSubject<any>("");
  getztvInputError = this.setztvInputError.asObservable();
  setztvoutputInputError = new BehaviorSubject<any>("");
  getztvoutputInputError = this.setztvoutputInputError.asObservable();
  formInputValidation = new BehaviorSubject<any>("");
  getformInputValidation = this.formInputValidation.asObservable();
  refreshZtvData = new BehaviorSubject<any>("");
  logoutSubject = new BehaviorSubject<any>("");
  getLogoutSubject = this.logoutSubject.asObservable();
  touchDimObj = {};
  constructor(private http: HttpClient) {
    let dataZtv = JSON.stringify(
      JSON.parse(localStorage.getItem("refreshZtvData") as any)
    );
    this.refreshZtvData.next(dataZtv);
  }
  getRefreshZtvData = this.refreshZtvData.asObservable();
  removeRefreshZtvData(): void {
    this.setRefreshZtvData("");
    this.refreshZtvData.next("");
  }
  setRefreshZtvData(value) {
    let data = Object.assign({}, value);
    this.refreshZtvData.next(JSON.stringify(data) as any);
    localStorage.setItem("refreshZtvData", JSON.stringify(data));
  }
  graphData = new BehaviorSubject<any>("");
  getGraphData = this.graphData.asObservable();

  cloSchema = new BehaviorSubject<boolean>(false);
  getCloSchema = this.cloSchema.asObservable();

  cloGraphData = new BehaviorSubject<any>("");
  getCloGraphData = this.cloGraphData.asObservable();
  //DYNA
  graphDynaData = new BehaviorSubject<any>("");
  getGraphDynaData = this.graphDynaData.asObservable();

  envData = new BehaviorSubject([]);
  getEnvData = this.envData.asObservable();

  readData = new BehaviorSubject([]);
  getReadData = this.readData.asObservable();

  changedGraphData = new BehaviorSubject([]);
  getChangedGraphData = this.changedGraphData.asObservable();

  unslecteduneditedFeatures = new BehaviorSubject([]);
  getUnSelectedUnEditedData = this.unslecteduneditedFeatures.asObservable();

  enableGraph = new BehaviorSubject<boolean | any>(null);
  checkEnableGraph = this.enableGraph.asObservable();

  enableCloLiteGraph = new BehaviorSubject<boolean>(false);
  checkCloLiteEnableGraph = this.enableCloLiteGraph.asObservable();

  //DYNA
  enableDynaGraph = new BehaviorSubject<boolean>(false);
  checkEnableDynaGraph = this.enableDynaGraph.asObservable();

  aloValidData = new BehaviorSubject<boolean>(false);
  checkALOGraph = this.aloValidData.asObservable();

  aloPercent = new BehaviorSubject("");
  getAloValue = this.aloPercent.asObservable();

  quickAloPercent = new BehaviorSubject("");
  getquickAloPercewnt = this.quickAloPercent.asObservable();

  quickConfig = new BehaviorSubject("");
  getquickConfig = this.quickConfig.asObservable();

  setAloQuick = new BehaviorSubject("");
  getQuickAlo = this.setAloQuick.asObservable();

  cloLoadFeatureFileConfigure = new BehaviorSubject("Normal");
  getCloLoadFeatureFileConfigure =
    this.cloLoadFeatureFileConfigure.asObservable();
  envURL;
  keyNumber;

  isGraphValid = new BehaviorSubject("");
  checkGraphIsValid = this.isGraphValid.asObservable();

  isConfigData = new BehaviorSubject("");
  checkConfigData = this.isConfigData.asObservable();

  graphIsEditedData = new BehaviorSubject([]);
  checkGraphValueEdited = this.graphIsEditedData.asObservable();

  //DYNA
  dynagraphIsEditedData = new BehaviorSubject([]);
  checkDynaGraphValueEdited = this.dynagraphIsEditedData.asObservable();

  closeSelectDevicePopup = new BehaviorSubject("");
  checkCloseSelectDevicePopup = this.closeSelectDevicePopup.asObservable();

  closeConfigPopup = new BehaviorSubject("");
  checkCloseConfigPopup = this.closeConfigPopup.asObservable();

  isAOCValid = new BehaviorSubject("");
  checkAOCIsValid = this.isAOCValid.asObservable();

  enableRSet = new BehaviorSubject<boolean>(false);
  checkEnableRSet = this.enableRSet.asObservable();

  enableAlo = new BehaviorSubject<boolean>(false);
  checkAloenable = this.enableAlo.asObservable();

  enablePSU = new BehaviorSubject(false);
  checkPSUenable = this.enablePSU.asObservable();

  enableHBPSU = new BehaviorSubject<boolean>(false);
  checkHBPSUenable = this.enableHBPSU.asObservable();

  enableLGPSU = new BehaviorSubject<boolean>(false);
  checkLGPSUenable = this.enableLGPSU.asObservable();

  onSliderClick = new BehaviorSubject<boolean>(false);
  aocSliderCick = this.onSliderClick.asObservable();

  startupSlider = new BehaviorSubject("");
  startupSliderClick = this.startupSlider.asObservable();

  isStartupValueValid = new BehaviorSubject("");
  checkStartupIsValid = this.isStartupValueValid.asObservable();

  graphRepresentation = new BehaviorSubject("");
  getGraphRepresentation = this.graphRepresentation.asObservable();

  //DYNA
  graphDynaRepresentation = new BehaviorSubject("");
  getDynaGraphRepresentation = this.graphDynaRepresentation.asObservable();

  columnSelection = new BehaviorSubject("");
  getColumnSelection = this.columnSelection.asObservable();

  filterOnChecked = new BehaviorSubject("");
  filterRemove = this.filterOnChecked.asObservable();

  aocValue = new BehaviorSubject("");
  getAocValue = this.aocValue.asObservable();
  touchDimValue = new BehaviorSubject("");
  gettouchDimValue = this.touchDimValue.asObservable();
  luminValue = new BehaviorSubject("");
  getLuminValue = this.luminValue.asObservable();

  luminError = new BehaviorSubject<boolean>(false);
  getLuminFormError = this.luminError.asObservable();

  luminValueEdited = new BehaviorSubject<boolean>(false);
  getLuminValueEdited = this.luminValueEdited.asObservable();

  graphLiteValue = new BehaviorSubject("");
  getLiteValueEdited = this.graphLiteValue.asObservable();

  cloLiteTxt = new BehaviorSubject("");
  getCloLiteHead = this.cloLiteTxt.asObservable();

  cloTxt = new BehaviorSubject("");
  getCloHead = this.cloTxt.asObservable();

  dynaDimmerText = new BehaviorSubject("");
  getDynaDimmerText = this.dynaDimmerText.asObservable();

  cloliteValues = new BehaviorSubject("");
  getliteValues = this.cloliteValues.asObservable();

  featurePage = new BehaviorSubject("");
  getFeaturePage = this.featurePage.asObservable();

  uncheckValue = new BehaviorSubject("");
  getuncheckValue = this.uncheckValue.asObservable();

  setToDefault = new BehaviorSubject("");
  getDefaultFilter = this.setToDefault.asObservable();

  setValidation = new BehaviorSubject("");
  getValidation = this.setValidation.asObservable();

  liteHour = new BehaviorSubject("");
  getliteHour = this.liteHour.asObservable();
  LitePower = new BehaviorSubject("");
  getlitePower = this.LitePower.asObservable();
  //DYNA Properties
  dynaTimeHours = new BehaviorSubject("");
  getDynaTimeHours = this.dynaTimeHours.asObservable();

  dynaOutputLevel = new BehaviorSubject("");
  getDynaOutputLevel = this.dynaOutputLevel.asObservable();

  dynaFadeTimess = new BehaviorSubject("");
  getDynaFadeTimess = this.dynaFadeTimess.asObservable();

  dynaDimmerEnabledMode = new BehaviorSubject(false);
  getDynaDimmerEnabledMode = this.dynaDimmerEnabledMode.asObservable();

  dynaMidPointShiftMode = new BehaviorSubject(true);
  getDynaMidPointShiftMode = this.dynaMidPointShiftMode.asObservable();

  dynaDimmerEdited = new BehaviorSubject(false);
  getDynaDimmerEditedRsp = this.dynaDimmerEdited.asObservable();

  dynaMidPointShiftValue = new BehaviorSubject(0);
  getDynaMidPointShiftValue = this.dynaMidPointShiftValue.asObservable();

  dynaMIdPointShiftDefaultProperties = new BehaviorSubject([]);
  getDynaMidPointSHiftDefaultProperties =
    this.dynaMIdPointShiftDefaultProperties.asObservable();

  dynaDefaultValues = new BehaviorSubject([]);
  getDynaDefaultValues = this.dynaDefaultValues.asObservable();
  dynaOverrideMode = new BehaviorSubject(false);
  getDynaOverrideMode = this.dynaOverrideMode.asObservable();

  dynaTimeReferencedMode = new BehaviorSubject(false);
  getDynaTimeReferencedMode = this.dynaTimeReferencedMode.asObservable();

  dynadefaultOverrideMode = new BehaviorSubject(false);
  getDefaultDynaOverrideMode = this.dynadefaultOverrideMode.asObservable();

  showNextMultiSuccessBtn = new BehaviorSubject(false);
  getShowNextMultiSuccessBtn = this.showNextMultiSuccessBtn.asObservable();

  userBoxSizeValue = new BehaviorSubject(false);
  getUserBoxSizeValue = this.userBoxSizeValue.asObservable();

  dynaDefaultTimeReferencedMode = new BehaviorSubject(false);
  getDefaultDynaTimeReferencedMode =
    this.dynaDefaultTimeReferencedMode.asObservable();

  dynaDefaultDimmerEnabledMode = new BehaviorSubject(false);
  getDefaultDynaDimmerEnabledMode =
    this.dynaDefaultDimmerEnabledMode.asObservable();

  dynaDimmerScene1 = new BehaviorSubject(true);
  getDynaDimmerScene1 = this.dynaDimmerScene1.asObservable();

  dynaDimmerAllScenes = new BehaviorSubject([]);
  getDynaDimmerAllScenes = this.dynaDimmerAllScenes.asObservable();

  dynaDimmerScene2 = new BehaviorSubject(false);
  getDynaDimmerScene2 = this.dynaDimmerScene2.asObservable();

  dynaDimmerScene3 = new BehaviorSubject(false);
  getDynaDimmerScene3 = this.dynaDimmerScene3.asObservable();

  dynaDimmerScene4 = new BehaviorSubject(false);
  getDynaDimmerScene4 = this.dynaDimmerScene4.asObservable();

  dynaDimmerScene5 = new BehaviorSubject(false);
  getDynaDimmerScene5 = this.dynaDimmerScene5.asObservable();

  dynaDependencyProp = new BehaviorSubject(false);
  getDynaDependencyProp = this.dynaDependencyProp.asObservable();

  dynaShowEnabled = new BehaviorSubject(false);
  getDynaShowEnabled = this.dynaShowEnabled.asObservable();

  dynaShowOverrideEnabled = new BehaviorSubject(false);
  getDynaShowOverrideEnabled = this.dynaShowOverrideEnabled.asObservable();

  dynaShowTimeReference = new BehaviorSubject(false);
  getDynaShowTimeReference = this.dynaShowTimeReference.asObservable();

  finalDynaDimmerData = new BehaviorSubject("");
  getFinalDynaDimmerData = this.finalDynaDimmerData.asObservable();

  //DYNA PROPERTIS END
  aocEdited = new BehaviorSubject<boolean>(false);
  getaocValidate = this.aocEdited.asObservable();

  isStartupEditedValue = new BehaviorSubject<boolean>(false);
  getstartupValidate = this.aocEdited.asObservable();

  modes = new BehaviorSubject("");
  getmodes = this.modes.asObservable();

  callConfig = new BehaviorSubject("");
  getConfigMode = this.callConfig.asObservable();

  removeSelect = new BehaviorSubject("");
  getSelect = this.removeSelect.asObservable();

  devices = new BehaviorSubject("");
  searchDevices = this.devices.asObservable();

  clear = new BehaviorSubject("");
  clearFilterDevices = this.clear.asObservable();

  aloFeatApi = new BehaviorSubject(false);
  getAloFeatApi = this.aloFeatApi.asObservable();
  aocFeatApi = new BehaviorSubject(false);
  getAocFeatApi = this.aocFeatApi.asObservable();
  cloFeatApi = new BehaviorSubject(false);
  getCloFeatApi = this.cloFeatApi.asObservable();
  cloLiteFeatApi = new BehaviorSubject(false);
  getCloLiteFeatApi = this.cloLiteFeatApi.asObservable();
  codedMainsFeatApi = new BehaviorSubject(false);
  getCodedMainsFeatApi = this.codedMainsFeatApi.asObservable();
  lineSwicthFeatApi = new BehaviorSubject(false);
  getLineSwitchFeatApi = this.lineSwicthFeatApi.asObservable();
  owpFeatApi = new BehaviorSubject(false);
  getOwpFeatApi = this.owpFeatApi.asObservable();
  dimmingInterfaceFeatApi = new BehaviorSubject(false);
  getDimmingInterfaceFeatApi = this.dimmingInterfaceFeatApi.asObservable();
  daliPSUFeatApi = new BehaviorSubject(false);
  getDaliPSUFeatApi = this.daliPSUFeatApi.asObservable();
  ztvFeatApi = new BehaviorSubject(false);
  getZtvFeatApi = this.ztvFeatApi.asObservable();
  ztvoutputFeatApi = new BehaviorSubject(false);
  getztvoutputFeatApi = this.ztvoutputFeatApi.asObservable();
  rsoFeatApi = new BehaviorSubject(false);
  getrsoFeatApi = this.rsoFeatApi.asObservable();
  lipFeatApi = new BehaviorSubject(false);
  getlipFeatApi = this.lipFeatApi.asObservable();
  lumininfoformatFeatApi = new BehaviorSubject(false);
  getLumininfoformatFeatApi = this.lumininfoformatFeatApi.asObservable();
  startUpTimeFeatApi = new BehaviorSubject(false);
  getStartUpTimeFeatApi = this.startUpTimeFeatApi.asObservable();
  dtlFeatApi = new BehaviorSubject(false);
  getDtlFeatApi = this.dtlFeatApi.asObservable();
  corridorFeatApi = new BehaviorSubject(false);
  getCorridoeFeatApi = this.corridorFeatApi.asObservable();
  mtpFeatApi = new BehaviorSubject(false);
  getMtpFeatApi = this.mtpFeatApi.asObservable();
  ampDimFeatApi = new BehaviorSubject(false);
  getampDimFeatApi = this.ampDimFeatApi.asObservable();
  dcEmergencyFeatApi = new BehaviorSubject(false);
  getDCEmergencyFeatApi = this.dcEmergencyFeatApi.asObservable();
  minDimLevelFeatApi = new BehaviorSubject(false);
  getminDimLevelFeatApi = this.minDimLevelFeatApi.asObservable();
  touchDimFeatApi = new BehaviorSubject(false);
  getTouchDimFeatApi = this.touchDimFeatApi.asObservable();
  diia253maintenanceFeatApi = new BehaviorSubject(false);
  getdiia253maintenanceFeatApi = this.diia253maintenanceFeatApi.asObservable();
  dynaDimmerFeatApi = new BehaviorSubject(false);
  getDynaDimmerFeatApi = this.dynaDimmerFeatApi.asObservable();

  //ALO
  aloDefaultColor = new BehaviorSubject("");
  getAloDefaultColor = this.aloDefaultColor.asObservable();

  aloDefaultValue = new BehaviorSubject("");
  getAloDefaultValue = this.aloDefaultValue.asObservable();

  tcDeltaMTPtValue = new BehaviorSubject(0);
  getTCDeltaValueMTP = this.tcDeltaMTPtValue.asObservable();

  mtpDimStartMax = new BehaviorSubject(0);
  getMtpDimStartMax = this.mtpDimStartMax.asObservable();

  mtpDimStopMax = new BehaviorSubject(0);
  getMtpDimStopMax = this.mtpDimStopMax.asObservable();

  mtpDimLevelMax = new BehaviorSubject(0);
  getMtpDimLevelMax = this.mtpDimLevelMax.asObservable();

  //STARTUP
  startupDefaultColor = new BehaviorSubject("");
  getStartupDefaultColor = this.startupDefaultColor.asObservable();

  startupDefaultValue = new BehaviorSubject("");
  getStartupDefaultValue = this.startupDefaultValue.asObservable();

  //AOC
  aocDefaultColor = new BehaviorSubject("");
  getAocDefaultColor = this.aocDefaultColor.asObservable();

  aocDefaultValue = new BehaviorSubject("");
  getAocDefaultValue = this.aocDefaultValue.asObservable();

  //ZTV
  ztvRestoreBtnClick = new BehaviorSubject("");
  getztvRestoreBtnClick = this.ztvRestoreBtnClick.asObservable();
  ztvoutputRestoreBtnClick = new BehaviorSubject("");
  getztvoutputRestoreBtnClick = this.ztvoutputRestoreBtnClick.asObservable();
  //SR PSU
  srpsuDefaultColor = new BehaviorSubject("");
  getSrpsuDefaultColor = this.srpsuDefaultColor.asObservable();

  srpsuDefaultValue = new BehaviorSubject("");
  getSrPsuDefaultValue = this.srpsuDefaultValue.asObservable();
  //diia253maintenance
  diia253maintenanceDefaultValue = new BehaviorSubject("");
  getdiia253maintenanceDefaultValue =
    this.diia253maintenanceDefaultValue.asObservable();
  //CLO
  cloDefaultColor = new BehaviorSubject("");
  getCloDefaultColor = this.cloDefaultColor.asObservable();

  cloDefaultValue = new BehaviorSubject("");
  getCloDefaultValue = this.cloDefaultValue.asObservable();

  //DYNA
  dynaDefaultColor = new BehaviorSubject("");
  getDynaDefaultColor = this.dynaDefaultColor.asObservable();

  isDynaError = new BehaviorSubject(false);
  getIsDynaError = this.isDynaError.asObservable();

  dynaDefaultValue = new BehaviorSubject("");
  getDynaDefaultValue = this.dynaDefaultValue.asObservable();

  ampdimDefaultColor = new BehaviorSubject("");
  getAmpDimDefaultColor = this.ampdimDefaultColor.asObservable();

  ampdimDefaultValue = new BehaviorSubject("");
  getAmpDimDefaultValue = this.ampdimDefaultValue.asObservable();
  //LUMIN
  luminDefaultColor = new BehaviorSubject("");
  getLuminDefaultColor = this.luminDefaultColor.asObservable();

  luminDefaultValue = new BehaviorSubject("");
  getLuminDefaultValue = this.luminDefaultValue.asObservable();

  selectedModal = new BehaviorSubject("");
  getSelectedModal = this.selectedModal.asObservable();

  configMethod = new BehaviorSubject("");
  getConfigMethod = this.configMethod.asObservable();

  isDisable = new BehaviorSubject("");
  makeDisable = this.isDisable.asObservable();

  Reload = new BehaviorSubject("");
  getReload = this.Reload.asObservable();

  closeIt = new BehaviorSubject("");
  closeModal = this.closeIt.asObservable();

  information = new BehaviorSubject("");
  getReaderInfo = this.information.asObservable();
  //DtL
  setDtlData = new BehaviorSubject("");
  getDtldData = this.setDtlData.asObservable();

  setMinMax = new BehaviorSubject("");
  getMinMaxData = this.setMinMax.asObservable();

  setdtlInputError = new BehaviorSubject<any>("");
  getdtlInputError = this.setdtlInputError.asObservable();

  setDtlEdited = new BehaviorSubject<any>("");
  getDtlEdited = this.setDtlEdited.asObservable();

  DtlDefaultColor = new BehaviorSubject("");
  getdtlDefaultColor = this.DtlDefaultColor.asObservable();

  //CLOlite
  setCloLiteData = new BehaviorSubject("");
  getCloLitedData = this.setCloLiteData.asObservable();

  setCLOliteInputError = new BehaviorSubject<any>("");
  getCLOliteInputError = this.setCLOliteInputError.asObservable();

  setCLOliteEdited = new BehaviorSubject<any>("");
  getCLOliteEdited = this.setCLOliteEdited.asObservable();

  cloLiteDefaultColor = new BehaviorSubject("");
  getCLOliteDefaultColor = this.cloLiteDefaultColor.asObservable();

  cloLiteDefaultValue = new BehaviorSubject("");
  getCloLiteDefaultValue = this.cloLiteDefaultValue.asObservable();

  //Min Dim Level

  setMinDimLevelData = new BehaviorSubject("");
  getMinDimLevelData = this.setMinDimLevelData.asObservable();

  setminDimLevelInputError = new BehaviorSubject<any>("");
  getminDimLevelInputError = this.setminDimLevelInputError.asObservable();

  setMinDimLevelEdited = new BehaviorSubject<any>("");
  getMinDimLevelEdited = this.setMinDimLevelEdited.asObservable();

  minDimLevelDefaultColor = new BehaviorSubject("");
  getminDimLevelDefaultColor = this.minDimLevelDefaultColor.asObservable();
  //End MIN DIM level

  availableFeatures = new BehaviorSubject("");
  getAvailableFeature = this.availableFeatures.asObservable();

  availableFeaturesMulti = new BehaviorSubject("");
  getAvailableFeatureMulti = this.availableFeaturesMulti.asObservable();

  corruptFeat = new BehaviorSubject("");
  getCorruptFeature = this.corruptFeat.asObservable();

  readDataValuesFeature = new BehaviorSubject("");
  getReaderValues = this.readDataValuesFeature.asObservable();

  nonCorrupt = new BehaviorSubject("");
  getNonCorruptFeature = this.nonCorrupt.asObservable();

  redFeatures = new BehaviorSubject("");
  getRedFeature = this.redFeatures.asObservable();

  readerLoad = new BehaviorSubject("");
  getReloadReader = this.readerLoad.asObservable();

  corridordata = new BehaviorSubject("");
  getCorridorData = this.corridordata.asObservable();

  corridorgraphdata = new BehaviorSubject("");
  getCorridorGraphData = this.corridorgraphdata.asObservable();
  mtpgraphdata = new BehaviorSubject<any>("");
  getMtpGraphData = this.mtpgraphdata.asObservable();
  mtpdata = new BehaviorSubject("");
  getMtpData = this.mtpdata.asObservable();
  ztvoutputdata = new BehaviorSubject("");
  getztvoutputData = this.ztvoutputdata.asObservable();
  rsodata = new BehaviorSubject("");
  getrsoData = this.rsodata.asObservable();
  lipdata = new BehaviorSubject("");
  getlipData = this.lipdata.asObservable();
  diia253maintenancedata = new BehaviorSubject("");
  getdiia253maintenanceData = this.diia253maintenancedata.asObservable();
  ampDimdata = new BehaviorSubject("");
  getAmpDimData = this.ampDimdata.asObservable();
  ampDimGraphdata = new BehaviorSubject<any>("");
  getAmpDimGraphdata = this.ampDimGraphdata.asObservable();

  corridorEditValueAdd = new BehaviorSubject(false);
  getCorridorEdtedData = this.corridorEditValueAdd.asObservable();
  mtpEditValueAdd = new BehaviorSubject(false);
  getMtpEdtedData = this.mtpEditValueAdd.asObservable();
  ztvoutputEditValueAdd = new BehaviorSubject(false);
  getztvoutputEdtedData = this.ztvoutputEditValueAdd.asObservable();
  lipEditValueAdd = new BehaviorSubject(false);
  getlipEdtedData = this.lipEditValueAdd.asObservable();
  rsoEditValueAdd = new BehaviorSubject(false);
  getrsoEdtedData = this.rsoEditValueAdd.asObservable();
  diia253maintenanceEditValueAdd = new BehaviorSubject(false);
  getdiia253maintenanceEdtedData =
    this.diia253maintenanceEditValueAdd.asObservable();
  setMtpDefault = new BehaviorSubject(false);
  getMtpDefault = this.setMtpDefault.asObservable();
  setztvoutputDefault = new BehaviorSubject(false);
  getztvoutputDefault = this.setztvoutputDefault.asObservable();
  setlipDefault = new BehaviorSubject(false);
  getlipDefault = this.setlipDefault.asObservable();
  setrsoDefault = new BehaviorSubject(false);
  getrsoDefault = this.setrsoDefault.asObservable();
  setCorridorDefault = new BehaviorSubject(false);
  getCorridorDefault = this.setCorridorDefault.asObservable();
  lineswitchdata = new BehaviorSubject("");
  getLineSwitchData = this.lineswitchdata.asObservable();
  codedmainsdata = new BehaviorSubject("");
  getcodedmainsData = this.codedmainsdata.asObservable();
  owpdata = new BehaviorSubject("");
  getowpData = this.owpdata.asObservable();
  lineSwitchEditValueAdd = new BehaviorSubject(false);
  getLineSwitchEdtedData = this.lineSwitchEditValueAdd.asObservable();
  owpEditValueAdd = new BehaviorSubject(false);
  getOwpEdtedData = this.owpEditValueAdd.asObservable();
  codedmainsEditValueAdd = new BehaviorSubject(false);
  getcodedmainsEdtedData = this.codedmainsEditValueAdd.asObservable();
  setLineSwitchDefault = new BehaviorSubject(false);
  getLineSwitchDefault = this.setLineSwitchDefault.asObservable();
  setcodedmainsDefault = new BehaviorSubject(false);
  getcodedmainsDefault = this.setcodedmainsDefault.asObservable();
  setAmpDimDefault = new BehaviorSubject(false);
  getAmpDimDefault = this.setAmpDimDefault.asObservable();
  lineSwitchDefaultValue = new BehaviorSubject("");
  getLineSwitchDefaultValue = this.lineSwitchDefaultValue.asObservable();
  owpDefaultValue = new BehaviorSubject("");
  getOwpDefaultValue = this.owpDefaultValue.asObservable();
  owpRestoreDefaultValue = new BehaviorSubject("");
  getowpRestoreDefaultValue = this.owpRestoreDefaultValue.asObservable();
  codedmainsDefaultValue = new BehaviorSubject("");
  getcodedmainsDefaultValue = this.codedmainsDefaultValue.asObservable();
  mtpDefaultValue = new BehaviorSubject("");
  getmtpDefaultValue = this.mtpDefaultValue.asObservable();
  ztvoutputDefaultValue = new BehaviorSubject("");
  getztvoutputDefaultValue = this.ztvoutputDefaultValue.asObservable();
  lipDefaultValue = new BehaviorSubject("");
  getlipDefaultValue = this.lipDefaultValue.asObservable();
  rsoDefaultValue = new BehaviorSubject("");
  getrsoDefaultValue = this.rsoDefaultValue.asObservable();
  codedmainsError = new BehaviorSubject(false);
  getcodedmainsError = this.codedmainsError.asObservable();
  owpError = new BehaviorSubject(false);
  getowpError = this.owpError.asObservable();
  lineSwitchError = new BehaviorSubject(false);
  getLineSwitchError = this.lineSwitchError.asObservable();
  corridorError = new BehaviorSubject(false);
  getCorridorError = this.corridorError.asObservable();
  mtpError = new BehaviorSubject(false);
  getMtpError = this.mtpError.asObservable();
  ztvoutputError = new BehaviorSubject(false);
  getztvoutputError = this.ztvoutputError.asObservable();
  rsoError = new BehaviorSubject(false);
  getrsoError = this.rsoError.asObservable();
  lipError = new BehaviorSubject(false);
  getlipError = this.lipError.asObservable();
  diia253maintenanceError = new BehaviorSubject(false);
  getdiia253maintenanceError = this.diia253maintenanceError.asObservable();
  ampdimError = new BehaviorSubject(false);
  getampdimError = this.ampdimError.asObservable();
  dcemergencyDefaultColor = new BehaviorSubject("");
  getDcemergencyDefaultColor = this.dcemergencyDefaultColor.asObservable();

  dcemergencyEditedData = new BehaviorSubject("");
  getDcemergencyEditedData = this.dcemergencyEditedData.asObservable();

  isDcemergencyValueValid = new BehaviorSubject("");
  checkDcemergencyIsValid = this.isDcemergencyValueValid.asObservable();

  setDcemergencyDefaultData = new BehaviorSubject<any>("");
  getDcemergencyDefaultData = this.setDcemergencyDefaultData.asObservable();

  setDcemergencyInputError = new BehaviorSubject<any>("");
  getDcemergencyInputError = this.setDcemergencyInputError.asObservable();
  setLineSwitchInputError = new BehaviorSubject<any>("");
  getLineSwitchInputError = this.setLineSwitchInputError.asObservable();
  //TouchDim
  touchDimDefaultColor = new BehaviorSubject("");
  getTouchDimDefaultColor = this.touchDimDefaultColor.asObservable();
  touchDimEditedData = new BehaviorSubject("");
  getTouchDimEditedData = this.touchDimEditedData.asObservable();
  owpDefaultColor = new BehaviorSubject("");
  getowpDefaultColor = this.owpDefaultColor.asObservable();
  diia253maintenanceDefaultColor = new BehaviorSubject("");
  getdiia253maintenanceDefaultColor =
    this.diia253maintenanceDefaultColor.asObservable();
  diia253maintenanceEditedData = new BehaviorSubject("");
  getdiia253maintenanceEditedData = this.diia253maintenancedata.asObservable();
  owpEditedData = new BehaviorSubject("");
  getowpEditedData = this.owpdata.asObservable();
  isTouchDimValueValid = new BehaviorSubject("");
  checkTouchDimIsValid = this.isTouchDimValueValid.asObservable();
  setdiia253maintenanceDefaultData = new BehaviorSubject<any>("");
  getdiia253maintenanceDefaultData =
    this.setdiia253maintenanceDefaultData.asObservable();
  setTouchDimDefaultData = new BehaviorSubject<any>("");
  getTouchDimDefaultData = this.setTouchDimDefaultData.asObservable();
  setTouchDimInputError = new BehaviorSubject<any>("");
  getTouchDimInputError = this.setTouchDimInputError.asObservable();
  setdiia253maintenanceInputError = new BehaviorSubject<any>("");
  getdiia253maintenanceInputError =
    this.setdiia253maintenanceInputError.asObservable();
  setowpInputError = new BehaviorSubject<any>("");
  getowpInputError = this.setowpInputError.asObservable();
  setTouchDimValues = new BehaviorSubject<any>("");
  getTouchDimValues = this.setTouchDimValues.asObservable();
  directFeature = new BehaviorSubject<any>("");
  getdirectFeature = this.directFeature.asObservable();

  dimmingData = new BehaviorSubject<any>("");
  getDimmingData = this.dimmingData.asObservable();

  isDimmingValid = new BehaviorSubject<any>("");
  getValidDimming = this.isDimmingValid.asObservable();

  settingItDefault = new BehaviorSubject<any>("");
  getrestoreDefault = this.settingItDefault.asObservable();

  isDimmingRestore = new BehaviorSubject<any>("");
  getDimmingRestored = this.isDimmingRestore.asObservable();

  restoreDimming = new BehaviorSubject<any>("");
  getDimmingInterfaceRestored = this.restoreDimming.asObservable();

  checkValidityofDimming = new BehaviorSubject<any>("");
  getDimmingValidity = this.checkValidityofDimming.asObservable();

  generatePlugInPutData = new BehaviorSubject<any>("");
  getGeneratePlugInPutData = this.generatePlugInPutData.asObservable();

  owpAccessPwd = new BehaviorSubject<any>("");
  getowpAccessPwd = this.owpAccessPwd.asObservable();

  simpleSetConnectReadDevice = new BehaviorSubject<boolean>(false);
  getReadDeviceStatusViaSimpleSet =
    this.simpleSetConnectReadDevice.asObservable();
  sendReadDevice(value) {
    this.readDeviceSubject.next(value);
  }
  /* showContnueBtn = new BehaviorSubject<boolean>(false);
  getShowContnueBtnStatus = this.showContnueBtn.asObservable();
 */

  unsubscribe = new Subject<any>();
  getPreferencesAPI;
  preferencesAPI = "config/api/v1/preferences";
  startConfigURL = "config/api/v1/configuration";
  readerStartUrl = "/server/v1/status";
  getConfigurationUrl =
    "config/api/v1/configuration/{configurationId}/features";
  scehmaLIstApi = "config/api/v1/devices/schemas?12nc=";
  fullDeviceList = "search/api/v1/search/v1/devices?showArchive=true";
  apiList: any = [];
  softwareAPI = "search/api/v1/search/v1/device/{device12Nc}/software";
  productionInformationAPI =
    "search/api/v1/data/devices/{deviceNfcId}/production-information";
  setFeatureUrl = "config/api/v1/configuration/{configurationId}/features";
  //midNightShiftCalculationUrl =    "config/api/v1/calculation/api/v1/midnight-shift";//Old URL
  midNightShiftCalculationUrl = "config/api/v1/midnight-shift-calculation"; //New Mid Night Shift Calculation URL
  envDataStoredFP: any = undefined;
  getEnvValue() {
    var ConfigPath: any;
    if (window.location.hostname == "localhost") {
      ConfigPath = "assets/json/config.json";
    } else {
      ConfigPath = "config.json";
    }
    this.http.get(ConfigPath).subscribe((resp) => {
      if (this.envDataStoredFP === undefined) {
        this.envDataStoredFP = resp["multiOneAPIBaseUrl"];
        localStorage.setItem(
          "referUrl",
          JSON.stringify(resp["multiOneAPIBaseUrl"])
        );
      }

      this.envData.next(resp["multiOneAPIBaseUrl"]);
      this.envData.next([]);
    });
  }
  getEnvValue_FP() {
    if (this.envDataStoredFP === undefined || this.envDataStoredFP === null) {
      this.envDataStoredFP = JSON.parse(localStorage.getItem("referUrl"));
    }

    this.envData.next(this.envDataStoredFP);
    this.envData.next([]);
  }

  getBaseUrl() {
    var ConfigPath: any;
    if (window.location.hostname == "localhost") {
      ConfigPath = "assets/json/readFile.json";
    } else {
      ConfigPath = "readFile.json";
    }
    this.http.get(ConfigPath).subscribe((resp) => {
      this.readData.next(resp["multiOneAPIBaseReadUrl"]);
      this.readData.next([]);
    });
  }

  apiCall(method, url: string, body?, httpOptions?) {
    let owpAccessPwd='';
    this.getowpAccessPwd.subscribe((data)=>{
      owpAccessPwd=data;
    })
    if (method === "get") {
      // if (!isNullOrUndefined(body && body['properties']) && (body['properties'][0]['memoryBankId'] === '43-0' || body['properties'][0]['memoryBankId'] === '44-0')) {
      //   url = url + '/' + owpAccessPwd
      // }
      var configurationDataValue = JSON.parse(
        localStorage.getItem("configurationData")
      );
      if(url.includes('feature-payload') && configurationDataValue['selectedFeaturesInfo'].includes('owp-') && owpAccessPwd!=''){
        url = url + '?owpAccessPassword=' + owpAccessPwd
      }
      return this.http.get(url, httpOptions);
    } else if (method === "post") {
      return this.http.post(url, body, httpOptions);
    }
  }

  putApi(method, url: string, body?, httpOptions?) {
    if (method === "put") {
      return this.http.put(url, body, httpOptions);
    }
  }

  setReadDeviceStatusViaSimpleSet(data) {
    this.simpleSetConnectReadDevice.next(data);
  }

  setAloAPI(data) {
    this.aloFeatApi.next(data);
  }
  setAocAPI(data) {
    this.aocFeatApi.next(data);
  }
  setCloAPI(data) {
    this.cloFeatApi.next(data);
  }
  setCloLiteAPI(data) {
    this.cloLiteFeatApi.next(data);
  }
  setCodedMainsAPI(data) {
    this.codedMainsFeatApi.next(data);
  }
  setLineSwitchAPI(data) {
    this.lineSwicthFeatApi.next(data);
  }
  setowpAPI(data) {
    this.owpFeatApi.next(data);
  }
  setDimmingInterfaceAPI(data) {
    this.dimmingInterfaceFeatApi.next(data);
  }
  setDaliPSUAPI(data) {
    this.daliPSUFeatApi.next(data);
  }
  setZtvAPI(data) {
    this.ztvFeatApi.next(data);
  }
  setztvoutputAPI(data) {
    this.ztvoutputFeatApi.next(data);
  }
  setrsoAPI(data) {
    this.rsoFeatApi.next(data);
  }
  setlipAPI(data) {
    this.lipFeatApi.next(data);
  }
  setLuminAPI(data) {
    this.lumininfoformatFeatApi.next(data);
  }
  setStartUpTimeAPI(data) {
    this.startUpTimeFeatApi.next(data);
  }
  setDtlAPI(data) {
    this.dtlFeatApi.next(data);
  }
  setCorridorAPI(data) {
    this.corridorFeatApi.next(data);
  }
  setMtpAPI(data) {
    this.mtpFeatApi.next(data);
  }
  setAmpDimAPI(data) {
    this.ampDimFeatApi.next(data);
  }
  setDCEmeregncyAPI(data) {
    this.dcEmergencyFeatApi.next(data);
  }
  setMinDimLevelAPI(data) {
    this.minDimLevelFeatApi.next(data);
  }
  setTouchDimAPI(data) {
    this.touchDimFeatApi.next(data);
  }
  setdiia253maintenanceAPI(data) {
    this.diia253maintenanceFeatApi.next(data);
  }
  setDynaDimmerAPI(data) {
    this.dynaDimmerFeatApi.next(data);
  }
  sendDimmingInterfacedata(data) {
    this.dimmingData.next(data);
  }

  validtionOfDimming(data) {
    this.isDimmingValid.next(data);
  }

  sendDimmingCheckValidation(data) {
    this.checkValidityofDimming.next(data);
  }

  sendOwpAccessPwd(data) {
    this.owpAccessPwd.next(data);
  }

  setGeneratePluginOutputData(data) {
    this.generatePlugInPutData.next(data);
  }
  /*  setshowContnueBtn(data) {
    this.showContnueBtn.next(data);
  } */

  restoingDefault(data) {
    this.settingItDefault.next(data);
  }

  sendDimmingDefault(data) {
    this.isDimmingRestore.next(data);
  }

  sendDimmingRestoreDefault(data) {
    this.restoreDimming.next(data);
  }

  sentdirectlyFeature(data) {
    this.directFeature.next(data);
  }
  mtpInputError(data) {
    this.mtpError.next(data);
  }
  ztvoutputInputError(data) {
    this.ztvoutputError.next(data);
  }
  rsoInputError(data) {
    this.rsoError.next(data);
  }
  lipInputError(data) {
    this.lipError.next(data);
  }
  diia253maintenanceInputError(data) {
    this.diia253maintenanceError.next(data);
  }
  ampdimInputError(data) {
    this.ampdimError.next(data);
  }
  corridorInputError(data) {
    this.corridorError.next(data);
  }
  lineswitchInputError(data) {
    this.lineSwitchError.next(data);
  }
  codedmainsInputError(data) {
    this.codedmainsError.next(data);
  }
  owpInputError(data) {
    this.owpError.next(data);
  }
  setCorridorDefaultColor(data) {
    this.setCorridorDefault.next(data);
  }
  setCloLiteDefaultColor(data) {
    this.cloLiteDefaultColor.next(data);
  }
  setCloLiteDefaultValue(data) {
    this.cloLiteDefaultValue.next(data);
  }
  setMtpDefaultColor(data) {
    this.setMtpDefault.next(data);
  }
  setztvoutputDefaultColor(data) {
    this.setztvoutputDefault.next(data);
  }
  setlipDefaultColor(data) {
    this.setlipDefault.next(data);
  }
  setrsoDefaultColor(data) {
    this.setrsoDefault.next(data);
  }
  setLineSwitchDefaultColor(data) {
    this.setLineSwitchDefault.next(data);
  }
  setcodedmainsDefaultColor(data) {
    this.setcodedmainsDefault.next(data);
  }
  relaodForReader(data) {
    this.readerLoad.next(data);
  }

  corridorEditedVal(data) {
    this.corridorEditValueAdd.next(data);
  }
  mtpEditedVal(data) {
    this.mtpEditValueAdd.next(data);
  }
  ztvoutputEditedVal(data) {
    this.ztvoutputEditValueAdd.next(data);
  }
  lipEditedVal(data) {
    this.lipEditValueAdd.next(data);
  }
  rsoEditedVal(data) {
    this.rsoEditValueAdd.next(data);
  }
  diia253maintenanceEditedVal(data) {
    this.diia253maintenanceEditValueAdd.next(data);
  }
  lineSwitchEditedVal(data) {
    this.lineSwitchEditValueAdd.next(data);
  }
  owpEditedVal(data) {
    this.owpEditValueAdd.next(data);
  }
  codedmainsEditedVal(data) {
    this.codedmainsEditValueAdd.next(data);
  }
  sendMtpdata(data) {
    this.mtpdata.next(data);
  }
  sendztvoutputdata(data) {
    this.ztvoutputdata.next(data);
  }
  sendrsodata(data) {
    this.rsodata.next(data);
  }
  sendlipdata(data) {
    this.lipdata.next(data);
  }
  senddiia253maintenancedata(data) {
    this.diia253maintenancedata.next(data);
  }
  sendAmpDimdata(data) {
    this.ampDimdata.next(data);
  }
  sendAmpDimGraphdata(data) {
    this.ampDimGraphdata.next(data);
  }
  sendMtpGraphdata(data) {
    this.mtpgraphdata.next(data);
  }
  sendCorridordata(data) {
    this.corridordata.next(data);
  }
  sendCorridorGraphdata(data) {
    this.corridorgraphdata.next(data);
  }
  sendLineSwitchdata(data) {
    this.lineswitchdata.next(data);
  }
  sendcodedmainsdata(data) {
    this.codedmainsdata.next(data);
  }
  sendowpdata(data) {
    this.owpdata.next(data);
  }
  sendCorruptFeat(data) {
    this.corruptFeat.next(data);
  }

  sendNonCorrupt(data) {
    this.nonCorrupt.next(data);
  }

  redFailedFeatures(data) {
    this.redFeatures.next(data);
  }
  sendFeatures(data) {
    this.availableFeatures.next(data);
  }

  sendMultiFeatures(data) {
    this.availableFeaturesMulti.next(data);
  }

  setConfigurationData(data) {
    this.configurationData.next(data);
  }

  readerInfo(data) {
    this.information.next(data);
  }

  sentConfigurationData(data) {
    this.configurationData.next(data);
  }
  // Opening Modal Popup
  modalConfigurationData(data) {
    this.configurationData.next(data);
  }
  aloSetErrorButton(data) {
    this.aloValidData.next(data);
  }
  aloPercentConfigurationData(data) {
    this.aloPercent.next(data);
  }
  quickAloPercentConfigurationData(data) {
    this.quickAloPercent.next(data);
  }
  sendQuickConfig(data) {
    this.quickConfig.next(data);
  }
  setAloData(data) {
    this.setAloQuick.next(data);
  }
  aocSliderClickEvent(data) {
    this.onSliderClick.next(data);
  }

  startupSliderClickEvent(data) {
    this.startupSlider.next(data);
  }

  sendStartupValidData(data) {
    this.isStartupValueValid.next(data);
  }

  aocValueChange(data) {
    this.aocValue.next(data);
  }

  setCloLoadConfigureExtraValidation(data) {
    this.cloLoadFeatureFileConfigure.next(data);
  }

  setConfig(data) {
    this.isConfigData.next(data);
  }

  searchDevice(data) {
    this.devices.next(data);
  }

  clearFilter(data) {
    this.clear.next(data);
  }

  callConfigMethod(data) {
    this.configMethod.next(data);
  }

  disableBtn(data) {
    this.isDisable.next(data);
  }

  generatefile(featureFileUrl, configurationID: string) {
    let featureUrl = featureFileUrl.replace(
      "{configurationId}",
      configurationID
    );
    return this.http.get(featureUrl, { responseType: "text" }).pipe(
      tap() // Log the result or error
    );
  }

  setFormInputValidation(data) {
    this.formInputValidation.next(data);
  }

  setCloShema(data) {
    this.cloSchema.next(data);
  }

  sendGraphData(graphType, data: any) {
    let graphPointsData = {};
    graphPointsData["graphPoints"] = data;
    graphPointsData["graphType"] = graphType;
    this.graphData.next(graphPointsData);
  }

  sendCloGraphData(graphType, data: any[]) {
    let graphPoints = [];
    graphPoints = data;
    let graphPointsData = {};
    graphPointsData["graphPoints"] = graphPoints;
    graphPointsData["graphType"] = graphType;
    this.cloGraphData.next(graphPointsData);
  }
  //DYNA

  //DYNA
  sendDynaGraphData(data: any) {
    this.graphDynaData.next(data);
  }

  updateGraphData(data) {
    this.changedGraphData.next(data);
  }

  sendUnSelectedUnEditedData(data) {
    this.unslecteduneditedFeatures.next(data);
  }
  setEnableGraph(data) {
    this.enableGraph.next(data);
  }

  setCloLiteEnableGraph(data) {
    this.enableCloLiteGraph.next(data);
  }

  //DYNA
  setEnableDynaGraph(data) {
    this.enableDynaGraph.next(data);
  }

  sendGraphValidData(data) {
    this.isGraphValid.next(data);
  }

  sendGraphIsEditedData(data) {
    this.graphIsEditedData.next(data);
  }

  //DYNA
  sendDynaGraphIsEditedData(data) {
    this.dynagraphIsEditedData.next(data);
  }

  sendCloseSelectDevicePopup(data) {
    this.closeSelectDevicePopup.next(data);
  }

  sendCloseConfigPopup(data) {
    this.closeConfigPopup.next(data);
  }

  sendAOCValidData(data) {
    this.isAOCValid.next(data);
  }

  setEnableRset(data) {
    this.enableRSet.next(data);
  }
  sentAloEnabled(data) {
    this.enableAlo.next(data);
  }

  sentPSUEnable(data) {
    this.enablePSU.next(data);
  }

  sentHBPSUEnable(data) {
    this.enableHBPSU.next(data);
  }
  sentLGPSUEnable(data) {
    this.enableLGPSU.next(data);
  }
  setGraphRepresentation(data) {
    this.graphRepresentation.next(data);
  }
  //DYNA
  setDynaGraphRepresentation(data) {
    this.graphDynaRepresentation.next(data);
  }
  setFilterOnChecked(data) {
    this.filterOnChecked.next(data);
  }

  setColumn(data) {
    this.columnSelection.next(data);
  }

  setLuminValue(data) {
    this.luminValue.next(data);
  }

  setLuminFormInputValidation(data) {
    this.luminError.next(data);
  }

  setLuminValueChanges(data) {
    this.luminValueEdited.next(data);
  }

  sendGraphValues(data) {
    this.graphLiteValue.next(data);
  }

  sendCloLiteText(data) {
    this.cloLiteTxt.next(data);
  }

  sendCloText(data) {
    this.cloTxt.next(data);
  }

  sendDynaDimmerText(data) {
    this.dynaDimmerText.next(data);
  }
  sendliteHour(data) {
    this.liteHour.next(data);
  }

  sendLitePower(data) {
    this.LitePower.next(data);
  }

  sendShowNextSuccessBtnMulti(data) {
    this.showNextMultiSuccessBtn.next(data);
  }
  sendMultiBoxSizeUser(data) {
    this.userBoxSizeValue.next(data);
  }
  //DYNA Setter
  setDynaDimmerInterDependency(dependency: boolean) {
    this.dynaDependencyProp.next(dependency);
  }
  showDynaEnabled(data) {
    this.dynaShowEnabled.next(data);
  }

  showDynaOverrideEnabled(data) {
    this.dynaShowOverrideEnabled.next(data);
  }

  showDynaTimeRefernece(data) {
    this.dynaShowTimeReference.next(data);
  }
  sendDynaTimeHours(data) {
    this.dynaTimeHours.next(data);
  }

  sendDynaOutputLevel(data) {
    this.dynaOutputLevel.next(data);
  }

  sendDynaFadeTimess(data) {
    this.dynaFadeTimess.next(data);
  }
  sendDynaDimmerEnabledMode(data) {
    this.dynaDimmerEnabledMode.next(data);
  }
  sendDynaMidPointShiftMode(data) {
    this.dynaMidPointShiftMode.next(data);
  }

  setDynaDimmerEdited(data) {
    this.dynaDimmerEdited.next(data);
  }
  sendDynaMidPointShiftValue(data) {
    this.dynaMidPointShiftValue.next(data);
  }

  setDynaMidPointShiftDefaultsSchemaProps(data) {
    this.dynaMIdPointShiftDefaultProperties.next(data);
  }

  sendDynaDefaultValues(data) {
    this.dynaDefaultValues.next(data);
  }
  sendDynaOveerideMode(data) {
    this.dynaOverrideMode.next(data);
  }
  sendDynaTimeReferenceMode(data) {
    this.dynaTimeReferencedMode.next(data);
  }

  setDyanEnabledDefault(data) {
    this.dynaDefaultDimmerEnabledMode.next(data);
  }

  setDyanTimeRefDefault(data) {
    this.dynaDefaultTimeReferencedMode.next(data);
  }

  setDyanOverrideDefault(data) {
    this.dynadefaultOverrideMode.next(data);
  }
  setDynaDimmerAllScenes(data) {
    this.dynaDimmerAllScenes.next(data);
  }
  sendDynaScene1(data) {
    this.dynaDimmerScene1.next(data);
  }
  sendDynaScene2(data) {
    this.dynaDimmerScene2.next(data);
  }
  sendDynaScene3(data) {
    this.dynaDimmerScene3.next(data);
  }
  sendDynaScene4(data) {
    this.dynaDimmerScene4.next(data);
  }
  sendDynaScene5(data) {
    this.dynaDimmerScene5.next(data);
  }
  sendisDynaValid(data) {
    this.isDynaError.next(data);
  }
  //DYNA Setter END
  sendFeaturePage(data) {
    this.featurePage.next(data);
  }

  sendUncheckSort(data) {
    this.uncheckValue.next(data);
  }

  setFilter(data) {
    this.setToDefault.next(data);
  }

  sendValidationMessage(data) {
    this.setValidation.next(data);
  }
  sendZtvObj(data) {
    this.setZtvValues.next(data);
  }
  sendztvoutputObj(data) {
    this.setztvoutputValues.next(data);
  }
  sendTouchDimObj(data) {
    this.setTouchDimValues.next(data);
  }
  ztvInputError(data) {
    this.setztvInputError.next(data);
  }
  logout(data) {
    this.logoutSubject.next(data);
  }
  // ztvoutputInputError(data) {
  //   this.setztvoutputInputError.next(data);
  // }
  ztvEditedVal(data) {
    this.setZtvEdited.next(data);
  }
  // ztvoutputEditedVal(data) {
  //   this.setztvoutputEdited.next(data);
  // }
  isEdited(data) {
    this.aocEdited.next(data);
  }

  isStartupEdited(data) {
    this.isStartupEditedValue.next(data);
  }

  sendModes(data) {
    this.modes.next(data);
  }

  configModeRefresh(data) {
    this.callConfig.next(data);
  }

  removeModes(data) {
    this.removeSelect.next(data);
  }

  setAloDefaultColor(value) {
    this.aloDefaultColor.next(value);
  }

  setAloDefaultValue(value) {
    this.aloDefaultValue.next(value);
  }
  setMTPTcDeltaValue(value) {
    this.tcDeltaMTPtValue.next(value);
  }
  setMTPDimStopMax(value) {
    this.mtpDimStopMax.next(value);
  }
  setMTPDimStartMax(value) {
    this.mtpDimStartMax.next(value);
  }
  setMTPDimLevelMax(value) {
    this.mtpDimLevelMax.next(value);
  }
  setStartupDefaultColor(value) {
    this.startupDefaultColor.next(value);
  }

  setStartupDefaultValue(value) {
    this.startupDefaultValue.next(value);
  }

  setAocDefaultColor(value) {
    this.aocDefaultColor.next(value);
  }

  setAocDefaultValue(value) {
    this.aocDefaultValue.next(value);
  }

  setZtvRestoreBtnClick(value) {
    this.ztvRestoreBtnClick.next(value);
  }
  setztvoutputRestoreBtnClick(value) {
    this.ztvoutputRestoreBtnClick.next(value);
  }
  setSrpsuDefaultColor(value) {
    this.srpsuDefaultColor.next(value);
  }

  setSrpsuDefaultValue(value) {
    this.srpsuDefaultValue.next(value);
  }
  setdiia253maintenanceDefaultValue(value) {
    this.diia253maintenanceDefaultValue.next(value);
  }
  setCloDefaultColor(value) {
    this.cloDefaultColor.next(value);
  }
  //DYNA
  setDynaDefaultColor(value) {
    this.dynaDefaultColor.next(value);
  }
  setCloDefaultValue(value) {
    this.cloDefaultValue.next(value);
  }

  setAmpDimDefaultValue(value) {
    this.ampdimDefaultValue.next(value);
  }
  setAmpDimDefaultColor(value) {
    this.ampdimDefaultColor.next(value);
  }
  setDynaDefaultValue(value) {
    this.dynaDefaultValue.next(value);
  }

  setLineSwitchDefaultValue(value) {
    this.lineSwitchDefaultValue.next(value);
  }
  setowpDefaultValue(value) {
    this.owpDefaultValue.next(value);
  }
  setOwpRestoreDefaultValue(value) {
    this.owpRestoreDefaultValue.next(value);
  }
  setcodedmainsDefaultValue(value) {
    this.codedmainsDefaultValue.next(value);
  }
  setMtpDefaultValue(value) {
    this.mtpDefaultValue.next(value);
  }
  setztvoutputDefaultValue(value) {
    this.ztvoutputDefaultValue.next(value);
  }
  setlipDefaultValue(value) {
    this.lipDefaultValue.next(value);
  }
  setrsoDefaultValue(value) {
    this.rsoDefaultValue.next(value);
  }
  setLuminDefaultColor(value) {
    this.luminDefaultColor.next(value);
  }

  setLuminDefaultValue(value) {
    this.luminDefaultValue.next(value);
  }

  sendSelectedModala(data) {
    this.selectedModal.next(data);
  }

  dontReload(data) {
    this.Reload.next(data);
  }

  modalClose(data) {
    this.closeIt.next(data);
  }

  sendDtldata(data) {
    this.setDtlData.next(data);
  }

  sendCloLitedata(data) {
    this.setCloLiteData.next(data);
  }
  sendMinMaxValues(data) {
    this.setMinMax.next(data);
  }
  dtlInputError(data) {
    this.setdtlInputError.next(data);
  }
  dtlEditedVal(data) {
    this.setDtlEdited.next(data);
  }
  setDtlDefaultColor(value) {
    this.DtlDefaultColor.next(value);
  }

  //Min Dim Level
  sendMinDimLeveldata(data) {
    this.setMinDimLevelData.next(data);
  }
  minDimLevelInputError(data) {
    this.setminDimLevelInputError.next(data);
  }
  MinDimLevelEditedVal(data) {
    this.setMinDimLevelEdited.next(data);
  }
  setMinDimLevelDefaultColor(value) {
    this.minDimLevelDefaultColor.next(value);
  }
  //End Min Dim Level
  sendConfigForReader(data) {
    this.readDataValuesFeature.next(data);
  }

  setDcemergencyDefaultColor(value) {
    this.dcemergencyDefaultColor.next(value);
  }

  setDcemergencyEditedData(value) {
    this.dcemergencyEditedData.next(value);
  }

  setFinalDynaDimmerData(data) {
    this.finalDynaDimmerData.next(data);
  }

  sendDcemergencyValidData(data) {
    this.isDcemergencyValueValid.next(data);
  }

  sendDcemergencyDefaultData(data) {
    this.setDcemergencyDefaultData.next(data);
  }

  sendDcemergencyInputError(data) {
    this.setDcemergencyInputError.next(data);
  }

  //TouchDim
  setdiia253maintenanceDefaultColor(value) {
    this.diia253maintenanceDefaultColor.next(value);
  }
  setowpDefaultColor(value) {
    this.owpDefaultColor.next(value);
  }
  setTouchDimDefaultColor(value) {
    this.touchDimDefaultColor.next(value);
  }
  setdiia253maintenanceEditedData(value) {
    this.diia253maintenanceEditedData.next(value);
  }
  setowpEditedData(value) {
    this.owpEditedData.next(value);
  }
  setTouchDimEditedData(value) {
    this.touchDimEditedData.next(value);
  }

  sendTouchDimValidData(data) {
    this.isTouchDimValueValid.next(data);
  }

  sendTouchDimDefaultData(data) {
    this.setTouchDimDefaultData.next(data);
  }

  sendTouchDimInputError(data) {
    this.setTouchDimInputError.next(data);
  }
  senddiia253maintenanceInputError(data) {
    this.setdiia253maintenanceInputError.next(data);
  }
  postCallForUserPreferences() {
    setTimeout(() => {
      let storeSorting;
      let storeFilter;
      let storeColumn;
      let storeOmitOption;
      let storeConfigMode;
      let storeSaveFeatureFile;
      let storeDCFfile;
      if (JSON.parse(localStorage.getItem("lastSorting")) === null) {
        storeSorting = [];
      } else {
        storeSorting = JSON.parse(localStorage.getItem("lastSorting"));
      }
      if (JSON.parse(localStorage.getItem("storeFilter")) === null) {
        storeFilter = [];
      } else {
        storeFilter = JSON.parse(localStorage.getItem("storeFilter"));
      }
      if (JSON.parse(localStorage.getItem("storeColumn")) === null) {
        storeColumn = [];
      } else {
        storeColumn = JSON.parse(localStorage.getItem("storeColumn"));
      }
      if (JSON.parse(localStorage.getItem("storeOmitOption")) === null) {
        storeOmitOption = [];
      } else {
        storeOmitOption = JSON.parse(localStorage.getItem("storeOmitOption"));
      }
      if (JSON.parse(localStorage.getItem("storeConfigMode")) === null) {
        storeConfigMode = [];
      } else {
        storeConfigMode = JSON.parse(localStorage.getItem("storeConfigMode"));
      }
      if (isNullOrUndefined(JSON.parse(localStorage.getItem("storeSaveFeatureFile")))) {
        storeSaveFeatureFile = [];
      } else {
        storeSaveFeatureFile = JSON.parse(localStorage.getItem("storeSaveFeatureFile"));
      }
      if (isNullOrUndefined(JSON.parse(localStorage.getItem("storeDCFFile")))) {
        storeDCFfile = [];
      } else {
        storeDCFfile = JSON.parse(localStorage.getItem("storeDCFFile"));
      }
      let sortingObjectKey = new Object();
      sortingObjectKey = {
        storeColumn: {
          storeColumnsData: storeColumn["storeColumnsData"],
          storeColumnsCheck: storeColumn["storeColumnsCheck"],
        },
        storeOmitOption: {
          storeDevicePopupValue: storeOmitOption["storeDevicePopupValue"],
          storeDevicePopupCheck: storeOmitOption["storeDevicePopupCheck"],
        },
        storeConfigMode: {
          storeConfigModeData: storeConfigMode["storeConfigModeData"],
          storeConfigModeCheck: storeConfigMode["storeConfigModeCheck"],
        },
        lastSorting: {
          storeLastSortedColumn: storeSorting["storeLastSortedColumn"],
          storeLastColumnCheck: storeSorting["storeLastColumnCheck"],
        },

        sortCol: {},
        storeFilter: {
          storeFilterData: storeFilter["storeFilterData"],
          storeFilterCheck: storeFilter["storeFilterCheck"],
        },

        storeSaveFeatureFile: {
          storeSaveFeatureFileCheck: storeSaveFeatureFile["storeSaveFeatureFileCheck"],
          storeSaveFeatureFileCheckValue: storeSaveFeatureFile["storeSaveFeatureFileCheckValue"],
        },

        storeDCFFile: {
          storeDCFFileCheck: storeDCFfile["storeDCFFileCheck"],
          storeDCFFileCheckValue: storeDCFfile["storeDCFFileCheckValue"],
        },
      };
      this.getEnvValue();
      //this.getPrdImgDomain();
      this.getEnvData.pipe(takeUntil(this.unsubscribe)).subscribe((url) => {
        if (url.length) {
          this.unsubscribe.next();
          this.apiCall(
            "post",
            url + this.preferencesAPI,
            sortingObjectKey
          ).subscribe((response) => {});
        }
      });
    }, 500);
  }

  getEnvirnomentUrl(hosntName) {
    let item;
    let trimUrl;
    if (hosntName === "localhost") {
      trimUrl = "localhost";
    } else {
      trimUrl = hosntName.split(".")[0];
    }
    let urls = Object.assign({}, ...url);
    if (urls.hasOwnProperty(trimUrl)) {
      item = urls[trimUrl];
      return item;
    }
  }
  getImageUrl(hosntName) {
    let pathVal;
    if (hosntName === "localhost") {
      pathVal = "../../../../assets/img/";
    } else {
      pathVal = null;
    }
    return pathVal;
  }

  getLogoutUrl(hosntName) {
    let item;
    let trimUrl;
    if (hosntName === "localhost") {
      trimUrl = "localhost";
    } else {
      trimUrl = hosntName.split(".")[0];
    }
    let urls = Object.assign({}, ...logoutUrl);
    if (urls.hasOwnProperty(trimUrl)) {
      item = urls[trimUrl];
      return item;
    }
  }

  renameFeatures(array) {
    var index = array.indexOf("CLOLite");
    if (index > -1) {
      array.splice(index, 1);
      array.push("CLOLite");
    }
    var index = array.indexOf("Ast");
    if (index > -1) {
      array.splice(index, 1);
      array.push("Startup time");
    }
    var index = array.indexOf("SrPsu");
    if (index > -1) {
      array.splice(index, 1);
      array.push("SR PSU");
    }
    var index = array.indexOf("Ztv");
    if (index > -1) {
      array.splice(index, 1);
      array.push("0-10V / 1-10V");
    }
    return array;
  }

  getStartConfiguration(deviceInfo, url) {
    url = url + this.startConfigURL;
    let deviceObj = {
      device12nc: deviceInfo.device12nc,
      softwareVersion: deviceInfo.softwareVersion,
    };
    return this.apiCall("post", url, deviceObj);
  }
  getMidnightShiftCalculation(payload, url) {
    url = url + this.midNightShiftCalculationUrl;
    return this.apiCall("post", url, payload);
  }
  getServerStatus(url) {
    url = url + this.readerStartUrl;
    return this.apiCall("get", url);
  }

  getConfigurationAPI(url, configID) {
    url = url + this.getConfigurationUrl;
    return this.apiCall("get", url.replace("{configurationId}", configID));
  }

  getSupportedSchemaList(url, device12nc, DeviceVersion) {
    //hardcoded for RSO feature need to remove
    // if (device12nc === "929002772013") {
    //   url = url + this.scehmaLIstApi + device12nc + "&DeviceVersion=" + "1";
    // } else {
    url =
      url + this.scehmaLIstApi + device12nc + "&DeviceVersion=" + DeviceVersion;
    //}
    return this.apiCall("get", url);
  }
  getProductionInformation(url, device12nc, DeviceVersion) {
    url =
      url + this.scehmaLIstApi + device12nc + "&DeviceVersion=" + DeviceVersion;
    return this.apiCall("get", url);
  }
  getDeviceSearchList(url) {
    url = url + this.fullDeviceList;
    let regions = ["EMEA", "NA", "CN", "ROA"];
    var str;
    const htHeader = new HttpHeaders()
      .set("region", regions[0])
      .append("region", regions[1])
      .append("region", regions[2])
      .append("region", regions[3]);
    this.apiList = this.apiCall("get", url, "", { headers: htHeader });
    return this.apiList;
  }
  getDeviceSoftwareVersion(url, device12nc) {
    url = url + this.softwareAPI;
    return this.apiCall("get", url.replace("{device12Nc}", device12nc));
  }
  getProductionInfo(url, deviceNfcId) {
    url = url + this.productionInformationAPI;
    return this.apiCall("get", url.replace("{deviceNfcId}", deviceNfcId));
  }
  setAllfeatureAPI(url, configId, features) {
    url = url + this.setFeatureUrl;
    return this.apiCall(
      "post",
      url.replace("{configurationId}", configId),
      features
    );
  }
}
