import {
  Component,
  Input,
  OnInit,
  ViewRef,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/core-module/services/api.service";
import { FieldType } from "@ngx-formly/core";
import { isNullOrUndefined } from "util";
import { Subscription } from "rxjs";
@Component({
  selector: "app-owp",
  templateUrl: "./owp.component.html",
  styleUrls: ["./owp.component.scss"],
})
export class OwpComponent extends FieldType implements OnInit,OnDestroy {
  isSelectAll: boolean;
  @Input() field;
  column: any;
  tables: any;
  properties: any;
  firstColumn: any[] = [];
  secondColumn: any[] = [];
  newPasswordForm: FormGroup;
  verifyPasswordForm: FormGroup;
  ngModelval: any = [];
  feature12Nc = [];
  dataSet = [];
  features = [];
  schemaSet = [];
  verifyPasswordFieldValue: any = {
    verifyPasswordInputBox1: null,
    verifyPasswordInputBox2: null,
    verifyPasswordInputBox3: null,
    verifyPasswordInputBox4: null,
  };
  passwordFieldValue: any = {
    newPasswordInputBox1: null,
    newPasswordInputBox2: null,
    newPasswordInputBox3: null,
    newPasswordInputBox4: null,
  };
  quickConfigCustomCSS;
  setQuickConfig;
  isEdited: any;
  inputError: any = [];
  owpCreateFile: boolean;
  owpDefaultColor;
  submitted: boolean = false;
  resetPass: any = false;
  pathVal: string;
  maskWebNames: any = {
    DALI102ADDRESSING: "DALI 102 Addressing",
    ALO: "ALO",
    AOC: "AOC",
    CLO: "CLO",
    CLOLITE: "CLOLite",
    CORRIDORMODE: "Corridor Mode",
    DTL: "DTL",
    DYNADIMMER: "Dynadimmer",
    LINESWITCH: "Line Switch",
    TOUCHANDDIM: "Touch and Dim",
    DIMMINGINTERFACE: "Dimming Interface",
    LUMINAIREINFO: "Luminaire Info",
    OWP: "OWP",
    DALIPSU: "DALI PSU",
    DCEMERGENCY: "DC Emergency",
    ZTV: "ZTV",
    CODEDMAINS: "Coded Mains",
    MINDIMLEVEL: "Min Dim Level",
    MTP: "MTP",
    AST: "AST",
    AMPDIM: "Amp Dim",
  };
  getOwpDefaultValueSub: Subscription;
  getowpDefaultColorSub: Subscription;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private ref: ChangeDetectorRef
  ) {
    super();
    this.initNewPasswordForm();
    this.initVerifyPasswordForm();
  }
  resetDefaultOwp() {
    this.loadOwpFromConfigurationData();
    this.sendowpdata();
  }
  setConfigurationData(value, keyVal) {
    if (!isNullOrUndefined(value)) {
      let owpObj = {};
      var key = keyVal;
      var data = {};
      var addNew = {
        [keyVal]: {},
        'OWP': {},
      };
      data = JSON.parse(localStorage.getItem("configurationData"));
      if (data && !isNullOrUndefined(data["resp"])) {
        owpObj = Object.assign({}, value);
        data["resp"][keyVal] = owpObj;
      } else {
        data["resp"] = addNew;
        owpObj = Object.assign({}, value);
        data["resp"][keyVal] = owpObj;
      }
      var res = owpObj["OwpEnabled"].filter(
        (item) => item.checked === true
      );
      var OwpEnabled = res.map((item) => item.name);
      var result = OwpEnabled.map((item) => {
        return item.replace(" ", "").toUpperCase();
      });
      data['resp']['OWP']={OwpEnabled:result};
      localStorage.setItem("configurationData", JSON.stringify(data));
    }
  }
  sendowpdata() {
    this.apiService.sendowpdata(this.ngModelval);
    this.setConfigurationData(this.ngModelval, "owp");
  }
  onChangeVal(event, isChecked: boolean) {
    this.owpDefaultColor = false;
    this.owpCreateFile = false;
    var index = this.dataSet.findIndex((item) => item.name === event.target.id);
    this.dataSet[index].checked = isChecked;
    var isAllChecked = this.dataSet.every((item) => item.checked === true);
    if (!isAllChecked) {
      this.isSelectAll = false;
      var result = this.dataSet.filter((item) => item.checked === true);
      this.ngModelval["OwpEnabled"] = [...result];
    } else {
      this.isSelectAll = true;
      this.ngModelval["OwpEnabled"] = [...this.dataSet];
    }
    //this.checkdfault();
    //this.apiService.owpEditedVal(this.owpDefaultColor);
    this.apiService.owpInputError(this.owpCreateFile);
    this.checkdfault();
    this.sendowpdata();
  }
  checkdfault() {
    this.owpDefaultColor = true;
    if (
      this.f.newPasswordInputBox1.value != "" ||
      this.f.newPasswordInputBox2.value != "" ||
      this.f.newPasswordInputBox3.value != "" ||
      this.f.newPasswordInputBox4.value != "" ||
      this.v.verifyPasswordInputBox1.value != "" ||
      this.v.verifyPasswordInputBox2.value != "" ||
      this.v.verifyPasswordInputBox3.value != "" ||
      this.v.verifyPasswordInputBox4.value != ""
    ) {
      this.owpDefaultColor = false;
    }
    var result = this.dataSet.filter((item) => item.checked === true);
    if (result.length > 0) {
      this.owpDefaultColor = false;
    }
    this.apiService.setowpDefaultColor(this.owpDefaultColor);
    //this.apiService.owpEditedVal(this.owpDefaultColor);
  }
  addowpLoadedFeatureReadValues(resp: any) {

     if (resp['owp'] || resp['OWP']) {
      if (!isNullOrUndefined(resp['OWP'] && resp['OWP']['OwpEnabled']) && resp['OWP']['OwpEnabled'].length > 0) {
        let owpData = [];
        owpData = resp['OWP']['OwpEnabled'];
        this.firstColumn = this.firstColumn.map((item) => ({
          ...item,
          checked: owpData.some(o2 => item.name.toUpperCase().split(" ").join("") === o2.split(" ").join("").toUpperCase()) ? true : false
        }));
  
        this.secondColumn = this.secondColumn.map((item) => ({
          ...item,
          checked: owpData.some(o2 => item.name.toUpperCase().split(" ").join("") === o2.split(" ").join("").toUpperCase()) ? true : false
        }));
        this.dataSet = this.dataSet.map((item) => ({
          ...item,
          checked: owpData.some(o2 => item.name.toUpperCase().split(" ").join("") === o2.split(" ").join("").toUpperCase()) ? true : false
        }));
        var result = this.dataSet.filter((item) => item.checked === true);
        this.ngModelval["OwpEnabled"] = [...result];
        if (result.length === this.dataSet.length) {
          this.isSelectAll = true;
        } else {
          this.isSelectAll = false;
        }
      }else if(resp['owp']){
        let owpObj = resp['owp'];
        var keys = Object.keys(owpObj);
        var values = Object.values(owpObj);
        var result = [];
        keys.forEach((key, i) => (result[key] = values[i]));
        this.ngModelval = result;

      }
    }

    this.checkdfault();
    this.sendowpdata();
  }
  ngOnInit() {
    this.pathVal = this.apiService.getImageUrl(window.location.hostname);
    this.isEdited = false;
    this.column = this.field.requiredProperties;
    this.properties = this.field.properties;
    this.tables = this.field.Tables;
    this.loadOwpFromConfigurationData();

    this.getOwpDefaultValueSub = this.apiService.getOwpDefaultValue.subscribe((response) => {
      if (response == "valid") {
        Object.keys(this.newPasswordForm.controls).forEach((key) => {
          this.newPasswordForm.get(key).setValue("");
          this.newPasswordForm.get(key).setValidators([]);
          this.newPasswordForm.get(key).updateValueAndValidity();
        });
        Object.keys(this.verifyPasswordForm.controls).forEach((key) => {
          this.verifyPasswordForm.get(key).setValue("");
          this.verifyPasswordForm.get(key).setValidators([]);
          this.verifyPasswordForm.get(key).updateValueAndValidity();
        });
        this.passwordFieldValue = JSON.parse(
          JSON.stringify({
            newPasswordInputBox1: null,
            newPasswordInputBox2: null,
            newPasswordInputBox3: null,
            newPasswordInputBox4: null,
          })
        );
        this.verifyPasswordFieldValue = JSON.parse(
          JSON.stringify({
            verifyPasswordInputBox1: null,
            verifyPasswordInputBox2: null,
            verifyPasswordInputBox3: null,
            verifyPasswordInputBox4: null,
          })
        );
        this.isSelectAll = false;
        this.owpDefaultColor = true;
        this.dataSet = this.dataSet.map((item) => ({
          ...item,
          checked: false,
        }));
        this.ngModelval["OwpPassword"] = [];
        this.apiService.owpInputError(false);
        this.sendowpdata();
      }
    });
    this.getowpDefaultColorSub = this.apiService.getowpRestoreDefaultValue.subscribe((response) => {
      if(response){
        this.owpDefaultColor = true;
        this.resetDefaultOwp();
      }
    });

    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );
    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (element["featureName"].toString().toUpperCase() === "OWP") {
          isConfigEdited = true;
          let owp: any = element["featureParameters"][0];
          let resp ={
              OWP:owp
          };
          this.addowpLoadedFeatureReadValues(resp);
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }

    if (isConfigEdited) {
    } else {
      setTimeout(() => {
        let owpLoaddedFetaure = JSON.parse(
          localStorage.getItem("configurationData")
        );
        if (owpLoaddedFetaure) {
          if (owpLoaddedFetaure.resp && (owpLoaddedFetaure.resp.owp || owpLoaddedFetaure.resp.OWP)) {
            if (owpLoaddedFetaure.resp.owp || owpLoaddedFetaure.resp.OWP) {
                this.addowpLoadedFeatureReadValues(owpLoaddedFetaure.resp);
            }
          }
        }
      }, 0);
    }
    this.apiService.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "quickConfig";
      } else if (data === "standard") {
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "standard";
      }
      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });
    setTimeout(() => {
      this.sendowpdata();
      this.isEdited = true;
    }, 500);
  }
  selectAll(isChecked: boolean) {
    this.owpDefaultColor = false;
    this.owpCreateFile = false;
    if (isChecked) {
      this.isSelectAll = true;
      this.dataSet = this.dataSet.map((item) => ({
        ...item,
        checked: true,
      }));
    } else {
      this.isSelectAll = false;
      this.dataSet = this.dataSet.map((item) => ({
        ...item,
        checked: false,
      }));
    }
    const midpoint = Math.ceil(this.dataSet.length / 2);
    this.firstColumn = this.dataSet.slice(0, midpoint);
    this.secondColumn = this.dataSet.slice(midpoint);
    if (this.isSelectAll && isChecked) {
      this.ngModelval["OwpEnabled"] = [...this.dataSet];
    } else {
      this.dataSet = this.dataSet.map((item) => ({
        ...item,
        checked: false,
      }));
      this.ngModelval["OwpEnabled"] = [...this.dataSet];
    }
    this.checkdfault();
    this.sendowpdata();
  }
  initNewPasswordForm() {
    this.newPasswordForm = this.fb.group({
      newPasswordInputBox1: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
      newPasswordInputBox2: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
      newPasswordInputBox3: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
      newPasswordInputBox4: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
    });
  }
  initVerifyPasswordForm() {
    this.verifyPasswordForm = this.fb.group({
      verifyPasswordInputBox1: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
      verifyPasswordInputBox2: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
      verifyPasswordInputBox3: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
      verifyPasswordInputBox4: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
    });
  }
  inputValueChanged(event) {}
  get f() {
    return this.newPasswordForm.controls;
  }
  get v() {
    return this.verifyPasswordForm.controls;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  loadOwpFromConfigurationData() {
    if (JSON.parse(localStorage.getItem("configurationData"))) {
      let configureData = JSON.parse(localStorage.getItem("configurationData"));
      for (let index = 0; index < configureData.feature12Nc.length; index++) {
        this.features = configureData.feature12Nc.map((item) => {
          return item.replace("-", "").toUpperCase();
        });
      }
      this.tables.itemName.forEach((element) => {
        element.forEach((item) => {
          this.schemaSet.push(item.toUpperCase());
        });
      });
      this.schemaSet = this.schemaSet.filter(this.onlyUnique);
      const intersection = this.features.filter((element) =>
        this.schemaSet.includes(element)
      );
      if (this.dataSet.length === 0) {
        for (let index = 0; index < intersection.length; index++) {
          this.dataSet.push({
            id: index,
            name: this.maskWebNames[intersection[index]],
            checked: false,
          });
        }
      } else {
        this.dataSet = this.dataSet.map((item) => ({
          ...item,
          checked: item.checked,
        }));
      }
      this.ngModelval["OwpEnabled"] = [...this.dataSet];
      const midpoint = Math.ceil(this.dataSet.length / 2);
      this.firstColumn = this.dataSet.slice(0, midpoint);
      this.secondColumn = this.dataSet.slice(midpoint);
    }
  }
  onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }
  onSubmit(event, controlName, formName) {
    if (formName === "newPassword") {
      this.passwordFieldValue[controlName] = event.target.value;
    }
    if (formName === "verify") {
      this.verifyPasswordFieldValue[controlName] = event.target.value;
    }
    var ispasswordFieldValueValid = false;
    for (var type in this.passwordFieldValue) {
      if (this.passwordFieldValue[type] === null) {
        ispasswordFieldValueValid = true;
      }
    }
    var isverifyPasswordFieldValueValid = false;
    for (var type in this.verifyPasswordFieldValue) {
      if (this.verifyPasswordFieldValue[type] === null) {
        isverifyPasswordFieldValueValid = true;
      }
    }
    if (ispasswordFieldValueValid || isverifyPasswordFieldValueValid) {
      return;
    }
    this.submitted = true;
    if (
      this.passwordFieldValue["newPasswordInputBox1"] != "" &&
      this.verifyPasswordFieldValue["verifyPasswordInputBox1"] != "" &&
      this.passwordFieldValue["newPasswordInputBox1"] !=
        this.verifyPasswordFieldValue["verifyPasswordInputBox1"]
    ) {
      this.newPasswordForm.get("newPasswordInputBox1").setErrors({
        incorrect: true,
      });
      this.verifyPasswordForm.get("verifyPasswordInputBox1").setErrors({
        incorrect: true,
      });
    } else {
      this.newPasswordForm.get("newPasswordInputBox1").setErrors(null);
      this.verifyPasswordForm.get("verifyPasswordInputBox1").setErrors(null);
    }
    if (
      this.passwordFieldValue["newPasswordInputBox2"] != "" &&
      this.verifyPasswordFieldValue["verifyPasswordInputBox2"] != "" &&
      this.passwordFieldValue["newPasswordInputBox2"] !=
        this.verifyPasswordFieldValue["verifyPasswordInputBox2"]
    ) {
      this.newPasswordForm.get("newPasswordInputBox2").setErrors({
        incorrect: true,
      });
      this.verifyPasswordForm.get("verifyPasswordInputBox2").setErrors({
        incorrect: true,
      });
    } else {
      this.newPasswordForm.get("newPasswordInputBox2").setErrors(null);
      this.verifyPasswordForm.get("verifyPasswordInputBox2").setErrors(null);
    }
    if (
      this.passwordFieldValue["newPasswordInputBox3"] != "" &&
      this.verifyPasswordFieldValue["verifyPasswordInputBox3"] != "" &&
      this.passwordFieldValue["newPasswordInputBox3"] !=
        this.verifyPasswordFieldValue["verifyPasswordInputBox3"]
    ) {
      this.newPasswordForm.get("newPasswordInputBox3").setErrors({
        incorrect: true,
      });
      this.verifyPasswordForm.get("verifyPasswordInputBox3").setErrors({
        incorrect: true,
      });
    } else {
      this.newPasswordForm.get("newPasswordInputBox3").setErrors(null);
      this.verifyPasswordForm.get("verifyPasswordInputBox3").setErrors(null);
    }
    if (
      this.passwordFieldValue["newPasswordInputBox4"] != "" &&
      this.verifyPasswordFieldValue["verifyPasswordInputBox4"] != "" &&
      this.passwordFieldValue["newPasswordInputBox4"] !=
        this.verifyPasswordFieldValue["verifyPasswordInputBox4"]
    ) {
      this.newPasswordForm.get("newPasswordInputBox4").setErrors({
        incorrect: true,
      });
      this.verifyPasswordForm.get("verifyPasswordInputBox4").setErrors({
        incorrect: true,
      });
    } else {
      this.newPasswordForm.get("newPasswordInputBox4").setErrors(null);
      this.verifyPasswordForm.get("verifyPasswordInputBox4").setErrors(null);
    }
    if (
      parseInt(this.verifyPasswordFieldValue["verifyPasswordInputBox1"]) ===
        Number(0) &&
      parseInt(this.verifyPasswordFieldValue["verifyPasswordInputBox2"]) ===
        Number(0) &&
      parseInt(this.verifyPasswordFieldValue["verifyPasswordInputBox3"]) ===
        Number(0) &&
      parseInt(this.verifyPasswordFieldValue["verifyPasswordInputBox4"]) === Number(0)
    ) {
      this.verifyPasswordForm.get("verifyPasswordInputBox1").setErrors({
        incorrect: true,
      });
      this.verifyPasswordForm.get("verifyPasswordInputBox2").setErrors({
        incorrect: true,
      });
      this.verifyPasswordForm.get("verifyPasswordInputBox3").setErrors({
        incorrect: true,
      });
      this.verifyPasswordForm.get("verifyPasswordInputBox4").setErrors({
        incorrect: true,
      });
    }
    if (
      parseInt(this.verifyPasswordFieldValue["verifyPasswordInputBox1"]) ===
        Number(255) &&
      parseInt(this.verifyPasswordFieldValue["verifyPasswordInputBox2"]) ===
        Number(255) &&
      parseInt(this.verifyPasswordFieldValue["verifyPasswordInputBox3"]) ===
        Number(255) &&
      parseInt(this.verifyPasswordFieldValue["verifyPasswordInputBox4"]) ===
        Number(255)
    ) {
      this.verifyPasswordForm.get("verifyPasswordInputBox1").setErrors({
        incorrect: true,
      });
      this.verifyPasswordForm.get("verifyPasswordInputBox2").setErrors({
        incorrect: true,
      });
      this.verifyPasswordForm.get("verifyPasswordInputBox3").setErrors({
        incorrect: true,
      });
      this.verifyPasswordForm.get("verifyPasswordInputBox4").setErrors({
        incorrect: true,
      });
    }
    if (
      parseInt(this.passwordFieldValue["newPasswordInputBox1"]) ===
        Number(255) &&
      parseInt(this.passwordFieldValue["newPasswordInputBox2"]) ===
        Number(255) &&
      parseInt(this.passwordFieldValue["newPasswordInputBox3"]) ===
        Number(255) &&
      parseInt(this.passwordFieldValue["newPasswordInputBox4"]) === Number(255)
    ) {
      this.newPasswordForm.get("newPasswordInputBox1").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("newPasswordInputBox2").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("newPasswordInputBox3").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("newPasswordInputBox4").setErrors({
        incorrect: true,
      });
    }
    if (
      parseInt(this.passwordFieldValue["newPasswordInputBox1"]) === Number(0) &&
      parseInt(this.passwordFieldValue["newPasswordInputBox2"]) === Number(0) &&
      parseInt(this.passwordFieldValue["newPasswordInputBox3"]) === Number(0) &&
      parseInt(this.passwordFieldValue["newPasswordInputBox4"]) === Number(0)
    ) {
      this.newPasswordForm.get("newPasswordInputBox1").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("newPasswordInputBox2").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("newPasswordInputBox3").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("newPasswordInputBox4").setErrors({
        incorrect: true,
      });
    }
    if (this.newPasswordForm.valid && this.verifyPasswordForm.valid) {
      var pass = [];
      for (const [key, value] of Object.entries(
        this.verifyPasswordFieldValue
      )) {
        pass.push(parseInt(this.verifyPasswordFieldValue[key]));
      }
      this.ngModelval["OwpPassword"] = pass;
      this.apiService.owpInputError(false);
      this.checkdfault();
      this.sendowpdata();
    } else {
      //this.ngModelval["OwpPassword"] = [];
      this.apiService.owpInputError(true);
      this.checkdfault();
      // this.sendowpdata();
    }
  }
  
  ngOnDestroy(): void {
    if(this.getOwpDefaultValueSub){
       this.getOwpDefaultValueSub.unsubscribe();
    }
    if(this.getowpDefaultColorSub){
      this.getowpDefaultColorSub.unsubscribe();
   }
  }
}
