import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { ApiService } from "../../../core-module/services/api.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectingDeviceModalComponent } from "../selecting-device-modal/selecting-device-modal.component";
import { ConfigureDeviceModalComponent } from "../configure-device-modal/configure-device-modal.component";
import { Subject, forkJoin, throwError } from "rxjs";
import { catchError, flatMap, map, takeUntil } from "rxjs/operators";
import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { ErrorComponent } from "src/app/error/error.component";
import { DataLayerService } from "src/app/core-module/services/data-layer.service";
import { isNullOrUndefined } from "util";
import jwt_decode from "jwt-decode";
@Component({
  selector: "app-device",
  templateUrl: "./device.component.html",
  styleUrls: ["./device.component.scss"],
})
export class DeviceComponent implements OnInit, OnChanges, OnDestroy {
  @Output() spinner = new EventEmitter();
  @Output() pageChange: EventEmitter<number>;
  @Input() filterChekcedValue;
  searchText = "";
  myDeviceList = [];
  deviceHyphenArray = [];
  list: any;
  removedFilter: string;
  filterlength;
  page: number = 1;
  fullDeviceList = "search/api/v1/search/v1/devices";
  searchDevices = "search/api/v1/search/v1/devices";
  envSearchDevices;
  unsubscribe = new Subject<any>();
  columnList = [
    "12nc",
    "Product Name",
    "Datasheet Link",
    "Application Area",
    "Pout",
    "Iout Min",
    "Iout Max",
    "Vout Min",
    "Vout Max",
    "Configuration Interface",
    "Dimming Features",
    "Efficiency Full Load",
    "Isolation",
    "Mounting",
    "Length",
    "Width",
    "Height",
    `Box configuration`,
  ];
  columnList1: any = {
    "12nc": "12nc",
    deviceName: "Product Name",
    datasheet: "Datasheet Link",
    applicationArea: "Application Area",
    pOut: "Pout",
    iOutMin: "Iout Min",
    iOutMax: "Iout Max",
    vOutMax: "Vout Max",
    vOutMin: "Vout Min",
    configInterface: "Configuration Interface",
    dimming: "Dimming Features",
    efficiency: "Efficiency Full Load",
    isolation: "Isolation",
    moutning: "Mounting",
    width: "Width",
    height: "Height",
    SimpleSetBoxConfiguration: `Box configuration`,
  };

  sortableColumn = this.columnList1;

  columnListOnRefresh = [
    "12nc",
    "deviceName",
    "datasheet",
    "applicationArea",
    "pOut",
    "iOutMin",
    "iOutMax",
    "vOutMax",
    "vOutMin",
    "configInterface",
    "dimming",
    "efficiency",
    //"operating",
    "isolation",
    "moutning",
    "length",
    "width",
    "height",
    "SimpleSetBoxConfiguration",
  ];
  preferencesAPIHostName;
  preferencesAPI = "config/api/v1/preferences";

  deviceList: any = [];
  onloadDeviceList = [];
  featureList = [];
  deviceList1 = this.deviceList;
  arrr = [];
  loading: boolean = true;
  deviceRowsArray = [];
  deviceAllArray: any = [];
  deviceAllArray123: any = [];
  sortingName: any;
  isDesc: boolean;
  sortBy: any;
  sortDirection: string = "desc";
  sortedData: any;
  pageNumber: number;
  showCompare = false;
  compareSelectText: any;
  selectDeviceText: any;
  selectedComparingDevices = [];
  countChecked = 0;
  isDisabledButton;
  lastSorting = {};
  toggleSort: any;
  baseURL: any;
  url;
  envDeviceListURL;
  OldFilterLen: any;
  filterURL: any;
  FQuerySTring;
  filterQueryStr: string;
  checkedValue: any[] = [];
  checkedValue1;
  filterValueSelected;
  showNoDataText = false;
  wrongText;
  savedHeaderName;
  selectedCheckBox = [];
  searchWithFilter = "";
  filterSearch;
  storeFilterColumns;
  hide12nc = false;
  hideDeviceName = false;
  dataSheetLink = false;
  application = false;
  pOut = false;
  iOutMin = false;
  iOutMax = false;
  interface = false;
  dimming = false;
  efficiency = false;
  isolation = false;
  mounting = false;
  length = false;
  width = false;
  height = false;
  SimpleSetBoxConfiguration = false;
  vOutMax = false;
  vOutMin = false;
  columnData: any = [];
  callingSorted;
  sortingColumns;
  oldFilter: any;
  sortThroughAPI;
  getFilter = true;
  subscription;
  pathVal:string;
  envStartConfigURL;
  startConfigurationData = {};
  envHostName;
  softwareVer: any = [];
  latestFirmware;
  feature12NcName: any = [];
  envScehmaLIstApi;
  schemaResp: any;
  softwareVersionSchema;
  currentMode: string;
  luminSoftwareVersionSchema: any;
  mtpSoftwareVersionSchema: any;
  dimminginterfaceSoftwareVersionSchema: any;
  dynadimmerSoftwareVersionSchema: any;
  dcemergencySoftwareVersionSchema: any;
  ampdimSoftwareVersionSchema: any;
  aocSoftwareVersionSchema: any;
  touchdimSoftwareVersionSchema: any;
  diia253maintenanceSoftwareVersionSchema: any;
  corridorSoftwareVersionSchema: any;
  astSoftwareVersionSchema: any;
  cloliteSoftwareVersionSchema: any;
  ztvSoftwareVersionSchema: any;
  ztvoutputSoftwareVersionSchema: any;
  lipSoftwareVersionSchema: any;
  rsoSoftwareVersionSchema: any;
  cloSoftwareVersionSchema: any;
  regions: any = [];
  regionStr: string = "";
  apiList: any = [];
  dtlSoftwareVersionSchema: any;
  mindimlevelSoftwareVersionSchema: any;
  lineswitchSoftwareVersionSchema: any;
  owpSoftwareVersionSchema: any;
  codedmainsSoftwareVersionSchema: any;
  dalipsuSoftwareVersionSchema: any;
  readBaseUrlUnSubscribe = new Subject<any>();
  readingStatus;
  readerListUrl = "/peripheral/v1/types";
  readerData;
  isDcsConnected: boolean = true;
  readerBaseUrl: any[];
  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private ref: ChangeDetectorRef,
    public route: Router,
    private _dataLayerService: DataLayerService
  ) {}

  ngOnInit() {
    this.pathVal = this.apiService.getImageUrl(window.location.hostname);
    var currentUrl = window.location.href;
    if (currentUrl.includes("deviceList?response=")) {
      this.route.navigate(["/home"]);
    }
    this.apiService.removeModes("invalid");
    window.scrollTo(0, 0);
    // localStorage.removeItem('saveSetData');
    localStorage.removeItem("configurationData");
    this.apiService.sendFeaturePage("valid");
    this.columnList1["12nc"] = ["12nc"];
    this.columnList1["deviceName"] = ["Product Name"];
    this.columnList1["datasheet"] = ["Datasheet Link"];
    this.columnList1["applicationArea"] = ["Application Area"];
    this.columnList1["pOut"] = ["Pout"];
    this.columnList1["iOutMin"] = ["Iout Min"];
    this.columnList1["iOutMax"] = ["Iout Max"];
    this.columnList1["vOutMax"] = ["Vout Max"];
    this.columnList1["vOutMin"] = ["Vout Min"];
    this.columnList1["configInterface"] = ["Configuration Interface"];
    this.columnList1["dimming"] = ["Dimming Features"];
    this.columnList1["efficiency"] = ["Efficiency Full Load"];
    this.columnList1["isolation"] = ["Isolation"];
    this.columnList1["moutning"] = ["Mounting"];
    this.columnList1["length"] = ["Length"];
    this.columnList1["width"] = ["Width"];
    this.columnList1["height"] = ["Height"];
    this.columnList1["SimpleSetBoxConfiguration"] = [
      "Box configuration",
    ];
    localStorage.setItem("featureFileCReation", JSON.stringify(false));
    this.apiService.getmodes.subscribe((resp) => {
      // var mode = JSON.parse(localStorage.getItem('modes'))
      if (resp === "Standard" || resp === "quickConfig") {
        this.currentMode = "Standard";
        localStorage.setItem("exportedFeatureFile", JSON.stringify(true));
      }
      if (resp === "Advanced" || resp === "standard") {
        this.currentMode = "Advanced";
        localStorage.setItem("exportedFeatureFile", JSON.stringify(false));
      }
    });
    this.apiService.setConfig("");
    this.apiService.getEnvValue();
    this.compareSelectText = "Compare";
    this.selectDeviceText = "Find & Select Device";

    // Removing the code it is failing on load //

    localStorage.removeItem("storeColumn");
    localStorage.removeItem("storeConfigMode");
    localStorage.removeItem("storeOmitOption");
    localStorage.removeItem("storeFilter");
    localStorage.removeItem("lastSorting");
    localStorage.removeItem("saveCheckedDevice");

    this.refreshReadersList();

    this.columnList = [];
    this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        this.url = url;
        if (url.length) {
          this.envDeviceListURL = url;
          this.unsubscribe.next();

          this.subscription = this.apiService
            .apiCall("get", url + this.preferencesAPI)
            .subscribe(
              (response) => {
                let obj = {};
                let sortObj = {};
                let filterObj = {};
                let configModeObj = {};
                let omitObj = {};

                if (Object.keys(response).length === 0) {
                  obj["storeColumnsData"] = this.columnListOnRefresh;
                  obj["storeColumnsCheck"] = false;
                  localStorage.setItem("storeColumn", JSON.stringify(obj));

                  sortObj["storeLastSortedColumn"] =
                    this.columnListOnRefresh[0];
                  sortObj["storeLastColumnCheck"] = false;
                  this.sortThroughAPI = sortObj;
                  localStorage.setItem("lastSorting", JSON.stringify(sortObj));

                  // configModeObj['storeConfigModeData'] = "standard";
                  // configModeObj['storeConfigModeCheck'] = false;
                  // localStorage.setItem('storeConfigMode', JSON.stringify(configModeObj));

                  // omitObj['storeDevicePopupValue'] = "omit";
                  // omitObj['storeDevicePopupCheck'] = false;
                  // localStorage.setItem('storeOmitOption', JSON.stringify(omitObj));

                  this.apiService.postCallForUserPreferences();
                  this.apiService.setColumn(this.columnListOnRefresh);
                } else if (
                  response &&
                  response["storeColumn"]["storeColumnsData"]
                ) {
                  obj["storeColumnsData"] =
                    response["storeColumn"]["storeColumnsData"];
                }

                if (
                  response &&
                  !(
                    response.hasOwnProperty("storeColumn") ||
                    !(
                      response &&
                      response["storeColumn"] &&
                      response["storeColumn"].hasOwnProperty("storeColumnsData")
                    )
                  )
                ) {
                  obj["storeColumnsData"] = this.columnListOnRefresh;
                  obj["storeColumnsCheck"] = false;
                  localStorage.setItem("storeColumn", JSON.stringify(obj));

                  // this.apiService.postCallForUserPreferences();
                  this.apiService.setColumn(this.columnListOnRefresh);
                }
                if (response["storeFilter"]) {
                  // this.getFilter = response['storeFilter']['storeFilterCheck'];
                  filterObj["storeFilterData"] =
                    response["storeFilter"]["storeFilterData"];
                }

                // obj['storeColumnsData'] = response['storeColumn']['storeColumnsData'];
                // if(response['lastSorting']) {
                //   sortObj['storeLastColumnCheck'] = response['lastSorting']['storeLastColumnCheck'];
                //   this.sortThroughAPI = sortObj;
                //   localStorage.setItem('lastSorting', JSON.stringify(sortObj));
                // }

                if (
                  response["lastSorting"] &&
                  response["lastSorting"]["storeLastSortedColumn"]
                ) {
                  sortObj["storeLastSortedColumn"] =
                    response["lastSorting"]["storeLastSortedColumn"];
                  sortObj["storeLastColumnCheck"] =
                    response["lastSorting"]["storeLastColumnCheck"];
                  this.sortThroughAPI = sortObj;
                  localStorage.setItem("lastSorting", JSON.stringify(sortObj));
                } else {
                  sortObj["storeLastColumnCheck"] =
                    response["lastSorting"]["storeLastColumnCheck"];
                  this.sortThroughAPI = sortObj;
                  localStorage.setItem("lastSorting", JSON.stringify(sortObj));
                }
                if (response["storeFilter"]) {
                  filterObj["storeFilterCheck"] =
                    response["storeFilter"]["storeFilterCheck"];
                  this.getFilter = response["storeFilter"];
                  localStorage.setItem(
                    "storeFilter",
                    JSON.stringify(filterObj)
                  );
                }
                if (
                  response &&
                  response["storeColumn"] &&
                  response["storeColumn"]["storeColumnsCheck"]
                ) {
                  obj["storeColumnsCheck"] =
                    response["storeColumn"]["storeColumnsCheck"];
                  localStorage.setItem("storeColumn", JSON.stringify(obj));
                  this.apiService.setColumn(obj["storeColumnsData"]);
                } else {
                  obj["storeColumnsCheck"] =
                    response["storeColumn"]["storeColumnsCheck"];
                  localStorage.setItem("storeColumn", JSON.stringify(obj));
                  this.apiService.setColumn(this.columnListOnRefresh);
                }
                // if(response['storeFilter']) {
                //   if(response['storeFilter']['storeFilterData']) {}
                //   if(response['storeFilter']['storeFilterData'].length<=0) {
                //     this.getDevice();
                //     // this.getFilter = false;
                //   }
                // }

                configModeObj["storeConfigModeData"] =
                  response["storeConfigMode"]["storeConfigModeData"];
                configModeObj["storeConfigModeCheck"] =
                  response["storeConfigMode"]["storeConfigModeCheck"];
                localStorage.setItem(
                  "storeConfigMode",
                  JSON.stringify(configModeObj)
                );

                if (response && response["storeOmitOption"]) {
                  omitObj["storeDevicePopupValue"] =
                    response["storeOmitOption"]["storeDevicePopupValue"];
                  omitObj["storeDevicePopupCheck"] =
                    response["storeOmitOption"]["storeDevicePopupCheck"];
                  localStorage.setItem(
                    "storeOmitOption",
                    JSON.stringify(omitObj)
                  );
                }
                let storeFilter = JSON.parse(
                  localStorage.getItem("storeFilter") || "{}"
                );
                if (
                  storeFilter == {} ||
                  (storeFilter &&
                    storeFilter["storeFilterCheck"] &&
                    storeFilter["storeFilterData"].length <= 0) ||
                  (storeFilter && !storeFilter["storeFilterCheck"])
                ) {
                  this.getDevice();
                }
              },
              (error) => {
                this.loading = false;
                if (error.message === undefined) {
                  const modalRef = this.modalService.open(ErrorComponent, {
                    keyboard: false,
                    centered: true,
                    windowClass: "error-style, error-style1",
                  });
                  modalRef.componentInstance.errorName = "Authorization error";
                  modalRef.componentInstance.errorDescription =
                    "User does not have sufficient access rights to proceed";
                }
              }
            );
          // this.getDevice();
        }
      });

    this.apiService.searchDevices.subscribe((resp) => {
      if (resp.length > 1) {
        this.searchDevice(resp);
        this.searchText = resp;
      }
    });

    this.apiService.clearFilterDevices.subscribe((resp) => {
      this.clearFilter();
    });

    this.apiService.getColumnSelection.subscribe((data) => {
      if (localStorage.getItem("storeColumn")) {
        let sortVal = JSON.parse(localStorage.getItem("lastSorting"));
        let storeColumn = JSON.parse(localStorage.getItem("storeColumn"));
        if (storeColumn && storeColumn["storeColumnsCheck"]) {
          this.columnData = storeColumn["storeColumnsData"];
        } else if (data) {
          this.columnData = data;
          let obj = {};
          if (storeColumn["storeColumnsCheck"]) {
            obj["storeColumnsData"] = data;
          } else {
            obj["storeColumnsData"] = storeColumn["storeColumnsData"];
          }
          obj["storeColumnsCheck"] = storeColumn["storeColumnsCheck"];
          localStorage.setItem("storeColumn", JSON.stringify(obj));
          // this.apiService.postCallForUserPreferences();
        } else {
          this.columnData = this.columnListOnRefresh;
          let obj = {};
          if (storeColumn["storeColumnsCheck"]) {
            obj["storeColumnsData"] = data;
          } else {
            obj["storeColumnsData"] = storeColumn["storeColumnsData"];
          }
          obj["storeColumnsCheck"] = storeColumn["storeColumnsCheck"];

          localStorage.setItem("storeColumn", JSON.stringify(obj));
          // this.apiService.postCallForUserPreferences();
        }
        this.hide12nc = true;
        this.hideDeviceName = true;
        this.dataSheetLink = true;
        this.application = true;
        this.pOut = true;
        this.iOutMin = true;
        this.iOutMax = true;
        this.interface = true;
        this.dimming = true;
        this.efficiency = true;
        this.isolation = true;
        this.mounting = true;
        this.length = true;
        this.width = true;
        this.height = true;
        this.SimpleSetBoxConfiguration = true;
        this.vOutMax = true;
        this.vOutMin = true;
        let columns = this.columnData;
        let i = 0;
        this.columnList = [];
        for (let type in this.columnList1) {
          if (columns.includes(type)) {
            this.columnList[i] = this.columnList1[type];
            i++;
          }
        }
        this.sortingColumns = false;
        for (let i = 0; i < columns.length; i++) {
          let head = JSON.stringify(this.sortableColumn[columns[i]]);
          if (sortVal) {
            if (head === '["' + sortVal.headerName + '"]') {
              this.sortingColumns = true;
            }
          }
          if (columns[i] === "12nc") {
            this.hide12nc = false;
          }
          if (columns[i] === "deviceName") {
            this.hideDeviceName = false;
          }
          if (columns[i] === "datasheet") {
            this.dataSheetLink = false;
          }
          if (columns[i] === "applicationArea") {
            this.application = false;
          }
          if (columns[i] === "pOut") {
            this.pOut = false;
          }
          if (columns[i] === "iOutMin") {
            this.iOutMin = false;
          }
          if (columns[i] === "iOutMax") {
            this.iOutMax = false;
          }
          if (columns[i] === "vOutMax") {
            this.vOutMax = false;
          }
          if (columns[i] === "vOutMin") {
            this.vOutMin = false;
          }
          if (columns[i] === "configInterface") {
            this.interface = false;
          }
          if (columns[i] === "dimming") {
            this.dimming = false;
          }
          if (columns[i] === "efficiency") {
            this.efficiency = false;
          }
          if (columns[i] === "isolation") {
            this.isolation = false;
          }
          if (columns[i] === "moutning") {
            this.mounting = false;
          }
          if (columns[i] === "length") {
            this.length = false;
          }
          if (columns[i] === "width") {
            this.width = false;
          }
          if (columns[i] === "height") {
            this.height = false;
          }
          if (columns[i] === "SimpleSetBoxConfiguration") {
            this.SimpleSetBoxConfiguration = false;
          }
        }
        if (!this.sortingColumns) {
          let obj = {};
          let firstColumn = this.columnData[0];
          let prefSort = JSON.parse(localStorage.getItem("lastSorting"));
          if (prefSort) {
            if (prefSort["storeLastColumnCheck"]) {
              for (let j = 0; j < this.columnList.length; j++) {
                // if(prefSort['storeLastSortedColumn'] && prefSort['storeLastSortedColumn']['headerName']){
                if (
                  this.columnList[j][0] ===
                  prefSort["storeLastSortedColumn"]["headerName"][0]
                ) {
                  this.sortColumn(
                    prefSort["storeLastSortedColumn"]["headerName"],
                    prefSort["storeLastSortedColumn"]["sortDir"]
                  );
                  obj["storeLastSortedColumn"] =
                    prefSort["storeLastSortedColumn"];
                  break;
                } else {
                  this.sortColumn(this.columnList1[firstColumn], "asc");
                  obj["storeLastSortedColumn"] = this.lastSorting;
                }
              }
            } else {
              this.sortColumn(this.columnList1[firstColumn], "asc");
            }
          } else {
            this.sortColumn(this.columnList1[firstColumn], "asc");
          }
          // obj['storeLastColumnCheck'] = prefSort['storeLastColumnCheck'];
          // localStorage.setItem('lastSorting', JSON.stringify(obj))
          // this.apiService.postCallForUserPreferences();
        }
      } else {
        this.hide12nc = false;
        this.hideDeviceName = false;
        this.dataSheetLink = false;
        this.application = false;
        this.pOut = false;
        this.iOutMin = false;
        this.iOutMax = false;
        this.interface = false;
        this.dimming = false;
        this.efficiency = false;
        this.isolation = false;
        this.mounting = false;
        this.length = false;
        this.width = false;
        this.height = false;
        this.SimpleSetBoxConfiguration = false;
        this.vOutMax = false;
        this.vOutMin = false;
      }
    });

    this.apiService.getuncheckValue.subscribe((resp) => {
      if (resp === "uncheck") {
        let obj = {};
        obj["storeLastColumnCheck"] = false;
        obj["storeLastSortedColumn"] = this.lastSorting;
        localStorage.setItem("lastSorting", JSON.stringify(obj));
        this.apiService.postCallForUserPreferences();
        // let firstColumn =this.columnData[0];
        // this.sortColumn( this.columnList1[firstColumn], 'asc');
      }
    });

    // setTimeout(() => {
    //   this.ref.detectChanges();
    // }, 1);
  }

  refreshReadersList() {
    this.apiService.getBaseUrl();

    this.apiService.getReadData
      .pipe(takeUntil(this.readBaseUrlUnSubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.readerBaseUrl = url;
          this.readBaseUrlUnSubscribe.next();
          let status = this.getReader();
          let readerSelection = this.getReaderList();

          status
            .pipe(
              flatMap((data) => {
                return readerSelection.pipe(
                  catchError((err) => {
                    // do stuff with err from API1 request
                    return throwError(err);
                  })
                );
              }),
              catchError((err) => {
                // do stuff with err from API2 request
                return throwError(err);
              })
            )
            .subscribe(
              (results) => {
                this.readerData = results;
                localStorage.setItem("readerDevices", JSON.stringify(results));

                let readers = JSON.parse(
                  localStorage.getItem("detectedReaderInfo")
                );

                let newReader = false;
                if (!isNullOrUndefined(readers && readers["selectedReader"]) && results[0]['peripheralType'] !== readers["selectedReader"]['peripheralType']) {
                  newReader = true;
                }

                if (
                  !readers ||
                  (readers && isNullOrUndefined(readers["selectedReader"]) || newReader)
                ) {
                  let detectedReader = {
                    readerType: "SimpleSet",
                    selectedReader: this.readerData[0],
                    readerConnected: true,
                    isDcsConnected: true
                  };

                  localStorage.setItem(
                    "detectedReaderInfo",
                    JSON.stringify(detectedReader)
                  );
                  let selevtedReadArr: any[] = [];
                  selevtedReadArr.push(this.readerData[0]);
                  localStorage.setItem(
                    "readerInfo",
                    JSON.stringify(selevtedReadArr)
                  );
                }
              },
              (error) => {
                if (
                  error &&
                  !isNullOrUndefined(error.target && error.target.url) &&
                  error.target.url === "http://localhost:2020/server/v1/status"
                ) {
                  this.isDcsConnected = false;
                }else {
                  this.isDcsConnected = true;
                }
                localStorage.removeItem("readerDevices");
                let detectedReader = {
                  readerType: "SimpleSet",
                  selectedReader: null,
                  readerConnected: false,
                  isDcsConnected: this.isDcsConnected
                };

                localStorage.setItem(
                  "detectedReaderInfo",
                  JSON.stringify(detectedReader)
                );
              }
            );
        }
      });
  }

  getReader() {
    return this.apiService.getServerStatus(this.readerBaseUrl);
  }

  getReaderList() {
    return this.apiService.apiCall(
      "get",
      this.readerBaseUrl + this.readerListUrl
    );
  }

  pageChanged(e) {
    this.pageNumber = e;
    this.searchCheckedDevice();
  }

  getDeviceSearchList() {
    this.loading = true;
    return this.apiService.getDeviceSearchList(this.envDeviceListURL);
  }

  sortColumn(headerName: any, sortDirection = "asc") {
    if (headerName) {
      this.sortDirection = sortDirection === "asc" ? "desc" : "asc";
    }

    this.lastSorting["headerName"] = headerName;
    // let sortitting = headerName;
    if (headerName) {
      this.callingSorted = headerName[0].trim();
    }
    this.lastSorting["sortDir"] = sortDirection;
    let sort = JSON.parse(localStorage.getItem("lastSorting") || "{}");
    let obj = {};
    if (sort && sort["storeLastColumnCheck"]) {
      obj["storeLastSortedColumn"] = this.lastSorting;
      obj["storeLastColumnCheck"] = sort["storeLastColumnCheck"];
      headerName = obj["storeLastSortedColumn"]["headerName"];
      sortDirection = obj["storeLastSortedColumn"]["sortDir"];
      localStorage.setItem("lastSorting", JSON.stringify(obj));
      // this.apiService.postCallForUserPreferences();
    } else {
      headerName = this.lastSorting["headerName"];
      obj["storeLastColumnCheck"] = sort ? sort["storeLastColumnCheck"] : false;
      obj["storeLastSortedColumn"] = this.lastSorting;
      localStorage.setItem("lastSorting", JSON.stringify(obj));
    }
    this.apiService.postCallForUserPreferences();
    if (headerName && this.deviceList) {
      this.sortOnload(headerName, this.deviceList, this.lastSorting["sortDir"]);
    }
    this.sortBy = headerName;
    this.searchCheckedDevice();
  }

  sortOnload(headerName, onloadDeviceList, direction?) {
    if (Array.isArray(headerName)) {
      headerName.forEach((element) => {
        headerName = element;
      });
    }
    if (headerName.indexOf(" ") >= 0) {
      headerName = headerName.split(" ").join("");
      headerName = headerName.charAt(0).toLowerCase() + headerName.slice(1);
      if (headerName === "productName") {
        headerName = "deviceName";
      }
    } else if (headerName === "12nc") {
      headerName = "device12nc";
    } else if (headerName === "deviceName") {
      headerName = "deviceName";
    } else if (headerName === "Pout") {
      headerName = "opMax";
    } else {
      headerName = headerName.charAt(0).toLowerCase() + headerName.slice(1);
    }
    if (onloadDeviceList && headerName) {
      let sortedData: any = this.sortArray(
        headerName,
        onloadDeviceList,
        direction
      );
    }
    this.savedHeaderName = headerName;
    this.toggleSort = !this.toggleSort;
  }
  sortArray(headerName, onloadDeviceList, sort) {
    let tempArray: Array<any> = onloadDeviceList;
    tempArray.sort((a, b) => {
      let aKey = a[headerName];
      let bKey = b[headerName];
      let aStringType = typeof aKey;
      let bStringType = typeof bKey;
      let comparison = 0;
      let str1;
      let str2;
      if (headerName === "applicationArea") {
        str1 = isNullOrUndefined(aKey) ? "-" : "-" + aKey;
        str2 = isNullOrUndefined(bKey) ? "-" : "-" + bKey;
      }
      if (headerName === "mounting") {
        str1 = isNullOrUndefined(aKey)
          ? "-"
          : aKey.length > 0
          ? aKey.length == 1
            ? "-" + aKey[0].name
            : aKey.length == 2
            ? "-" + aKey[0].name + ", " + aKey[1].name
            : "-"
          : "-";
        str2 = isNullOrUndefined(bKey)
          ? "-"
          : bKey.length > 0
          ? bKey.length == 1
            ? "-" + bKey[0].name
            : bKey.length == 2
            ? "-" + bKey[0].name + ", " + bKey[1].name
            : "-"
          : "-";
      }

      if (headerName === "dimmingFeatures") {
        str1 = isNullOrUndefined(aKey)
          ? "-"
          : aKey.length > 0
          ? "-" + aKey[0].name
          : "-";
        str2 = isNullOrUndefined(bKey)
          ? "-"
          : bKey.length > 0
          ? "-" + bKey[0].name
          : "-";
      } else if (headerName === "datasheetLink") {
        str1 = isNullOrUndefined(aKey)
          ? "-"
          : aKey.length == 0
          ? "--"
          : "-" + aKey;
        str2 = isNullOrUndefined(bKey)
          ? "-"
          : bKey.length == 0
          ? "--"
          : "-" + bKey;
      } else if (headerName === "isolation") {
        // str1 = aKey.name;
        // str2 = bKey.name;
        if (aKey !== null && bKey !== null) {
          str1 = aKey.name;
          str2 = bKey.name;
        } else {
          if (aKey === null) {
            aKey = "-";
          }
          if (bKey === null) {
            bKey = "-";
          }
          str1 = aKey;
          str2 = bKey;
        }
      } else if (headerName === "configurationInterface") {
        if (aKey !== null && bKey !== null) {
          str1 = aKey.join("");
          str2 = bKey.join("");
        } else {
          if (aKey === null) {
            aKey = "-";
          }
          if (bKey === null) {
            bKey = "-";
          }
          str1 = aKey;
          str2 = bKey;
        }
      } else if (aStringType === "string" && bStringType === "string") {
        str1 = aKey.replace(/\s/g, "").toLowerCase();
        str2 = bKey.replace(/\s/g, "").toLowerCase();
      } else if (aStringType && bStringType === "number") {
        str1 = a[headerName];
        str2 = b[headerName];
      }
      if (str1 > str2) {
        comparison = 1;
      }
      if (str1 < str2) {
        comparison = -1;
      }
      return sort === "desc" ? comparison * -1 : comparison;
    });
    return tempArray;
  }

  getDevice() {
    this.apiList = this.getDeviceSearchList();
    forkJoin(this.apiList).subscribe((resp) => {
      this.loading = true;
      this.spinner.emit(this.loading);
      this.deviceList = [].concat.apply([], resp);
      this.loading = false;
      this.spinner.emit(this.loading);
      if (this.savedHeaderName) {
        this.sortOnload(
          this.savedHeaderName,
          this.deviceList,
          this.lastSorting["sortDir"]
        );
      }
      this.loading = false;
      this.featureList = this.deviceList["featuresList"];
      this.loading = false;
      this.spinner.emit(this.loading);
    });
    if (localStorage.getItem("lastSorting") && this.sortingColumns) {
      let sortVal = JSON.parse(localStorage.getItem("lastSorting"));
      // let header = sortVal['headerName'];
      this.sortColumn(sortVal["headerName"], sortVal["sortDir"]);
    } else {
      let firstColumn;
      if (this.columnData) {
        firstColumn = this.columnData[0];
      } else {
        firstColumn = Object.keys(this.columnList1)[0];
      }
      if (firstColumn) {
        if (this.sortThroughAPI) {
          if (this.sortThroughAPI["storeLastColumnCheck"]) {
            this.sortColumn(
              this.sortThroughAPI["storeLastSortedColumn"]["headerName"],
              this.sortThroughAPI["storeLastSortedColumn"]["sortDir"]
            );
          } else {
            this.sortColumn(this.columnList1[firstColumn], "asc");
          }
        } else {
          this.sortColumn(this.columnList[0], "asc");
        }
      } else {
        this.sortColumn(this.columnList[0], "asc");
      }
    }
  }

  searchDevice(searchText) {
    this.searchCheckedDevice();
    let headers: any = new HttpHeaders({});
    var FQuerySTringVal = "?";
    var filtersValues = [];
    var str;
    var i = 0;
    var j = 0;
    this.deviceList = [];
    this.apiList = [];
    const regionData: any = [];
    if (this.filterChekcedValue) {
      this.filterChekcedValue.forEach(function (value) {
        if (value !== undefined && value != null && value != "") {
          var splitted = value.split("=", 3);
          filtersValues[i] = splitted[1];
          if (splitted[0] != "region") {
            FQuerySTringVal = FQuerySTringVal + value + "&";
          } else {
            regionData.push(splitted[1]);
            const index = regionData.indexOf("APR");
            if (index > -1) {
              regionData.splice(index, 1);
              regionData.push("ROA", "CN");
            }
            //str = splitted[0].concat(": ", splitted[1]);
            str = splitted[0].concat(": ", regionData.toString());
            headers = str;
          }
          i++;
        }
      });
    }

    if (
      (filtersValues.includes("EMEA") &&
        filtersValues.includes("NA") &&
        filtersValues.includes("APR")) ||
      filtersValues.length == 0 ||
      (!filtersValues.includes("EMEA") &&
        !filtersValues.includes("NA") &&
        !filtersValues.includes("APR"))
    ) {
      var j = 0;
      this.regions = ["EMEA", "NA", "CN", "ROA"];
      const htHeader = new HttpHeaders()
        .set("region", this.regions[0])
        .append("region", this.regions[1])
        .append("region", this.regions[2])
        .append("region", this.regions[3]);
      this.searchTextValue(searchText, htHeader, j);
    } else {
      this.searchTextValue(searchText, headers, j);
    }

    this.unsubscribe.next();
    this.deviceList = [];
    forkJoin(this.apiList).subscribe((resp: any) => {
      if (this.pageNumber > 1) {
        this.pageNumber = 1;
      }
      this.deviceList = [].concat.apply([], resp);
      this.deviceList = this.deviceList.filter((val) => {
        return (
          val.deviceName
            .toLowerCase()
            .includes(this.searchText.toString().toLowerCase()) ||
          val.device12nc
            .toLowerCase()
            .includes(this.searchText.toString().toLowerCase())
        );
      });
      if (this.deviceList && this.deviceList.length > 0) {
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        var decodedObj: any = jwt_decode(JSON.stringify(currentUser));
        this._dataLayerService.logUserDetails({
          UserId: localStorage.getItem("UserId"),
          companyId: decodedObj.company["companyId"],
          companyName: decodedObj.company["companyName"],
          userRole: decodedObj.role,
        });
        this._dataLayerService.logSearchEvent({
          event: "search",
          term: searchText,
          region: this.deviceList[0].region
            ? this.deviceList[0].region.toString()
            : "empty",
          AppArea: this.deviceList[0].applicationArea
            ? this.deviceList[0].applicationArea.toString()
            : "empty",
          ConfInt: this.deviceList[0].configurationInterface
            ? this.deviceList[0].configurationInterface.toString()
            : "empty",
          results: this.deviceList.length,
        });
      }
      this.loading = false;
      if (this.savedHeaderName) {
        this.sortOnload(
          this.savedHeaderName,
          this.deviceList,
          this.lastSorting["sortDir"]
        );
      }
      this.showNoDataText = false;
      if (this.deviceList.length == 0) {
        this.loading = false;
        this.showNoDataText = true;
        this.wrongText = searchText;
      }
    });
  }

  searchTextValue(searchText, headers, j) {
    if (searchText.length >= 3 && !isNaN(searchText)) {
      if (this.url.length) {
        this.loading = true;
        this.envSearchDevices = this.url + this.searchDevices;
        this.filterSearch = this.searchWithFilter
          ? this.searchWithFilter + "query=nc12%3A*"
          : "?query=nc12%3A*";
        this.unsubscribe.next();
        this.apiList[j] = this.apiService.apiCall(
          "get",
          this.envSearchDevices +
            this.filterSearch +
            searchText +
            "*" +
            "&showArchive=true",
          "",
          { headers: headers }
        );
      }
    } else if (searchText.length > 2 || isNaN(searchText)) {
      if (this.url.length) {
        this.loading = true;
        this.envSearchDevices = this.url + this.searchDevices;
        this.filterSearch = this.searchWithFilter
          ? this.searchWithFilter + "query=name:"
          : "?query=name:*";
        this.unsubscribe.next();
        this.apiList[j] = this.apiService.apiCall(
          "get",
          encodeURI(
            this.envSearchDevices +
              this.filterSearch +
              searchText +
              "*" +
              "&showArchive=true"
          ),
          "",
          { headers: headers }
        );
      }
    }
  }

  ngOnChanges() {
    let obj = {};
    // localStorage.setItem('storeFilter', JSON.stringify(this.checkedValue))
    let filter = JSON.parse(localStorage.getItem("storeFilter"));
    if (!filter) {
      obj["storeFilterData"] = [];
    }
    // if (this.filterChekcedValue || JSON.parse(localStorage.getItem('storeFilter'))) {
    //   if(filter && filter['storeFilterCheck']) {
    //     obj['storeFilterCheck'] = filter['storeFilterCheck'];
    //     obj['storeFilterData'] = filter['storeFilterData'];
    //     localStorage.setItem('storeFilter', JSON.stringify(obj));
    //     this.apiService.postCallForUserPreferences();
    //   } else {
    //     obj['storeFilterCheck'] = filter ? filter['storeFilterCheck'] : false;
    //     obj['storeFilterData'] = this.filterChekcedValue || [];
    //     localStorage.setItem('storeFilter', JSON.stringify(obj));
    //     this.apiService.postCallForUserPreferences();
    //   }
    // }
    if (this.filterChekcedValue) {
      this.filterlength = this.filterChekcedValue.length;
    }
  }

  ngDoCheck() {
    let filterAPIStorage = JSON.parse(localStorage.getItem("storeFilter"));
    if (this.filterlength >= 0) {
      let checkLength = this.filterChekcedValue
        ? this.filterChekcedValue.length
        : filterAPIStorage["storeFilterData"].length;
      if (
        checkLength != this.OldFilterLen ||
        JSON.stringify(this.filterChekcedValue) !=
          JSON.stringify(this.oldFilter)
      ) {
        this.filterValueSelected = "";

        // let obj = {};
        // obj['storeFilterCheck'] = filterAPIStorage['storeFilterCheck'];
        // if(filterAPIStorage && filterAPIStorage['storeFilterCheck']) {
        //   obj['storeFilterData'] = filterAPIStorage['storeFilterData'];
        //   localStorage.setItem('storeFilter', JSON.stringify(obj));
        //   this.apiService.postCallForUserPreferences();
        // } else {
        //   obj['storeFilterData'] = this.filterChekcedValue;
        //   localStorage.setItem('storeFilter', JSON.stringify(obj));
        //   this.apiService.postCallForUserPreferences();
        // }
        setTimeout(() => {
          this.filterOnChecked();
        }, 1000);
        this.OldFilterLen = this.filterChekcedValue
          ? this.filterChekcedValue.length
          : filterAPIStorage["storeFilterData"].length;
        this.oldFilter = this.filterChekcedValue;
      }
    }
  }
  //filter device list
  filterOnChecked() {
    this.searchCheckedDevice();
    this.showNoDataText = false;
    let filterAPIStorage = this.getFilter;
    var FQuerySTringVal = "?";
    this.filterValueSelected = "";
    var filtersValues = [];
    let headers = new HttpHeaders({});
    var i = 0;
    var str: any = [];
    this.filterChekcedValue = this.filterChekcedValue
      ? this.filterChekcedValue
      : filterAPIStorage["storeFilterData"];
    const regionData: any = [];
    if (this.filterChekcedValue) {
      this.filterChekcedValue.forEach(function (value) {
        if (value !== undefined && value != null && value != "") {
          var splitted = value.split("=", 3);
          filtersValues[i] = splitted[1];
          if (splitted[0] != "region") {
            FQuerySTringVal = FQuerySTringVal + value + "&";
          } else {
            regionData.push(splitted[1]);
            const index = regionData.indexOf("APR");
            if (index > -1) {
              regionData.splice(index, 1);
              regionData.push("ROA", "CN");
            }
            str = splitted[0].concat(": ", regionData.toString());
            headers = str;
          }
          i++;
        }
      });
      var index = filtersValues.indexOf("SimpleSetBoxConfiguration");
      if (index !== -1) {
        filtersValues[index] = "Box configuration";
      }
      // this.searchText = "";
      this.filterValueSelected = filtersValues;
    }
    //API CALL
    if (this.url.length) {
      this.loading = true;
      this.baseURL = this.url;
      this.filterURL =
        this.url + "search/api/v1/search/v1/devices" + FQuerySTringVal;
      this.searchWithFilter = FQuerySTringVal;
      this.unsubscribe.next();
      if (this.searchText) {
        this.searchDevice(this.searchText);
      } else if (
        (filtersValues.includes("EMEA") &&
          filtersValues.includes("NA") &&
          filtersValues.includes("APR")) ||
        filtersValues.length == 0 ||
        (!filtersValues.includes("EMEA") &&
          !filtersValues.includes("NA") &&
          !filtersValues.includes("APR"))
      ) {
        this.regions = ["EMEA", "NA", "CN", "ROA"];
        var i = 0;
        if (FQuerySTringVal.length <= 1) {
          const htHeader = new HttpHeaders()
            .set("region", this.regions[0])
            .append("region", this.regions[1])
            .append("region", this.regions[2])
            .append("region", this.regions[3]);
          this.apiList = this.apiService.apiCall(
            "get",
            this.filterURL + "showArchive=true",
            "",
            { headers: htHeader }
          );
        } else {
          let regions = ["EMEA", "NA", "CN", "ROA"];
          const htHeader = new HttpHeaders()
            .set("region", regions[0])
            .append("region", regions[1])
            .append("region", regions[2])
            .append("region", regions[3]);
          this.apiList = this.apiService.apiCall(
            "get",
            this.filterURL + "showArchive=true",
            "",
            { headers: htHeader }
          );
        }
        forkJoin(this.apiList).subscribe((resp) => {
          if (resp) {
            this.pageNumber = 1;
            this.deviceList = [].concat.apply([], resp);
            var applicationAreaData = [];
            this.filterChekcedValue.forEach((item) => {
              applicationAreaData.push(item.toString().split("=")[1]);
            });
            let currentUser = JSON.parse(localStorage.getItem("currentUser"));
            var decodedObj: any = jwt_decode(JSON.stringify(currentUser));
            this._dataLayerService.logUserDetails({
              UserId: localStorage.getItem("UserId"),
              companyId: decodedObj.company["companyId"],
              companyName: decodedObj.company["companyName"],
              userRole: decodedObj.role,
            });
            this._dataLayerService.checkAndAdd({
              event: "search",
              region: applicationAreaData,
              activated: true,
              results: this.deviceList.length,
            });
            this.sortOnload(
              this.savedHeaderName,
              this.deviceList,
              this.lastSorting["sortDir"]
            );
          }
          this.loading = false;
        });
      } else {
        this.apiService
          .apiCall("get", this.filterURL + "showArchive=true", "", {
            headers: headers,
          })
          .subscribe((resp) => {
            if (resp) {
              this.pageNumber = 1;
              this.deviceList = resp;
              let currentUser = JSON.parse(localStorage.getItem("currentUser"));
              var decodedObj: any = jwt_decode(JSON.stringify(currentUser));
              this._dataLayerService.logUserDetails({
                UserId: localStorage.getItem("UserId"),
                companyId: decodedObj.company["companyId"],
                companyName: decodedObj.company["companyName"],
                userRole: decodedObj.role,
              });
              var dataSet = [];
              this.filterChekcedValue.forEach((item) => {
                dataSet.push(item.toString().split("=")[1]);
              });
              this._dataLayerService.checkAndAdd({
                event: "search",
                region: dataSet,
                activated: true,
                results: this.deviceList.length,
              });
              this.sortOnload(
                this.savedHeaderName,
                this.deviceList,
                this.lastSorting["sortDir"]
              );
            }
            this.loading = false;
          });
      }
    }
  }

  clearFilter() {
    this.searchCheckedDevice();
    this.showNoDataText = false;
    this.loading = true;
    this.searchText = "";
    if (this.url.length) {
      if (this.searchWithFilter) {
        this.filterOnChecked();
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        var decodedObj: any = jwt_decode(JSON.stringify(currentUser));
        this._dataLayerService.logUserDetails({
          UserId: localStorage.getItem("UserId"),
          companyId: decodedObj.company["companyId"],
          companyName: decodedObj.company["companyName"],
          userRole: decodedObj.role,
        });
        this._dataLayerService.resetRegion(this.removedFilter);
        this.showNoDataText = false;
      } else {
        this.unsubscribe.next();
        this.apiList = this.getDeviceSearchList();

        forkJoin(this.apiList).subscribe((resp) => {
          this.loading = true;
          this.spinner.emit(this.loading);
          this.deviceList = [].concat.apply([], resp);
          let currentUser = JSON.parse(localStorage.getItem("currentUser"));
          var decodedObj: any = jwt_decode(JSON.stringify(currentUser));
          this._dataLayerService.logUserDetails({
            UserId: localStorage.getItem("UserId"),
            companyId: decodedObj.company["companyId"],
            companyName: decodedObj.company["companyName"],
            userRole: decodedObj.role,
          });
          this._dataLayerService.resetSearch();
          this.loading = false;
          this.spinner.emit(this.loading);
          if (this.savedHeaderName) {
            this.sortOnload(
              this.savedHeaderName,
              this.deviceList,
              this.lastSorting["sortDir"]
            );
          }
          this.loading = false;
          this.featureList = this.deviceList["featuresList"];
          this.loading = false;
          this.spinner.emit(this.loading);
        });
      }
    }
  }

  noText(searchText) {
    if (searchText.length < 1) {
      this.clearFilter();
    }
  }
  checkSimpleSetMultiDeviceConfiguration(item) {
    if (
      !isNullOrUndefined(item) &&
      !isNullOrUndefined(item.configurationInterface) &&
      item.configurationInterface.includes("SimpleSetBoxConfiguration")
    ) {
      return "Yes";
    } else {
      return "No";
    }
  }
  // Start of selecting a device Popup
  selectedDevice(device, showCompare) {
    try {
      localStorage.setItem("NFC_Protocol", device.configurationInterface);
    } catch (error) {}

    let readerDevice = [];
    readerDevice.push(device);
    localStorage.setItem("readerDeviceFullInfo", JSON.stringify(readerDevice));
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    var decodedObj: any = jwt_decode(JSON.stringify(currentUser));
    this._dataLayerService.logUserDetails({
      UserId: localStorage.getItem("UserId"),
      companyId: decodedObj.company["companyId"],
      companyName: decodedObj.company["companyName"],
      userRole: decodedObj.role,
    });
    this._dataLayerService.setSelectedDeviceInfo({
      event: "productView",
      productName: device.deviceName,
      "12NC": device.device12nc,
      familyTopLevel: device.family,
    });
    // let firstTime = true;
    this.apiService.setCloLoadConfigureExtraValidation("Normal");
    localStorage.removeItem("comparefirmwarenoLatest");
    localStorage.removeItem("saveSetData");
    localStorage.removeItem("saveSetData1");
    localStorage.setItem("normalFlow", JSON.stringify(true));
    localStorage.setItem("isAloMin", JSON.stringify(false));
    localStorage.setItem("device12nc", device.device12nc);
    let storeConfigMode = JSON.parse(localStorage.getItem("storeConfigMode"));
    let storeOmitOption = JSON.parse(localStorage.getItem("storeOmitOption"));
    let exportedFeatureFile = JSON.parse(
      localStorage.getItem("exportedFeatureFile")
    );
    if (!showCompare) {
      if (
        storeOmitOption &&
        storeOmitOption["storeDevicePopupValue"] == "omit" &&
        storeOmitOption["storeDevicePopupCheck"] == true &&
        !showCompare
      ) {
        //  &&
        //  (storeOmitOption && storeOmitOption['storeDevicePopupValue'] == "omit" && storeOmitOption['storeDevicePopupCheck'] == true)){
        let deviceVersion_latest = "";
        this.softwareVer = [];
        this.feature12NcName = [];
        this.apiService.getEnvValue();

        // setTimeout( () => {
        this.apiService.getEnvData
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((url) => {
            if (url.length) {
              this.unsubscribe.next();
              this.envHostName = url;
              this.getDeviceSoftwareVersion(device.device12nc).subscribe(
                (deviceVersion) => {
                  if (deviceVersion["items"][0].softwareVersions.length > 0) {
                    for (let i = 0; i < deviceVersion["items"].length; i++) {
                      for (
                        let j = 0;
                        j < deviceVersion["items"][i].softwareVersions.length;
                        j++
                      ) {
                        this.softwareVer.push(
                          deviceVersion["items"][i].softwareVersions[j]
                        );
                        deviceVersion_latest =
                          deviceVersion["items"][i].deviceType.nameAndVersion;
                        deviceVersion_latest =
                          deviceVersion_latest.split(":")[2];
                      }
                    }
                  } else {
                    this.softwareVer.push(
                      deviceVersion["items"][0].softwareVersions[0]
                    );
                  }
                  this.loading = true;
                  this.softwareVer = this.softwareVer
                    .map((a) =>
                      a
                        .split(".")
                        //added for handling x.y.2, x.y.25, x.y.255 format
                        .filter((item, index) => index < 2)
                        .map((n) => +n + 100000)
                        .join(".")
                    )
                    .sort()
                    .map((a) =>
                      a
                        .split(".")
                        .map((n) => +n - 100000)
                        .join(".")
                    );
                  this.softwareVer.reverse();
                  this.latestFirmware = this.softwareVer[0];

                  localStorage.setItem(
                    "comparefirmwarenoLatest",
                    JSON.stringify(this.latestFirmware)
                  );
                  this.envScehmaLIstApi = url;
                  //this.schemalistApi(device.device12nc,this.latestFirmware).subscribe(resp=>{
                  this.schemalistApi(
                    device.device12nc,
                    deviceVersion_latest
                  ).subscribe((resp) => {
                    this.schemaResp = resp["metadata"].schemas;
                    this.schemaResp.forEach((element, index) => {
                      let birth =
                        element.match(/[^,]*mcsa-[^,]*/g) ||
                        element.match(/[^,]*birth-[^,]*/g) ||
                        element.match(/[^,]*public-[^,]*/g) ||
                        element.match(/[^,]*bl[^,]*/g) ||
                        element.match(/[^,]*''[^,]*/g);
                      if (!birth) {
                        var splitted = element.split(":")[1];
                        if (splitted) {
                          var feature12NCvalue = splitted.substring(
                            0,
                            splitted.length - device.device12nc.length
                          );
                          this.feature12NcName.push(feature12NCvalue);
                        }
                      }
                    });
                    this.feature12NcName = this.feature12NcName.filter(Boolean);
                    let val = this.schemaResp.toString();
                    let versiontoString =
                      val.match(/[^,]*alo[^,]*/g) ||
                      val.match(/[^,]*clo[^,]*/g) ||
                      val.match(/[^,]*aoc[^,]*/g) ||
                      val.match(/[^,]*ztv[^,]*/g);
                    if (versiontoString) {
                      this.softwareVersionSchema = versiontoString
                        .toString()
                        .split(":")[2];
                    }

                    let selectedFeaturesInfo = [];
                    //  selectedFeaturesInfo.push('0-10v / 1-10v', 'Alo', 'Aoc', 'Clo', 'CloLite', 'Startup time', 'Ztv');

                    if (val.match(/[^,]*alo[^,]*/g)) {
                      selectedFeaturesInfo.push("Alo");
                    }
                    if (val.match(/[^,]*clo-[^,]*/g)) {
                      if (
                        val
                          .match(/[^,]*clo-[^,]*/g)
                          .toString()
                          .split(":")[1]
                          .slice(
                            0,
                            val
                              .match(/[^,]*clo-[^,]*/g)
                              .toString()
                              .split(":")[1]
                              .indexOf(device.device12nc)
                          ) === "clo-"
                      ) {
                        selectedFeaturesInfo.push("Clo");
                        let cloversiontoString = val.match(/[^,]*clo-[^,]*/g);
                        this.cloSoftwareVersionSchema = cloversiontoString
                          .toString()
                          .split(":")[2];
                      }
                    }
                    if (val.match(/[^,]*aoc[^,]*/g)) {
                      selectedFeaturesInfo.push("Aoc");
                      let aocversiontoString = val.match(/[^,]*aoc[^,]*/g);
                      this.aocSoftwareVersionSchema = aocversiontoString
                        .toString()
                        .split(":")[2];
                    }
                    if (val.match(/[^,]*touchdim[^,]*/g)) {
                      selectedFeaturesInfo.push("touchdim");
                      let touchdimversiontoString =
                        val.match(/[^,]*touchdim[^,]*/g);
                      this.touchdimSoftwareVersionSchema =
                        touchdimversiontoString.toString().split(":")[2];
                    }
                    if (val.match(/[^,]*diia253maintenance[^,]*/g)) {
                      selectedFeaturesInfo.push("diia253maintenance");
                      let diia253maintenanceversiontoString = val.match(
                        /[^,]*diia253maintenance[^,]*/g
                      );
                      this.diia253maintenanceSoftwareVersionSchema =
                        diia253maintenanceversiontoString
                          .toString()
                          .split(":")[2];
                    }
                    if (val.match(/[^,]*ampdim-[^,]*/g)) {
                      let ampdimVersiontoString =
                        val.match(/[^,]*ampdim[^,]*/g);
                      this.ampdimSoftwareVersionSchema = ampdimVersiontoString
                        .toString()
                        .split(":")[2];
                      selectedFeaturesInfo.push("Amp Dim");
                    }
                    if (val.match(/[^,]*ast[^,]*/g)) {
                      selectedFeaturesInfo.push("Startup time");
                      let astversiontoString = val.match(/[^,]*ast[^,]*/g);
                      this.astSoftwareVersionSchema = astversiontoString
                        .toString()
                        .split(":")[2];
                    }
                    if (val.match(/[^,]*srpsu[^,]*/g)) {
                      selectedFeaturesInfo.push("SR PSU");
                    }
                    if (val.match(/[^,]*luminfo[^,]*/g)) {
                      let luminversiontoString =
                        val.match(/[^,]*luminfo[^,]*/g);
                      this.luminSoftwareVersionSchema = luminversiontoString
                        .toString()
                        .split(":")[2];
                      selectedFeaturesInfo.push("LuminInfo");
                      // this.isLuminaireInfo=1;
                    }
                    if (val.match(/[^,]*ztv[^,]*/g)) {
                      selectedFeaturesInfo.push("0-10v / 1-10v");
                      let ztvversiontoString = val.match(/[^,]*ztv[^,]*/g);
                      this.ztvSoftwareVersionSchema = ztvversiontoString
                        .toString()
                        .split(":")[2];
                    }
                    if (val.match(/[^,]*ztvoutput[^,]*/g)) {
                      selectedFeaturesInfo.push("ztvoutput");
                      let ztvoutputversiontoString =
                        val.match(/[^,]*ztvoutput[^,]*/g);
                      this.ztvoutputSoftwareVersionSchema =
                        ztvoutputversiontoString.toString().split(":")[2];
                    }
                    if (val.match(/[^,]*lip[^,]*/g)) {
                      selectedFeaturesInfo.push("lip");
                      let lipversiontoString = val.match(/[^,]*lip[^,]*/g);
                      if(lipversiontoString.length>1){
                        this.lipSoftwareVersionSchema = lipversiontoString[1]
                        .toString()
                        .split(":")[2];
                      }else{
                        this.lipSoftwareVersionSchema = lipversiontoString
                        .toString()
                        .split(":")[2];
                      }
                    }
                    if (val.match(/[^,]*rso[^,]*/g)) {
                      selectedFeaturesInfo.push("rso");
                      let rsoversiontoString = val.match(/[^,]*rso[^,]*/g);
                      this.rsoSoftwareVersionSchema = rsoversiontoString
                        .toString()
                        .split(":")[2];
                    }
                    if (val.match(/[^,]*clolite[^,]*/g)) {
                      let cloliteversiontoString =
                        val.match(/[^,]*clolite[^,]*/g);
                      this.cloliteSoftwareVersionSchema = cloliteversiontoString
                        .toString()
                        .split(":")[2];
                      selectedFeaturesInfo.push("CloLite");
                    }
                    if (val.match(/[^,]*dtl[^,]*/g)) {
                      let dtlVersiontoString = val.match(/[^,]*dtl[^,]*/g);
                      this.dtlSoftwareVersionSchema = dtlVersiontoString
                        .toString()
                        .split(":")[2];
                      selectedFeaturesInfo.push("DTL");
                    }
                    if (val.match(/[^,]*mindimlevel[^,]*/g)) {
                      let mindimlevelVersiontoString = val.match(
                        /[^,]*mindimlevel[^,]*/g
                      );
                      this.mindimlevelSoftwareVersionSchema =
                        mindimlevelVersiontoString.toString().split(":")[2];
                      selectedFeaturesInfo.push("Min Dim Level");
                    }
                    if (val.match(/[^,]*mtp[^,]*/g)) {
                      selectedFeaturesInfo.push("Mtp");
                      let mtpversiontoString = val.match(/[^,]*mtp[^,]*/g);
                      this.mtpSoftwareVersionSchema = mtpversiontoString
                        .toString()
                        .split(":")[2];
                    }
                    if (val.match(/[^,]*dimminginterface[^,]*/g)) {
                      selectedFeaturesInfo.push("dimminginterface");
                      let dimminginterfaceversiontoString = val.match(
                        /[^,]*dimminginterface[^,]*/g
                      );
                      this.dimminginterfaceSoftwareVersionSchema =
                        dimminginterfaceversiontoString
                          .toString()
                          .split(":")[2];
                    }
                    if (val.match(/[^,]*corridor-[^,]*/g)) {
                      let corridorversiontoString =
                        val.match(/[^,]*corridor[^,]*/g);
                      this.corridorSoftwareVersionSchema =
                        corridorversiontoString.toString().split(":")[2];
                      selectedFeaturesInfo.push("Corridor");
                    }
                    if (val.match(/[^,]*dcemergency-[^,]*/g)) {
                      let dcemergencyVersiontoString = val.match(
                        /[^,]*dcemergency-[^,]*/g
                      );
                      this.dcemergencySoftwareVersionSchema =
                        dcemergencyVersiontoString.toString().split(":")[2];
                      selectedFeaturesInfo.push("DC Emergency");
                    }
                    if (val.match(/[^,]*touchdim-[^,]*/g)) {
                      selectedFeaturesInfo.push("Touch and Dim");
                    }
                    if (val.match(/[^,]*lineswitch-[^,]*/g)) {
                      selectedFeaturesInfo.push("Line Switch");
                      let lineswitchversiontoString = val.match(
                        /[^,]*lineswitch-[^,]*/g
                      );
                      this.lineswitchSoftwareVersionSchema =
                        lineswitchversiontoString.toString().split(":")[2];
                    }
                    if (val.match(/[^,]*owp-[^,]*/g)) {
                      selectedFeaturesInfo.push("Owp");
                      let owpversiontoString = val.match(/[^,]*owp-[^,]*/g);
                      this.owpSoftwareVersionSchema = owpversiontoString
                        .toString()
                        .split(":")[2];
                    }
                    if (val.match(/[^,]*codedmains-[^,]*/g)) {
                      selectedFeaturesInfo.push("Codedmains");
                      let codedmainsversiontoString = val.match(
                        /[^,]*codedmains-[^,]*/g
                      );
                      this.codedmainsSoftwareVersionSchema =
                        codedmainsversiontoString.toString().split(":")[2];
                    }
                    if (val.match(/[^,]*dalipsu-[^,]*/g)) {
                      selectedFeaturesInfo.push("DALI PSU");
                      let dalipsuversiontoString =
                        val.match(/[^,]*dalipsu-[^,]*/g);
                      this.dalipsuSoftwareVersionSchema = dalipsuversiontoString
                        .toString()
                        .split(":")[2];
                    }
                    if (val.match(/[^,]*dynadimmer-[^,]*/g)) {
                      let dynadimmerVersiontoString = val.match(
                        /[^,]*dynadimmer-[^,]*/g
                      );
                      this.dynadimmerSoftwareVersionSchema =
                        dynadimmerVersiontoString.toString().split(":")[2];
                      selectedFeaturesInfo.push("Dyna Dimmer");
                    }
                    if (selectedFeaturesInfo.length > 0) {
                      this.apiService.getEnvValue();
                      this.apiService.getEnvData
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((url) => {
                          if (url.length) {
                            this.unsubscribe.next();
                            this.envStartConfigURL = url;
                            if (this.latestFirmware.length > 3) {
                              this.latestFirmware = this.latestFirmware.slice(
                                0,
                                -2
                              );
                            }
                            let mockDevice = {
                              device12nc: device.device12nc,
                              deviceName: device.deviceName,
                              softwareVersion: this.latestFirmware,
                            };
                            this.apiService
                              .getStartConfiguration(
                                mockDevice,
                                this.envStartConfigURL
                              )
                              .subscribe(
                                (data) => {
                                  localStorage.removeItem("deviceVersion");
                                  var configurationID;
                                  if (localStorage.getItem("configId")) {
                                    configurationID =
                                      localStorage.getItem("configId");
                                  } else {
                                    configurationID = data["configurationID"];
                                  }
                                  if (device) {
                                    var domain =
                                      "https://www.api.easydesignintool.signify.com";
                                    if (device.devicetypePicture != undefined) {
                                      device.devicetypePicture =
                                        domain +
                                        device.devicetypePicture.replace(
                                          domain,
                                          ""
                                        );
                                    }
                                  }
                                  if (this.latestFirmware.length > 3) {
                                    this.latestFirmware =
                                      this.latestFirmware.slice(0, -2);
                                  }
                                  this.startConfigurationData[
                                    "configurationID"
                                  ] = configurationID;
                                  this.startConfigurationData["deviceName"] =
                                    device["deviceName"];
                                  this.startConfigurationData[
                                    "softwareVersion"
                                  ] = this.latestFirmware;
                                  this.startConfigurationData[
                                    "selectedFeaturesInfo"
                                  ] = selectedFeaturesInfo;
                                  this.startConfigurationData[
                                    "softwareVerSchema"
                                  ] = this.softwareVersionSchema;
                                  this.startConfigurationData["luminSoftVer"] =
                                    this.luminSoftwareVersionSchema;
                                  this.startConfigurationData["aocSoftVer"] =
                                    this.aocSoftwareVersionSchema;
                                  this.startConfigurationData[
                                    "touchdimSoftVer"
                                  ] = this.touchdimSoftwareVersionSchema;
                                  this.startConfigurationData[
                                    "diia253maintenanceSoftVer"
                                  ] =
                                    this.diia253maintenanceSoftwareVersionSchema;
                                  this.startConfigurationData[
                                    "corridorSoftVer"
                                  ] = this.corridorSoftwareVersionSchema;
                                  this.startConfigurationData["cloSoftVer"] =
                                    this.cloSoftwareVersionSchema;
                                  this.startConfigurationData["astSoftVer"] =
                                    this.astSoftwareVersionSchema;
                                  this.startConfigurationData[
                                    "cloliteSoftVer"
                                  ] = this.cloliteSoftwareVersionSchema;
                                  this.startConfigurationData["ztvSoftVer"] =
                                    this.ztvSoftwareVersionSchema;
                                  this.startConfigurationData[
                                    "ztvoutputSoftVer"
                                  ] = this.ztvoutputSoftwareVersionSchema;
                                  this.startConfigurationData["lipSoftVer"] =
                                    this.lipSoftwareVersionSchema;
                                  this.startConfigurationData["rsoSoftVer"] =
                                    this.rsoSoftwareVersionSchema;
                                  this.startConfigurationData["mtpSoftVer"] =
                                    this.mtpSoftwareVersionSchema;
                                  this.startConfigurationData[
                                    "dimminginterfaceSoftVer"
                                  ] =
                                    this.dimminginterfaceSoftwareVersionSchema;
                                  this.startConfigurationData[
                                    "dynadimmerSoftVer"
                                  ] = this.dynadimmerSoftwareVersionSchema;
                                  this.startConfigurationData[
                                    "dcemergencySoftVer"
                                  ] = this.dcemergencySoftwareVersionSchema;
                                  this.startConfigurationData[
                                    "lineswitchSoftVer"
                                  ] = this.lineswitchSoftwareVersionSchema;
                                  this.startConfigurationData["owpSoftVer"] =
                                    this.owpSoftwareVersionSchema;
                                  this.startConfigurationData[
                                    "codedmainsSoftVer"
                                  ] = this.codedmainsSoftwareVersionSchema;
                                  this.startConfigurationData[
                                    "dalipsuSoftVer"
                                  ] = this.dalipsuSoftwareVersionSchema;
                                  this.startConfigurationData["ampdimSoftVer"] =
                                    this.ampdimSoftwareVersionSchema;
                                  this.startConfigurationData["dtlSoftVer"] =
                                    this.dtlSoftwareVersionSchema;
                                  this.startConfigurationData[
                                    "mindimlevelSoftVer"
                                  ] = this.mindimlevelSoftwareVersionSchema;
                                  this.startConfigurationData["picture"] =
                                    device.devicetypePicture;
                                  this.startConfigurationData["device12nc"] =
                                    device.device12nc;
                                  let feature12Nc = [
                                    ...new Set(this.feature12NcName),
                                  ];
                                  this.startConfigurationData["feature12Nc"] =
                                    feature12Nc;
                                  localStorage.setItem(
                                    "configurationData",
                                    JSON.stringify(this.startConfigurationData)
                                  );
                                  localStorage.setItem(
                                    "oldConfigurationData",
                                    JSON.stringify(this.startConfigurationData)
                                  );
                                  this.apiService.setConfigurationData(
                                    this.startConfigurationData
                                  );
                                  this.loading = false;
                                  // let url = window.location.href;
                                  // if(url.includes('/deviceList')) {
                                  //   this.apiService.sendQuickConfig('quickConfig')
                                  // }
                                  let mode = JSON.parse(
                                    localStorage.getItem("storeConfigMode")
                                  );
                                  if (
                                    mode["storeConfigModeCheck"] &&
                                    (mode["storeConfigModeData"] ===
                                      "Standard" ||
                                      mode["storeConfigModeData"] ===
                                        "quickConfig")
                                  ) {
                                    this.apiService.sendModes("Standard");
                                  } else {
                                    this.apiService.sendModes("Advanced");
                                  }
                                  // localStorage.setItem('quickConfiguration', JSON.stringify('standard'));
                                  localStorage.removeItem("deviceVersion");
                                  this.route.navigate(["/featureLanding"]);
                                },
                                (err) => {
                                  this.loading = false;
                                  const modalRef = this.modalService.open(
                                    ErrorComponent,
                                    {
                                      keyboard: false,
                                      centered: true,
                                      windowClass: "error-style, error-style1",
                                    }
                                  );
                                  modalRef.componentInstance.errorName =
                                    err["message"];
                                  modalRef.componentInstance.errorDescription =
                                    err["description"];
                                  //alert(error["description"]);
                                }
                              );
                          }
                        });
                    } else {
                      this.loading = false;
                      this.route.navigate(["/featureNonSupported"]);
                      //alert('No feature found for the selected device');
                    }
                  });
                }
              );
            }
          });
      } else {
        let mode = JSON.parse(localStorage.getItem("storeConfigMode"));
        if (
          mode["storeConfigModeCheck"] &&
          (mode["storeConfigModeData"] === "Standard" ||
            mode["storeConfigModeData"] === "quickConfig")
        ) {
          this.apiService.sendModes("Standard");
        } else {
          this.apiService.sendModes("Advanced");
        }
        this.apiService.setConfigurationData("deviceSelected"); // to open a selecting popup for device info
        const modalRef = this.modalService.open(SelectingDeviceModalComponent, {
          backdrop: "static",
          keyboard: false,
          windowClass: "select-device-modal",
          centered: true,
        });
        modalRef.componentInstance.device = device;
      }
    }
  }
  // End of selecting a device popup
  toggleCompare() {
    this.compareSelectText = "Selected(" + this.countChecked + ")";
    this.selectDeviceText = "Select upto 3 devices to compare";
    this.showCompare = !this.showCompare;
  }

  cancelCompare() {
    this.showCompare = false;
    this.selectedComparingDevices = [];
    this.countChecked = 0;
    this.isDisabledButton = false;
    localStorage.removeItem("saveCheckedDevice");
    this.selectedCheckBox = [];
    this.compareSelectText = "Compare";
    this.selectDeviceText = "Select device type";
  }

  compareDevices() {
    this.showCompare = true;
    this.apiService.setConfigurationData("compareDevices");
    {
      const modalRef = this.modalService.open(SelectingDeviceModalComponent, {
        backdrop: "static",
        keyboard: false,
        windowClass: "compare-device-features",
        centered: true,
        size: "xl",
      });
      var domain = "https://www.api.easydesignintool.signify.com";
      for (var j = 0; j < this.selectedComparingDevices.length; j++) {
        if (this.selectedComparingDevices[j].devicetypePicture != undefined) {
          this.selectedComparingDevices[j].devicetypePicture =
            domain +
            this.selectedComparingDevices[j].devicetypePicture.replace(
              domain,
              ""
            );
        }
      }

      modalRef.componentInstance.selectedComparingDevices =
        this.selectedComparingDevices;
    }
  }

  comparing(event, product) {
    if (event.target.checked) {
      this.countChecked = this.countChecked + 1;
    } else {
      this.countChecked = this.countChecked - 1;
    }

    if (this.countChecked > 3) {
      alert("Please select upto 3 devices to Compare.");
      event.target.checked = false;
      this.countChecked = this.countChecked - 1;
    } else {
      this.compareSelectText = "Selected(" + this.countChecked + ")";
    }
    if (event.target.checked === true) {
      product.checked = true;
      this.selectedComparingDevices.push(product);
      localStorage.setItem(
        "saveCheckedDevice",
        JSON.stringify(this.selectedComparingDevices)
      );
      if (this.countChecked > 1 && this.countChecked <= 3) {
        this.isDisabledButton = true;
      } else {
        this.isDisabledButton = false;
      }
    } else {
      if (this.countChecked <= 1 || this.countChecked > 3) {
        this.isDisabledButton = false;
      } else {
        this.isDisabledButton = true;
      }
      product.checked = false;
      let index = this.selectedComparingDevices.indexOf(product);
      this.selectedComparingDevices.forEach((data) => {
        if (data.device12nc === product.device12nc) {
          const index = this.selectedComparingDevices.indexOf(data);
          if (index > -1) {
            this.selectedComparingDevices.splice(index, 1);
          }
          localStorage.setItem(
            "saveCheckedDevice",
            JSON.stringify(this.selectedComparingDevices)
          );
        }
      });
    }
  }

  openDataSheet(e) {
    e.stopPropagation();
  }

  removeFilter(filter) {
    this.removedFilter = filter;
    this.filterValueSelected.splice(filter, 1);
    this.apiService.setFilterOnChecked(filter);
    this.clearFilter();
  }

  selectColumn() {
    this.apiService.modalConfigurationData("columnSelected");
    const modalRef = this.modalService.open(SelectingDeviceModalComponent, {
      backdrop: "static",
      keyboard: false,
      windowClass: "select-device-modal",
      centered: true,
    });
  }

  stopBlue(event) {
    event.stopPropagation();
  }

  uploadFeatureFile() {
    this.apiService.modalConfigurationData("uploadFeatureFile");
    const modalRef = this.modalService.open(ConfigureDeviceModalComponent, {
      windowClass: "select-feature-modal",
      backdropClass: "customBackdrop",
    });
  }

  getDeviceSoftwareVersion(device12Nc) {
    return this.apiService.getDeviceSoftwareVersion(
      this.envHostName,
      device12Nc
    );
  }

  schemalistApi(device12nc, deviceVersion) {
    return this.apiService.getSupportedSchemaList(
      this.envScehmaLIstApi,
      device12nc,
      deviceVersion
    );
  }

  searchCheckedDevice() {
    if (localStorage.getItem("saveCheckedDevice")) {
      const saved = JSON.parse(localStorage.getItem("saveCheckedDevice"));
      saved.forEach((element, index) => {
        if (element.device12nc) {
          this.selectedCheckBox[index] = element.device12nc;
        }
      });
    }
  }

  ngOnDestroy() {
    // this.apiService.sendModes('');
    // this.subscription.unsubscribe();
    this.apiService.searchDevice("");
  }
}
