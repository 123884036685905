import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { ApiService } from "src/app/core-module/services/api.service";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { ReaderSelectionPopupComponent } from "../reader-selection-popup/reader-selection-popup.component";
import { catchError, flatMap, switchMap, takeUntil } from "rxjs/operators";
import {
  Subject,
  forkJoin,
  Observable,
  Subscription,
  of,
  throwError,
} from "rxjs";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { AuthenticationService } from "../../../core-module/services/authentication";
import { Router } from "@angular/router";
import { ErrorComponent } from "src/app/error/error.component";
import { isNullOrUndefined } from "util";
import { ReadDeviceSelectionPopupComponent } from "../read-device-selection-popup/read-device-selection-popup.component";
import { dcUsupportedDeviceList } from "src/assets/json/dcUnSupportedList";

@Component({
  selector: "app-read-device",
  templateUrl: "./read-device.component.html",
  styleUrls: ["./read-device.component.scss"],
})
export class ReadDeviceComponent implements OnInit, OnDestroy {
  @Input() public newPayLoadValues;
  @Input() public isRetry: boolean = false;
  readDevice: any;
  deviceNfcId: any;
  readingStatus;
  loading: boolean;
  readerData;
  hideStatus: boolean;
  customerLoader: boolean = false;
  isProductionInfoAvailable: boolean = false;
  customerLoaderAfter: boolean = false;
  noReader: boolean;
  specialErrorNoDatabase: boolean = false;
  baseLinkUrl = "http://localhost:2020";
  readerStartUrl = "/server/v1/status";
  readerListUrl = "/peripheral/v1/types";
  readreDataUrl = "/device/v1/NFC/LCN9600/dummy/memorybank/114";
  readerName: any;
  listReader: any = [];
  errorMessage: any;
  readerLength: any = [];
  deviceInfoUrl = "/peripheral/v1/nfc/{peripheralId}/devices";
  memoryBankUrl =
    "/device/v1/nfc/{peripheralId}/{deviceNfcId}/memorybanks/metadata";
  envScehmaLIstApi: any;
  corruptBitUrl =
    "/device/v1/nfc/{peripheralId}/{deviceNfcId}/memorybanks/corrupt";
  writingInDeviceURL =
    "/device/v1/nfc/{peripheralId}/{deviceNfcId}/memorybanks";
  listOfBank: any;
  unsubscribe = new Subject<any>();
  allFeatures: any;
  feature12NcName: any = [];
  readerBaseUrl: any[];
  readerDeviceData: any;
  featureWithId: any;
  productionDate: any;
  productionBatchId: any;
  sku: any;
  nonCorrupt = [];
  corrupt = [];
  payLoadUrl = "/device/v1/nfc/{peripheralId}/{deviceNfcId}/memorybanks";
  importPayLoadUrl =
    "feature/api/v1/configuration/{configurationId}/feature-payload";
  importPayLoadUrlNE =
    "feature/api/v1/configuration/{configurationId}/feature-payload/not-encrypted";
  startConfiguration;
  specificFeaturePayLoad;
  url: any;
  deviceInformationObj: {};
  bankIdByIndex: any;
  newDeviceObj;
  envHostName;
  apiListResponse: any[] = [];
  mem: any = [];
  softwareVersionSchema: any;
  luminSoftwareVersionSchema: any;
  everySchemaofDevice: any;
  alominBankId: boolean;
  isALoMinObj: boolean;
  isOwpObj: boolean;
  isWritingRetryCheck: boolean = false;
  payLoadResp: any;
  subscription: Subscription;
  removeSuccessText: boolean;
  writingDevicesContainer: boolean;
  writingURL: any;
  removeWritingHeader: boolean = true;
  writingURLViaPlugin =
    "feature/api/v1/configuration/{configurationId}/feature-payload";
  writingURLViaPluginNE =
    "feature/api/v1/configuration/{configurationId}/feature-payload/not-encrypted";
  isAocMinObj: boolean;
  isLumMinSixObj: boolean;
  specificMemoryBanksList = [
    {
      memoryBankId: "114-0", //AOC
    },
    {
      memoryBankId: "73-0", //ALO
    },
    {
      memoryBankId: "37-0", //ALO MIN
    },
    {
      memoryBankId: "14-0", //CLO//CLOLITE
    },
    {
      memoryBankId: "149-0", //AOC PWM
    },
    {
      memoryBankId: "29-0", //AST
    },
    {
      memoryBankId: "1-0", //LUMIN
    },
    {
      memoryBankId: "35-0", //SRPSU
    },
    {
      memoryBankId: "19-0", //ZTV
    },
    {
      memoryBankId: "52-0",
    },
    {
      memoryBankId: "13-0", //LUMIN
    },
    {
      memoryBankId: "15-0", //LINESWITCH
    },
    {
      memoryBankId: "18-0", //AMPDIM
    },
    {
      memoryBankId: "75-0", //DC EMERGENCY
    },
    {
      memoryBankId: "74-0", //DTL
    },
    {
      memoryBankId: "113-0", //DTL
    },
    {
      memoryBankId: "36-0", //Coded Mains
    },
    {
      memoryBankId: "43-0", //OWP
    },
  ];
  regions: string[];
  searchName: any[];
  apiList: any = [];
  deviceInfo: any;
  fullDeviceList = "search/api/v1/search/v1/devices";
  envDeviceListURL: string;
  writingDevice: any;
  corruptBits: any;
  isNormalFlow: any;
  dtlSoftwareVersionSchema: any;
  dalipsuSoftwareVersionSchema: any;
  startConfigurationURL: any[];
  allMemoryBanks: any = { memoryBankId: "All" };
  envAloschemaDetails: any;
  configurationData: any;
  latestDeviceVersion: any = [];
  latestDvValue: any;
  retryButton: boolean = false;
  retryClicked: boolean = false;
  mtpSoftwareVersionSchema: any;
  dimminginterfaceSoftwareVersionSchema: any;
  cloSoftwareVersionSchema: any;
  aloSoftwareVersionSchema: any;
  aocSoftwareVersionSchema: any;
  touchdimSoftwareVersionSchema: any;
  diia253maintenanceSoftwareVersionSchema: any;
  corridorSoftwareVersionSchema: any;
  ztvSoftwareVersionSchema: any;
  ztvoutputSoftwareVersionSchema: any;
  lipSoftwareVersionSchema: any;
  rsoSoftwareVersionSchema: any;
  ampdimSoftwareVersionSchema: any;
  astSoftwareVersionSchema: any;
  cloliteSoftwareVersionSchema: any;
  dynadimSoftwareVersionSchema: any;
  dcemergencySoftwareVersionSchema: any;
  lineswitchSoftwareVersionSchema: any;
  mindimlevelSoftwareVersionSchema: any;
  owpSoftwareVersionSchema: any;
  codedmainsSoftwareVersionSchema: any;
  continueRead: boolean = false;
  errorCodedDC = [
    "206-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "304-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.1-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.2-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.3-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.4-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.5-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.6-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.7-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.8-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.9-Device not found! Please check the device and try again",
    "404.1-Reader not connected! Please check the reader and try again",
    "404.2-Device not found! Please check the device and try again",
    "405-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "412.1-Reader not connected! Please check the reader and try again",
    "412.2-Device not found! Please check the device and try again",
    "500-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "1203_1-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
  ];
  showExtraPopUp: boolean = true;
  showDeviceIDReadDC: boolean = false;
  showContniueNowTimer: NodeJS.Timer;
  invalidFeatureData: any = [];
  readerInfoSub: Subscription;
  statusObs$: Subscription;
  modalRefUnSubscribe: NgbModalRef;
  unSupportedDeviceListDevices: any = [];
  pathVal: string;
  isShowCancelBtn: boolean = false;
  lineSwitchSupported: boolean;
  ampDimSupported: boolean;
  codedMainsSupported: boolean;
  dynadimmerSupported: boolean;
  interdependencyObjSetIdentified: boolean=false;
  // readerUrl: any;
  // isTestCase: boolean=true;

  constructor(
    private service: ApiService,
    public activemodal: NgbActiveModal,
    private modalService: NgbModal,
    private http: HttpClient,
    public route: Router
  ) { }

  ngOnInit() {
    this.pathVal = this.service.getImageUrl(window.location.hostname);
    try {
      this.unSupportedDeviceListDevices = atob(dcUsupportedDeviceList.XIBPDevices)
    } catch (error) { }
    /* this.service.getGeneratePlugInPutData.subscribe(pluginData=>{
      if(pluginData!="")
      this.mem = pluginData;
    }) */
    let multiDeviceConfig = localStorage.getItem("multidevice");
    let pluginout = JSON.parse(localStorage.getItem("pluginOut"));
    if (pluginout != "") {
      this.mem = pluginout;
    }
    this.service.getconfigData.subscribe((data) => {
      this.readDevice = data;
    });
    this.service.closeModal.subscribe((data) => {
      if (data) {
        this.activemodal.close();
      }
    });
    this.readerInfoSub = this.service.getReaderInfo.subscribe((data) => {
      if (data) {
        this.readerData = data;
        this.hideStatus = true;
        this.readerName = data;
        this.deviceInfoUrl = "/peripheral/v1/nfc/{peripheralId}/devices";
        // this.getDeviceVersion();
        setTimeout(() => {
          this.dataInreader();
        }, 3000);
        /*  // setTimeout(() => {
         //   this.getListOfFeature();
         // }, 3000); */
      }
    });
    if (this.readDevice === "readDevice") {
      this.writingDevice = JSON.parse(localStorage.getItem("isWriting"));
      this.customerLoaderAfter = true;
      if (this.writingDevice) {
        this.readerName = JSON.parse(localStorage.getItem("readerInfo"));
        this.hideStatus = true;
        this.removeSuccessText = false;
        this.removeWritingHeader = true;
        this.writingDevicesContainer = true;
        this.isWritingRetryCheck = true;
        this.newReaderInfo();
      } else {
        this.isWritingRetryCheck = false;
        localStorage.setItem("readingDevice", JSON.stringify(true));
        this.customerLoaderAfter = true;
        this.service.getBaseUrl();
        this.service.getReadData
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((url) => {
            if (url.length > 0) {
              this.readerBaseUrl = url;
              this.unsubscribe.next();
              localStorage.setItem("reading", JSON.stringify(true));
              let status = this.getDeviceReader();
              // let status:any;
              //  setTimeout(()=>{
              //    status = this.getDeviceReader();
              //     },2000)
              let readerSelection = this.getReaderList();

              this.statusObs$ = status
                .pipe(
                  flatMap((data) => {
                    return readerSelection.pipe(
                      catchError((err) => {
                        // do stuff with err from API1 request
                        return throwError(err);
                      })
                    );
                  }),
                  catchError((err) => {
                    // do stuff with err from API2 request
                    return throwError(err);
                  })
                )
                .subscribe(
                  (results) => {
                    this.readerData = results;
                    localStorage.setItem(
                      "readerInfo",
                      JSON.stringify(this.readerData)
                    );

                    localStorage.setItem(
                      "readerDevices",
                      JSON.stringify(results)
                    );

                    let readers = JSON.parse(
                      localStorage.getItem("detectedReaderInfo")
                    );

                    let newReader = false;
                    if (!isNullOrUndefined(readers && readers["selectedReader"]) && results[0]['peripheralType'] !== readers["selectedReader"]['peripheralType']) {
                      newReader = true;
                    }

                    if (
                      !readers ||
                      (readers && isNullOrUndefined(readers["selectedReader"]) || newReader)
                    ) {
                      let detectedReader = {
                        readerType: "SimpleSet",
                        selectedReader: this.readerData[0],
                        readerConnected: true,
                      };

                      localStorage.setItem(
                        "detectedReaderInfo",
                        JSON.stringify(detectedReader)
                      );
                    }
                    if (results) {
                      this.hideStatus = true;
                      if (this.readerData.length === 1) {
                        this.readerName = this.readerData[0];
                        this.dataInreader();
                      } else {
                        if (this.isRetry) {
                          this.listReader = this.readerData;
                          const modalRef = this.modalService.open(
                            ReadDeviceSelectionPopupComponent,
                            {
                              backdrop: "static",
                              keyboard: false,
                              windowClass: "select-device-modal-comp",
                              centered: true,
                              size: "lg",
                            }
                          );
                          this.modalRefUnSubscribe = modalRef;
                          modalRef.componentInstance.readerName = this.listReader;
                          modalRef.componentInstance.clickEvent = false;
                          modalRef.componentInstance.componentFrom = "ReadDevice";
                        } else {
                          let readerSelected = JSON.parse(localStorage.getItem('detectedReaderInfo'))

                          this.readerName = readerSelected.selectedReader;
                          if (this.readerName === null) {
                            this.isRetry = true;
                          }
                          else
                            this.dataInreader();
                        }

                      }
                    }
                  },
                  (error) => {
                    this.customerLoaderAfter = true;
                    localStorage.removeItem("readerDevices");
                    let detectedReader = {
                      readerType: "SimpleSet",
                      selectedReader: null,
                      readerConnected: false,
                    };

                    localStorage.setItem(
                      "detectedReaderInfo",
                      JSON.stringify(detectedReader)
                    );
                    this.noReader = true;
                    if (error.status) {
                      this.errorCodedDC.forEach((errorCode) => {
                        if (errorCode.includes(error.status)) {
                          if (error.status === errorCode.split("-")[0]) {
                            this.errorMessage = errorCode.split("-")[1];
                            this.showDCError(
                              error.message,
                              this.errorMessage,
                              this.showExtraPopUp
                            );
                          }
                        }
                      });
                    } else {
                      if (error["Message"]) {
                        this.errorMessage = error["Message"];
                      } else {
                        this.errorMessage = "Please check the connection";
                        this.retryButton = true;
                      }
                    }
                    this.retryButton = true;
                    this.hideStatus = true;
                    this.customerLoaderAfter = false;
                    this.customerLoader = false;
                  }
                );
            }
          });
      }
    }

    setTimeout(() => {
      this.service.getEnvValue();
      this.service.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (url.length > 0) {
            this.url = url;
          }
        });
    }, 1000);
  }
  showDCError(errorMessage: any, errorDescription: any, show: boolean) {
    if (show) {
      const modalRef = this.modalService.open(ErrorComponent, {
        keyboard: false,
        centered: true,
        windowClass: "error-style, error-style1",
      });
      modalRef.componentInstance.errorName = errorMessage;
      modalRef.componentInstance.errorDescription = errorDescription;
    }
  }

  newReaderInfo() {
    this.customerLoaderAfter = true;
    this.service.getBaseUrl();
    this.service.getReadData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.readerBaseUrl = url;
          this.unsubscribe.next();
          localStorage.setItem("reading", JSON.stringify(true));
          setTimeout(() => {
            this.getReaderList().subscribe(
              (data) => {
                this.readerData = data;
                localStorage.setItem(
                  "readerInfo",
                  JSON.stringify(this.readerData)
                );

                localStorage.setItem("readerDevices", JSON.stringify(data));

                let readers = JSON.parse(
                  localStorage.getItem("detectedReaderInfo")
                );

                let newReader = false;
                if (!isNullOrUndefined(readers && readers["selectedReader"]) && data[0]['peripheralType'] !== readers["selectedReader"]['peripheralType']) {
                  newReader = true;
                }

                if (
                  !readers ||
                  (readers && isNullOrUndefined(readers["selectedReader"]) || newReader)
                ) {
                  let detectedReader = {
                    readerType: "SimpleSet",
                    selectedReader: this.readerData[0],
                    readerConnected: true,
                  };

                  localStorage.setItem(
                    "detectedReaderInfo",
                    JSON.stringify(detectedReader)
                  );
                }
                if (this.readerData) {
                  this.hideStatus = true;
                  if (this.readerData.length === 1) {
                    this.readerName = this.readerData[0];
                    this.dataInreader();
                  } else {
                    if (this.isRetry) {
                      this.listReader = this.readerData;
                      const modalRef = this.modalService.open(
                        ReadDeviceSelectionPopupComponent,
                        {
                          backdrop: "static",
                          keyboard: false,
                          windowClass: "select-device-modal-comp",
                          centered: true,
                          size: "lg",
                        }
                      );
                      this.modalRefUnSubscribe = modalRef;
                      modalRef.componentInstance.readerName = this.listReader;
                      modalRef.componentInstance.clickEvent = false;
                      modalRef.componentInstance.componentFrom = "ReadDevice";
                    }
                    else {
                      let readerSelected = JSON.parse(localStorage.getItem('detectedReaderInfo'))
                      this.readerName = readerSelected.selectedReader;
                      if (this.readerName === null) {
                        this.isRetry = true;
                      }
                      else
                        this.dataInreader();

                    }
                  }
                }
              },
              (error) => {
                this.removeWritingHeader = false;
                this.writingDevicesContainer = false;
                this.customerLoaderAfter = true;
                this.noReader = true;
                this.retryButton = true;
                localStorage.removeItem("readerDevices");
                let detectedReader = {
                  readerType: "SimpleSet",
                  selectedReader: null,
                  readerConnected: false,
                };

                localStorage.setItem(
                  "detectedReaderInfo",
                  JSON.stringify(detectedReader)
                );
                if (error.status) {
                  this.errorCodedDC.forEach((errorCode) => {
                    if (errorCode.includes(error.status)) {
                      if (error.status === errorCode.split("-")[0]) {
                        this.errorMessage = errorCode.split("-")[1];
                        this.showDCError(
                          error.message,
                          this.errorMessage,
                          this.showExtraPopUp
                        );
                      }
                    }
                  });
                } else {
                  if (error["Message"]) {
                    this.errorMessage = error["Message"];
                  } else {
                    this.errorMessage = "Please check the connection";
                    this.retryButton = true;
                  }
                }
                this.hideStatus = true;
                this.customerLoaderAfter = false;
                this.customerLoader = false;
              }
            );
          }, 1000);
        }
      });
  }
  getProductionInfo(url, deviceNfcId) {
    return this.service.getProductionInfo(url, deviceNfcId);
  }
  dataInreader() {
    this.customerLoaderAfter = true;
    this.service.getBaseUrl();
    this.service.getReadData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        this.envHostName = url;
        if (url.length > 0) {
          this.readerBaseUrl = url;
          this.unsubscribe.next();
        setTimeout(() => {
          this.getDeviceInfo().subscribe(
            (data) => {
              let deviceInfo = JSON.parse(
                localStorage.getItem("readerDeviceFullInfo")
              );
              let readingCheck = localStorage.getItem("readingDevice");
              if (
                !isNullOrUndefined(
                  deviceInfo && deviceInfo[0] && deviceInfo[0]["deviceName"]
                )
              ) {
                data[0]["deviceMetadata"]["deviceName"] =
                  deviceInfo[0]["deviceName"];
              }
              if (data[0].deviceOwpStatus === "Enabled" && readingCheck === "false") {
                this.readerData = false;
                this.removeWritingHeader = false;
                this.writingDevicesContainer = false;
                this.noReader = true;
                this.customerLoader = false;
                this.loading = false;
                this.customerLoaderAfter = false;
                this.retryButton = true;
                this.writingDevicesContainer = false;
                this.errorMessage =
                  "This device is OWP protected and therefore not accessible. Please select another device!";
              } else if (data) {
                this.removeSuccessText = true;
                this.hideStatus = true;
                this.readerDeviceData = data[0];
                localStorage.setItem(
                  "setReaderDEviceInfo",
                  JSON.stringify(this.readerDeviceData)
                );
                localStorage.setItem("reading", JSON.stringify(false));
                let configDeviceInfo = JSON.parse(
                  localStorage.getItem("configurationData")
                );
                this.isNormalFlow = JSON.parse(
                  localStorage.getItem("normalFlow")
                );
                let checkDeviceSoftware;
                if (localStorage.getItem("comparefirmwarenoLatest")) {
                  checkDeviceSoftware = JSON.parse(
                    localStorage.getItem("comparefirmwarenoLatest")
                  );
                  if (checkDeviceSoftware.length > 3) {
                    checkDeviceSoftware = checkDeviceSoftware.slice(0, -2);
                  }
                }
                if (
                  configDeviceInfo &&
                  configDeviceInfo["deviceName"] ===
                    this.readerDeviceData.deviceMetadata["deviceName"] &&
                  configDeviceInfo["device12nc"] ===
                    this.readerDeviceData.deviceMetadata["device12nc"] &&
                  checkDeviceSoftware ===
                    this.readerDeviceData.deviceMetadata["deviceSwVersion"]
                ) {
                  this.hideStatus = true;
                  this.removeSuccessText = false;
                  this.removeWritingHeader = true;
                  this.writingDevicesContainer = true;
                  this.service.getEnvValue();
                  this.service.getEnvData
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((url) => {
                      if (url.length > 0) {
                        this.url = url;
                        /*  if(this.mem!=""){
                    this.writingDeviceWithPC(this.mem);
                  }
                  else{ */
                        this.generatePluginOutput();
                        /*  } */
                      }
                    });
                } else if (this.isNormalFlow) {
                  this.readerData = false;
                  this.noReader = true;
                  this.removeWritingHeader = false;
                  this.writingDevicesContainer = false;
                  this.customerLoader = false;
                  this.loading = false;
                  this.customerLoaderAfter = false;
                  this.errorMessage =
                    "This device is not matching with the connected device. Please choose another device.";
                  this.retryButton = true;
                  this.showDCError(
                    "Device not matching",
                    this.errorMessage,
                    this.showExtraPopUp
                  );
                } else {
                  this.deviceNfcId = data[0].deviceNfcId;
                  let prodURL: any = "";
                  this.service.getEnvValue();
                  this.service.getEnvData
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((url) => {
                      if (url.length > 0) {
                        this.showDeviceIDReadDC = true;
                        this.getProductionInfo(url, this.deviceNfcId).subscribe(
                          (productionInfo: any) => {
                            if (
                              productionInfo.hasOwnProperty(
                                "deviceProductionInformation"
                              )
                            ) {
                              this.isProductionInfoAvailable = true;
                              this.productionDate =
                                productionInfo.deviceProductionInformation.productionDate;
                              this.productionBatchId =
                                productionInfo.deviceProductionInformation.productionBatchId;
                              this.sku =
                                productionInfo.deviceProductionInformation.sku;
 
                              localStorage.setItem("prodInfoStatus", "yes");
                              //this.service.setshowContnueBtn(true);
                            } else {
                              this.isProductionInfoAvailable = false;
                              localStorage.setItem("prodInfoStatus", "no");
                              // this.service.setshowContnueBtn(false);
                            }
                          }
                        );
                      }
                    });
                  //this.unsubscribe.next();
                  this.showDeviceIDReadDC = false;
 
                  this.searchWith12Nc(
                    this.readerDeviceData.deviceMetadata.device12nc
                  );
                  this.showContniueNowTimer = setInterval(() => {
                    let contniueSHowStatus =
                      localStorage.getItem("showContinueNow");
                    if (contniueSHowStatus === "yes") {
                      this.continueRead = true;
                    } else {
                      this.continueRead = false;
                    }
                  }, 1000);
                }
              }
            },
            (err) => {
              this.retryButton = true;
              this.readerData = false;
              this.removeWritingHeader = false;
              this.writingDevicesContainer = false;
              this.noReader = true;
              this.customerLoader = false;
              this.loading = false;
              this.customerLoaderAfter = false;
              let myObj = err;
              this.errorMessage = myObj.Message;
              if (err.status) {
                this.errorCodedDC.forEach((errorCode) => {
                  if (errorCode.includes(err.status)) {
                    if (err.status === errorCode.split("-")[0]) {
                      this.errorMessage = errorCode.split("-")[1];
                      this.showDCError(
                        err.message,
                        this.errorMessage,
                        this.showExtraPopUp
                      );
                    }
                  }
                });
              }
              this.isShowCancelBtn = true;
            }
          );
        }, 1000);
        }
      });
  }

  searchWith12Nc(searchText) {
    this.specialErrorNoDatabase = false;
    this.service.getEnvValue();
    this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.url = url;
          let headers: any = new HttpHeaders({});
          let j = 0;
          this.regions = ["EMEA", "NA", "CN", "ROA"];
          const htHeader = new HttpHeaders()
            .set("region", this.regions[0])
            .append("region", this.regions[1])
            .append("region", this.regions[2])
            .append("region", this.regions[3]);
          this.searchTextValue(searchText, htHeader, j);
          this.unsubscribe.next();
          this.searchName = [];
          forkJoin(this.apiList).subscribe(
            (resp) => {
              this.deviceInfo = [].concat.apply([], resp);
              if (this.deviceInfo.length > 0 && !this.unSupportedDeviceListDevices.includes(searchText)) {
                localStorage.setItem(
                  "readerDeviceFullInfo",
                  JSON.stringify(this.deviceInfo)
                );
                try {
                  localStorage.setItem(
                    "NFC_Protocol",
                    this.deviceInfo[0].configurationInterface
                  );
                } catch (error) { }
                this.getDeviceVersion();
                setTimeout(() => {
                  this.getListOfFeature();
                }, 1500);
                //this.getListOfFeature();
              } else {
                this.specialErrorNoDatabase = true;
                this.readerData = false;
                this.noReader = true;
                this.customerLoader = false;
                this.loading = false;
                this.customerLoaderAfter = false;
                let deviceInfoUnAvailable =
                  this.readerDeviceData.deviceMetadata;
                //this.errorMessage = 'Device is not available in our database. So we cannot read or write, please choose another device';
                this.errorMessage =
                  "This device is currently not supported \n \n" +
                  deviceInfoUnAvailable.deviceName +
                  " " +
                  deviceInfoUnAvailable.deviceSwVersion +
                  "\n 12nc: " +
                  deviceInfoUnAvailable.device12nc +
                  "\n \n Please contact your local Signify representative!";
                //this.showDCError("Not found", this.errorMessage, this.showExtraPopUp);
              }
            },
            (err) => {
              this.readerData = false;
              this.noReader = true;
              this.customerLoader = false;
              this.loading = false;
              this.customerLoaderAfter = false;
              let myObj = err;
              this.errorMessage = myObj.Message ? myObj.Message : myObj.message;
              if (this.errorMessage === undefined) {
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = "Authorization error";
                modalRef.componentInstance.errorDescription =
                  "User does not have sufficient access rights to proceed";
              }
              this.isShowCancelBtn = true;
            }
          );
        }
      });
  }

  searchTextValue(searchText, headers?, j?) {
    if (this.url.length) {
      this.envDeviceListURL =
        this.url + this.fullDeviceList + "?query=nc12%3A*";
      this.unsubscribe.next();
      this.apiList[j] = this.service.apiCall(
        "get",
        this.envDeviceListURL + searchText + "*" + "&showArchive=true",
        "",
        { headers: headers }
      );
    }
  }

  getMemoreyBankList() {
    // this.customerLoaderAfter = true;
    this.service.getBaseUrl();
    this.service.getReadData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.unsubscribe.next();
          this.getMemoryBank().subscribe(
            (data) => {
              if (data) {
                localStorage.setItem("reading", JSON.stringify(true));
                let compatibleFeat = this.checkCompatibleFeat();
                let corruptBit = this.checkCorruptBit();

                forkJoin([compatibleFeat, corruptBit]).subscribe(
                  (results) => {
                    this.corruptBits = results[1];
                    //this.featureWithId = results[0];
                    this.featureWithId = JSON.parse(
                      atob(results[0]["memoryBank"])
                    );
                    localStorage.setItem(
                      "compatibleFeatureID",
                      JSON.stringify(this.featureWithId)
                    );
                    this.corruptBits["memoryBanks"].forEach((element) => {
                      for (let i = 0; i < this.featureWithId.length; i++) {
                        if (
                          element["memoryBankId"] ===
                          this.featureWithId[i]["memoryBankId"]
                        ) {
                          this.corrupt.push(
                            this.featureWithId[i]["featureName"]
                          );
                        }
                      }
                    });
                    let isWriting = JSON.parse(
                      localStorage.getItem("isWriting")
                    );
                    if (!isWriting) {
                      setTimeout(() => {
                        this.getConfiguration();
                      }, 100);
                    } else {
                      this.getImportPayLoad();
                    }
                  },
                  (err) => {
                    this.feature12NcName = [];
                    this.service.sendFeatures(this.feature12NcName);
                    this.readerData = false;
                    this.noReader = true;
                    this.customerLoader = false;
                    this.loading = false;
                    this.customerLoaderAfter = false;
                    let myObj = err;
                    this.errorMessage = myObj.Message;
                    if (err.status) {
                      this.errorCodedDC.forEach((errorCode) => {
                        if (errorCode.includes(err.status)) {
                          if (err.status === errorCode.split("-")[0]) {
                            this.errorMessage = errorCode.split("-")[1];
                            this.showDCError(
                              err.message,
                              this.errorMessage,
                              this.showExtraPopUp
                            );
                            this.retryButton = true;
                          }
                        }
                      });
                    } else {
                      if (
                        this.errorMessage ===
                        "An error occurred while retrieving the corrupt Memory Banks! \r\n Please check the Device or Peripheral ID and try again!"
                      ) {
                        this.errorMessage =
                          "An error occured while retrieving the corrupt data. Please check the Device or Peripheral ID and try again!";
                        this.retryButton = true;
                      }
                    }
                  }
                );
              }
            },
            (err) => {
              this.feature12NcName = [];
              this.service.sendFeatures(this.feature12NcName);
              this.readerData = false;
              this.noReader = true;
              this.customerLoader = false;
              this.loading = false;
              this.customerLoaderAfter = false;
              let myObj = err;
              this.errorMessage = myObj.Message;

              if (err.status) {
                this.errorCodedDC.forEach((errorCode) => {
                  if (errorCode.includes(err.status)) {
                    if (err.status === errorCode.split("-")[0]) {
                      this.errorMessage = errorCode.split("-")[1];
                      this.showDCError(
                        err.message,
                        this.errorMessage,
                        this.showExtraPopUp
                      );
                      this.retryButton = true;
                    }
                  }
                });
              } else {
                if (
                  this.errorMessage ===
                  "An error occurred while retrieving the list of supported Memory Banks! \r\n Please check the Device or Peripheral ID and try again!"
                ) {
                  this.errorMessage =
                    "An error occured while retrieving the data. Please check the Device or Peripheral ID and try again!";
                  this.retryButton = true;
                }
              }
              this.isShowCancelBtn = true;
            }
          );
        }
      });
  }

  callCorruptBnk() {
    // this.customerLoaderAfter = true;
    this.service.getBaseUrl();
    this.service.getReadData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.unsubscribe.next();
          this.checkCorruptBit().subscribe(
            (data: any) => {
              if (data) {
                this.hideStatus = true;
                if (data.memoryBanks.length > 0) {
                  data.memoryBanks.forEach((element) => {
                    for (let i = 0; i < this.featureWithId.length; i++) {
                      if (
                        element["memoryBankId"] ===
                        this.featureWithId[i]["memoryBankId"]
                      ) {
                        this.corrupt.push(this.featureWithId[i]["featureName"]);
                      }
                    }
                  });
                }
                if (this.writingDevice) {
                  this.getImportPayLoad();
                }
              }
            },
            (err) => {
              this.feature12NcName = [];
              this.service.sendFeatures(this.feature12NcName);
              this.readerData = false;
              this.writingDevicesContainer = false;
              this.noReader = true;
              this.customerLoader = false;
              this.loading = false;
              this.customerLoaderAfter = false;
              let myObj = err;
              this.errorMessage = myObj.Message;
              if (err.status) {
                this.errorCodedDC.forEach((errorCode) => {
                  if (errorCode.includes(err.status)) {
                    if (err.status === errorCode.split("-")[0]) {
                      this.errorMessage = errorCode.split("-")[1];
                      this.showDCError(
                        err.message,
                        this.errorMessage,
                        this.showExtraPopUp
                      );
                      this.retryButton = true;
                    }
                  }
                });
              }
            }
          );
        }
      });
  }
  continueReadClicked() {
    // localStorage.setItem("readContinue", "yes");
    // this.service.modalClose("close");
    this.activemodal.close();
    localStorage.setItem("normalFlow", JSON.stringify(false));
    this.route.navigate(["/featureLanding"]);
  }
  getListOfFeature() {
    // this.customerLoaderAfter = true;
    localStorage.setItem("reading", JSON.stringify(false));
    this.service.getEnvValue();
    this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.unsubscribe.next();
          this.envScehmaLIstApi = url;

          // this.getReaderData(this.readerDeviceData.deviceMetadata.device12nc,this.readerDeviceData.deviceMetadata.deviceSwVersion).subscribe((data) => {
          this.getReaderData(
            this.readerDeviceData.deviceMetadata.device12nc,
            this.latestDvValue
          ).subscribe(
            (data) => {
              if (data) {
                this.removeSuccessText = false;
                this.allFeatures = data["metadata"]["schemas"];

                if (this.allFeatures.length === 0) {
                  this.activemodal.close();
                  this.route.navigate(["/featureNonSupported"]);
                }
                this.allFeatures.forEach((element, index) => {
                  var splitted = element.split(":")[1];
                  if (splitted) {
                    var feature12NCvalue = splitted.substring(
                      0,
                      splitted.length -
                      this.readerDeviceData.deviceMetadata.device12nc.length
                    );
                    this.feature12NcName.push(feature12NCvalue);
                  }
                  let val = this.allFeatures.toString();
                  let versiontoString =
                    val.match(/[^,]*alo[^,]*/g) ||
                    val.match(/[^,]*clo[^,]*/g) ||
                    val.match(/[^,]*aoc[^,]*/g) ||
                    val.match(/[^,]*ztv[^,]*/g);
                  if (versiontoString) {
                    this.softwareVersionSchema = versiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*ztv[^,]*/g)) {
                    let ztvversiontoString = val.match(/[^,]*ztv[^,]*/g);
                    this.ztvSoftwareVersionSchema = ztvversiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*ztvoutput[^,]*/g)) {
                    let ztvoutputversiontoString =
                      val.match(/[^,]*ztvoutput[^,]*/g);
                    this.ztvoutputSoftwareVersionSchema =
                      ztvoutputversiontoString.toString().split(":")[2];
                  }
                  if (val.match(/[^,]*lip[^,]*/g)) {
                    let lipversiontoString = val.match(/[^,]*lip[^,]*/g);
                    if (lipversiontoString.length > 1) {
                      this.lipSoftwareVersionSchema = lipversiontoString[1]
                        .toString()
                        .split(":")[2];
                    } else {
                      this.lipSoftwareVersionSchema = lipversiontoString
                        .toString()
                        .split(":")[2];
                    }
                  }
                  if (val.match(/[^,]*rso[^,]*/g)) {
                    let rsoversiontoString = val.match(/[^,]*rso[^,]*/g);
                    this.rsoSoftwareVersionSchema = rsoversiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*alo[^,]*/g)) {
                    let aloversiontoString = val.match(/[^,]*alo[^,]*/g);
                    this.aloSoftwareVersionSchema = aloversiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*aoc[^,]*/g)) {
                    let aocversiontoString = val.match(/[^,]*aoc[^,]*/g);
                    this.aocSoftwareVersionSchema = aocversiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*touchdim[^,]*/g)) {
                    let touchdimversiontoString =
                      val.match(/[^,]*touchdim[^,]*/g);
                    this.touchdimSoftwareVersionSchema = touchdimversiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*diia253maintenance[^,]*/g)) {
                    let diia253maintenanceversiontoString = val.match(
                      /[^,]*diia253maintenance[^,]*/g
                    );
                    this.diia253maintenanceSoftwareVersionSchema =
                      diia253maintenanceversiontoString
                        .toString()
                        .split(":")[2];
                  }
                  if (val.match(/[^,]*corridor[^,]*/g)) {
                    let corridorversiontoString =
                      val.match(/[^,]*corridor[^,]*/g);
                    this.corridorSoftwareVersionSchema = corridorversiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*luminfoformat[^,]*/g)) {
                    let luminversiontoString = val.match(/[^,]*luminfo[^,]*/g);
                    this.luminSoftwareVersionSchema = luminversiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*dtl[^,]*/g)) {
                    let dtlVersiontoString = val.match(/[^,]*dtl[^,]*/g);
                    this.dtlSoftwareVersionSchema = dtlVersiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*dalipsu[^,]*/g)) {
                    let dalipsuVersiontoString =
                      val.match(/[^,]*dalipsu[^,]*/g);
                    this.dalipsuSoftwareVersionSchema = dalipsuVersiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*mtp[^,]*/g)) {
                    let mtpversiontoString = val.match(/[^,]*mtp[^,]*/g);
                    this.mtpSoftwareVersionSchema = mtpversiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*dimminginterface[^,]*/g)) {
                    let dimminginterfaceversiontoString = val.match(
                      /[^,]*dimminginterface[^,]*/g
                    );
                    this.dimminginterfaceSoftwareVersionSchema =
                      dimminginterfaceversiontoString.toString().split(":")[2];
                  }
                  if (val.match(/[^,]*clo[^,]*/g)) {
                    let cloVersiontoString = val.match(/[^,]*clo[^,]*/g);
                    this.cloSoftwareVersionSchema = cloVersiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*ampdim[^,]*/g)) {
                    let ampdimVersiontoString = val.match(/[^,]*ampdim[^,]*/g);
                    this.ampdimSoftwareVersionSchema = ampdimVersiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*clolite[^,]*/g)) {
                    let cloliteVersiontoString =
                      val.match(/[^,]*clolite[^,]*/g);
                    this.cloliteSoftwareVersionSchema = cloliteVersiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*ast[^,]*/g)) {
                    let astVersiontoString = val.match(/[^,]*ast[^,]*/g);
                    this.astSoftwareVersionSchema = astVersiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*dynadim[^,]*/g)) {
                    let dynadimVersiontoString =
                      val.match(/[^,]*dynadim[^,]*/g);
                    this.dynadimSoftwareVersionSchema = dynadimVersiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*dcemergency[^,]*/g)) {
                    let dcemergencyVersiontoString = val.match(
                      /[^,]*dcemergency[^,]*/g
                    );
                    this.dcemergencySoftwareVersionSchema =
                      dcemergencyVersiontoString.toString().split(":")[2];
                  }
                  if (val.match(/[^,]*lineswitch[^,]*/g)) {
                    let lineswitchVersiontoString =
                      val.match(/[^,]*lineswitch[^,]*/g);
                    this.lineswitchSoftwareVersionSchema =
                      lineswitchVersiontoString.toString().split(":")[2];
                  }
                  if (val.match(/[^,]*mindimlevel[^,]*/g)) {
                    let mindimlevelVersiontoString = val.match(
                      /[^,]*mindimlevel[^,]*/g
                    );
                    this.mindimlevelSoftwareVersionSchema =
                      mindimlevelVersiontoString.toString().split(":")[2];
                  }
                  if (val.match(/[^,]*owp[^,]*/g)) {
                    let owpVersiontoString = val.match(/[^,]*owp[^,]*/g);
                    this.owpSoftwareVersionSchema = owpVersiontoString
                      .toString()
                      .split(":")[2];
                  }
                  if (val.match(/[^,]*codedmains-[^,]*/g)) {
                    let codedmainsversiontoString = val.match(
                      /[^,]*codedmains-[^,]*/g
                    );
                    this.codedmainsSoftwareVersionSchema =
                      codedmainsversiontoString.toString().split(":")[2];
                  }
                  this.everySchemaofDevice = {
                    swschema: this.softwareVersionSchema,
                    luminSchemaVer: this.luminSoftwareVersionSchema,
                    dtlSchemaVer: this.dtlSoftwareVersionSchema,
                    dalipsuSchemaVer: this.dalipsuSoftwareVersionSchema,
                    mtpSoftVer: this.mtpSoftwareVersionSchema,
                    dimminginterfaceSoftVer:
                      this.dimminginterfaceSoftwareVersionSchema,
                    cloSoftVer: this.cloSoftwareVersionSchema,
                    aloSoftVer: this.aloSoftwareVersionSchema,
                    aocSoftVer: this.aocSoftwareVersionSchema,
                    touchdimSoftVer: this.touchdimSoftwareVersionSchema,
                    diia253maintenanceSoftVer:
                      this.diia253maintenanceSoftwareVersionSchema,
                    corridorSoftVer: this.corridorSoftwareVersionSchema,
                    ztvSoftVer: this.ztvSoftwareVersionSchema,
                    ztvoutputSoftVer: this.ztvoutputSoftwareVersionSchema,
                    lipSoftVer: this.lipSoftwareVersionSchema,
                    rsoSoftVer: this.rsoSoftwareVersionSchema,
                    ampdimSoftVer: this.ampdimSoftwareVersionSchema,
                    astSoftVer: this.astSoftwareVersionSchema,
                    cloliteSoftVer: this.cloliteSoftwareVersionSchema,
                    dynadimmerSoftVer: this.dynadimSoftwareVersionSchema,
                    dcemergencySoftVer: this.dcemergencySoftwareVersionSchema,
                    lineswitchSoftVer: this.lineswitchSoftwareVersionSchema,
                    mindimlevelSoftVer: this.mindimlevelSoftwareVersionSchema,
                    owpSoftVer: this.owpSoftwareVersionSchema,
                    codedmainsSoftVer: this.codedmainsSoftwareVersionSchema,
                  };
                  localStorage.setItem(
                    "softwareSchema",
                    JSON.stringify(this.everySchemaofDevice)
                  );
                });
                let writingDevice = JSON.parse(
                  localStorage.getItem("isWriting")
                );
                this.customerLoaderAfter = true;
                if (!writingDevice) {
                  this.hideStatus = true;
                  this.getMemoreyBankList();
                }
                this.service.sendFeatures(this.feature12NcName);
                this.hideStatus = true;
                // this.customerLoaderAfter = false;
              }
            },
            (err) => {
              this.retryButton = true;
              this.readerData = false;
              this.removeWritingHeader = false;
              this.writingDevicesContainer = false;
              this.noReader = true;
              this.customerLoader = false;
              this.loading = false;
              this.customerLoaderAfter = false;
              let myObj = err;
              this.errorMessage = myObj.message;
              if (err.code) {
                this.errorCodedDC.forEach((errorCode) => {
                  if (errorCode.includes(err.code + "_1")) {
                    if ("" + err.code + "_1" === errorCode.split("-")[0]) {
                      this.errorMessage = errorCode.split("-")[1];
                      this.showDCError(
                        err.message,
                        this.errorMessage,
                        this.showExtraPopUp
                      );
                    }
                  }
                });
              }
              this.isShowCancelBtn = true;
            }
          );
        }
      });
  }

  cancel() {
    localStorage.removeItem("configurationData");
    localStorage.removeItem("supporteFeatures");
    this.service.removeModes("invalid");
    this.service.modalClose("");
    this.service.readerInfo("");
    this.unsubscribe.unsubscribe();
    this.service.sendFeatures("");
    this.service.sendCorruptFeat("");
    this.service.sendNonCorrupt("");
    this.service.redFailedFeatures("");
  }

  getConfiguration() {
    localStorage.setItem("reading", JSON.stringify(false));
    this.service.getEnvValue();
    this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.unsubscribe.next();
          this.startConfigurationURL = url;
          this.configurationAPI(this.readerDeviceData.deviceMetadata).subscribe(
            (data) => {
              if (data) {
                this.hideStatus = true;
                this.startConfiguration = data;
                localStorage.setItem(
                  "readerConfigurationData",
                  JSON.stringify(this.startConfiguration)
                );
                this.getFeaturePayload();
              }
            },
            (err) => {
              this.feature12NcName = [];
              this.service.sendFeatures(this.feature12NcName);
              this.readerData = false;
              this.noReader = true;
              this.customerLoader = false;
              this.loading = false;
              this.customerLoaderAfter = false;
              let myObj = err;
              this.errorMessage = myObj.message;
              this.specialErrorNoDatabase = true;
              if (this.errorMessage === "Device type Not Exist.") {
                let deviceInfoUnAvailable =
                  this.readerDeviceData.deviceMetadata;
                // this.errorMessage = 'Device is not available in our database. So we cannot read or write, please choose another device';
                this.errorMessage =
                  "This device is currently not supported \n \n" +
                  deviceInfoUnAvailable.deviceName +
                  " " +
                  deviceInfoUnAvailable.deviceSwVersion +
                  "\n 12nc: " +
                  deviceInfoUnAvailable.device12nc +
                  "\n \n Please contact your local Signify representative!";
                // this.showDCError("Not found", this.errorMessage,this.showExtraPopUp);
              }
              this.isShowCancelBtn = true;
            }
          );
        }
      });
  }

  getFeaturePayload() {
    this.service.getBaseUrl();
    this.service.getReadData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (this.url.length > 0) {
          let supportedMB: any[] = [];
          this.feature12NcName.forEach((featureMBName) => {
            switch (featureMBName) {
              case "luminfoformat3-":
                supportedMB.push({
                  memoryBankId: "52-0",
                });
                supportedMB.push({
                  memoryBankId: "1-0",
                });
                break;
              case "luminfoformat0-":
                supportedMB.push({
                  memoryBankId: "52-0",
                });
                supportedMB.push({
                  memoryBankId: "1-0",
                });
                break;
              case "clo-":
                supportedMB.push({
                  memoryBankId: "14-0",
                });
                break;
              case "ast-":
                supportedMB.push({
                  memoryBankId: "29-0",
                });
                break;
              case "srpsu-":
                supportedMB.push({
                  memoryBankId: "35-0",
                });
                break;
              case "alo-":
                supportedMB.push({
                  memoryBankId: "37-0",
                });
                supportedMB.push({
                  memoryBankId: "73-0",
                });
                break;
              case "aoc-":
                supportedMB.push({
                  memoryBankId: "114-0",
                });
                supportedMB.push({
                  memoryBankId: "149-0",
                });

                break;
              case "clolite-":
                supportedMB.push({
                  memoryBankId: "14-0",
                });
                break;
              case "ztv-":
                supportedMB.push({
                  memoryBankId: "19-0",
                });
                break;
              case "dynadimmer-":
                supportedMB.push({
                  memoryBankId: "13-0",
                });
                break;
              case "lineswitch-":
                supportedMB.push({
                  memoryBankId: "15-0",
                });
                break;
              case "ampdim-":
                supportedMB.push({
                  memoryBankId: "18-0",
                });
                break;
              case "dcemergency-":
                supportedMB.push({
                  memoryBankId: "75-0",
                });
                break;
              case "dtl-":
                supportedMB.push({
                  memoryBankId: "74-0",
                });
                break;
              case "mtp-":
                supportedMB.push({
                  memoryBankId: "113-0",
                });
                break;
              case "mindimlevel-":
                supportedMB.push({
                  memoryBankId: "30-0",
                });
                break;
              case "codedmains-":
                supportedMB.push({
                  memoryBankId: "36-0",
                });
                break;
              case "touchdim-":
                supportedMB.push({
                  memoryBankId: "27-0",
                });
                break;
              case "ztvoutput-":
                supportedMB.push({
                  memoryBankId: "158-0",
                });
                break;
              case "rso-":
                supportedMB.push({
                  memoryBankId: "156-0",
                });
                break;
              case "lip-":
                supportedMB.push({
                  memoryBankId: "162-0",
                });
                break;
              case "corridor-":
                supportedMB.push({
                  memoryBankId: "16-0",
                });
              case "diia253maintenance-":
                supportedMB.push({
                  memoryBankId: "207-0",
                });
                break;
                case "owp-":
                  supportedMB.push({
                    memoryBankId: "43-0",
                });
                supportedMB.push({
                  memoryBankId: "44-0",
                });
                break;
              default:
                break;
            }
          });
          let allPayResp = {
            memorybankIds:
              //this.specificMemoryBanksList
              supportedMB,
          };
          this.unsubscribe.next();
          //this.service.apiCall('post',this.readerBaseUrl+this.payLoadUrl.replace('{peripheralId}',this.readerName['peripheralType']).replace('{deviceNfcId}', this.readerDeviceData['deviceNfcId']),allPayResp)
          this.service
            .apiCall(
              "post",
              this.readerBaseUrl +
              this.payLoadUrl
                .replace("{peripheralId}", this.readerName["peripheralId"])
                .replace(
                  "{deviceNfcId}",
                  this.readerDeviceData["deviceNfcId"]
                ),
              allPayResp
            )
            .subscribe(
              (resp) => {
                this.mem = resp;
                this.getImportPayLoad();
              },
              (err) => {
                this.feature12NcName = [];
                this.service.sendFeatures(this.feature12NcName);
                this.writingDevicesContainer = false;
                this.readerData = false;
                this.noReader = true;
                this.customerLoader = false;
                this.loading = false;
                this.customerLoaderAfter = false;
                let myObj = err;
                this.errorMessage = myObj.Message;
                if (err.status) {
                  this.errorCodedDC.forEach((errorCode) => {
                    if (errorCode.includes(err.status)) {
                      if (err.status === errorCode.split("-")[0]) {
                        this.errorMessage = errorCode.split("-")[1];
                        this.showDCError(
                          err.message,
                          this.errorMessage,
                          this.showExtraPopUp
                        );
                        this.retryButton = true;
                      }
                    }
                  });
                } else {
                  if (
                    this.errorMessage ===
                    "An error occurred while returning the memory bank properties! \r\n Please check the Device or Peripheral ID and try again!"
                  ) {
                    this.errorMessage =
                      "An error occurred while returning the properties!  Please check the Device or Peripheral ID and try again!";
                    this.retryButton = true;
                  }
                }
                this.isShowCancelBtn = true;
              }
            );
        }
      });
  }

  getImportPayLoad() {
    // this.customerLoaderAfter = true;
    localStorage.setItem("reading", JSON.stringify(false));
    var configurationDataValue = JSON.parse(
      localStorage.getItem("configurationData")
    );
    let uniqueConfigIDs = ["NA"];
    if (configurationDataValue != null) {
      uniqueConfigIDs = configurationDataValue["configurationIDUniqueDC"];
    }

    this.service.getEnvValue();
    this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (this.url.length > 0) {
          let enyDryFlag = false;
          if (this.mem.hasOwnProperty("iv")) {
            this.envScehmaLIstApi = this.url + this.importPayLoadUrl;
            enyDryFlag = true;
          } else {
            this.envScehmaLIstApi = this.url + this.importPayLoadUrlNE;
            enyDryFlag = false;
          }

          this.unsubscribe.next();
          let i = 0;
          this.apiListResponse = [];
          let newObj = [];
          let aloMinObj = [];
          let owpFeatureObj = [];
          let aocMinObj = [];
          let lumInfoSixObj = [];
          
          let dynadimmerObj = [];
          let codedmainsObj = [];
          let ampDimObj = [];
          let lineswitchObj = [];
          let atleastOneObj:boolean=false;
          this.interdependencyObjSetIdentified =false;
          this.mem.properties.forEach((bankId) => {
            if (bankId.memoryBankId === "37-0") {
              this.isALoMinObj = true;

              if (enyDryFlag) {
                aloMinObj.push({
                  memoryBankId: bankId.memoryBankId,
                  ciphertext: bankId.ciphertext,
                });
              } else {
                aloMinObj.push({
                  memoryBankId: bankId.memoryBankId,
                  memoryBankVersion: bankId.memoryBankVersion,
                  payload: bankId.payload,
                });
              }
            }
            if (bankId.memoryBankId === "43-0") {
              this.isOwpObj = true;

              if (enyDryFlag) {
                owpFeatureObj.push({
                  memoryBankId: bankId.memoryBankId,
                  ciphertext: bankId.ciphertext,
                });
              } else {
                owpFeatureObj.push({
                  memoryBankId: bankId.memoryBankId,
                  memoryBankVersion: bankId.memoryBankVersion,
                  payload: bankId.payload,
                });
              }
            }
            if (bankId.memoryBankId === "149-0") {
              this.isAocMinObj = true;

              if (enyDryFlag) {
                aocMinObj.push({
                  memoryBankId: bankId.memoryBankId,
                  ciphertext: bankId.ciphertext,
                });
              } else {
                aocMinObj.push({
                  memoryBankId: bankId.memoryBankId,
                  memoryBankVersion: bankId.memoryBankVersion,
                  payload: bankId.payload,
                });
              }
            }
            if (bankId.memoryBankId === "52-0") {
              this.isLumMinSixObj = true;

              if (enyDryFlag) {
                lumInfoSixObj.push({
                  memoryBankId: bankId.memoryBankId,
                  ciphertext: bankId.ciphertext,
                });
              } else {
                lumInfoSixObj.push({
                  memoryBankId: bankId.memoryBankId,
                  memoryBankVersion: bankId.memoryBankVersion,
                  payload: bankId.payload,
                });
              }
            }
            if (bankId.memoryBankId === "15-0") {
              this.lineSwitchSupported = true;

              if (enyDryFlag) {
                lineswitchObj.push({
                  memoryBankId: bankId.memoryBankId,
                  ciphertext: bankId.ciphertext,
                });
              } else {
                lineswitchObj.push({
                  memoryBankId: bankId.memoryBankId,
                  memoryBankVersion: bankId.memoryBankVersion,
                  payload: bankId.payload,
                });
              }
            }
            if (bankId.memoryBankId === "13-0") {
              this.dynadimmerSupported = true;

              if (enyDryFlag) {
                dynadimmerObj.push({
                  memoryBankId: bankId.memoryBankId,
                  ciphertext: bankId.ciphertext,
                });
              } else {
                dynadimmerObj.push({
                  memoryBankId: bankId.memoryBankId,
                  memoryBankVersion: bankId.memoryBankVersion,
                  payload: bankId.payload,
                });
              }
            }
            if (bankId.memoryBankId === "18-0") {
              this.ampDimSupported = true;

              if (enyDryFlag) {
                ampDimObj.push({
                  memoryBankId: bankId.memoryBankId,
                  ciphertext: bankId.ciphertext,
                });
              } else {
                ampDimObj.push({
                  memoryBankId: bankId.memoryBankId,
                  memoryBankVersion: bankId.memoryBankVersion,
                  payload: bankId.payload,
                });
              }
            }
            if (bankId.memoryBankId === "36-0") {
              this.codedMainsSupported = true;

              if (enyDryFlag) {
                codedmainsObj.push({
                  memoryBankId: bankId.memoryBankId,
                  ciphertext: bankId.ciphertext,
                });
              } else {
                codedmainsObj.push({
                  memoryBankId: bankId.memoryBankId,
                  memoryBankVersion: bankId.memoryBankVersion,
                  payload: bankId.payload,
                });
              }
            }
          });
          this.mem.properties.forEach((bankId) => {
            this.bankIdByIndex = bankId.memoryBankId;
            atleastOneObj=false;

            if (
              bankId.memoryBankId === "19-0" ||
              bankId.memoryBankId === "35-0" ||
              bankId.memoryBankId === "1-0" ||
              bankId.memoryBankId === "114-0" ||
              bankId.memoryBankId === "73-0" ||
              bankId.memoryBankId === "14-0" ||
              bankId.memoryBankId === "149-0" ||
              bankId.memoryBankId === "29-0" ||
              bankId.memoryBankId === "27-0" ||
              bankId.memoryBankId === '15-0' ||
              bankId.memoryBankId === '13-0' ||
              bankId.memoryBankId === '18-0' ||
              bankId.memoryBankId === "75-0" ||
              bankId.memoryBankId === "74-0" ||
              bankId.memoryBankId === "113-0" ||
              bankId.memoryBankId === "30-0" ||
              bankId.memoryBankId === "156-0" ||
              bankId.memoryBankId === "162-0" ||
              bankId.memoryBankId === "158-0" ||
              bankId.memoryBankId === "16-0" ||
              bankId.memoryBankId === '36-0' ||
              bankId.memoryBankId === "207-0" ||
              bankId.memoryBankId === "44-0"              
              ) {
              if (bankId.memoryBankId === "73-0" && this.isALoMinObj) {
                newObj = [];
                if (enyDryFlag) {
                  newObj.push({
                    memoryBankId: bankId.memoryBankId,
                    ciphertext: bankId.ciphertext,
                  });
                } else {
                  newObj.push({
                    memoryBankId: bankId.memoryBankId,
                    memoryBankVersion: bankId.memoryBankVersion,
                    payload: bankId.payload,
                  });
                }
                newObj = newObj.concat(aloMinObj);
              }else if (bankId.memoryBankId === "44-0" && this.isOwpObj) {
                newObj = [];
                if (enyDryFlag) {
                  newObj.push({
                    memoryBankId: bankId.memoryBankId,
                    ciphertext: bankId.ciphertext,
                  });
                } else {
                  newObj.push({
                    memoryBankId: bankId.memoryBankId,
                    memoryBankVersion: bankId.memoryBankVersion,
                    payload: bankId.payload,
                  });
                }
                newObj = owpFeatureObj.concat(newObj);
                atleastOneObj=true;
              } 
              
              else if (bankId.memoryBankId === "114-0" && this.isAocMinObj) {
                newObj = [];
                if (enyDryFlag) {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      ciphertext: bankId.ciphertext,
                    },
                  ];
                } else {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      memoryBankVersion: bankId.memoryBankVersion,
                      payload: bankId.payload,
                    },
                  ];
                }
                atleastOneObj=true;
                newObj = newObj.concat(aocMinObj);
              } else if (bankId.memoryBankId === "1-0" && this.isLumMinSixObj) {
                newObj = [];
                if (enyDryFlag) {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      ciphertext: bankId.ciphertext,
                    },
                  ];
                } else {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      memoryBankVersion: bankId.memoryBankVersion,
                      payload: bankId.payload,
                    },
                  ];
                }
                atleastOneObj=true;
                newObj = newObj.concat(lumInfoSixObj);
              }
              else if (bankId.memoryBankId === "13-0" &&  !this.interdependencyObjSetIdentified) {
                newObj = [];


                newObj = newObj.concat(dynadimmerObj).concat(lineswitchObj).concat(ampDimObj).concat(codedmainsObj);
                this.interdependencyObjSetIdentified = true;
                atleastOneObj=true;
              }
              else if (bankId.memoryBankId === "15-0" && !this.interdependencyObjSetIdentified) {
                newObj = [];


                newObj = newObj.concat(dynadimmerObj).concat(lineswitchObj).concat(ampDimObj).concat(codedmainsObj);
                this.interdependencyObjSetIdentified = true;
                atleastOneObj=true;
              }
              else if (bankId.memoryBankId === "18-0" && !this.interdependencyObjSetIdentified) {
                newObj = [];


                newObj = newObj.concat(dynadimmerObj).concat(lineswitchObj).concat(ampDimObj).concat(codedmainsObj);
                this.interdependencyObjSetIdentified = true;
                atleastOneObj=true;
              }
              else if (bankId.memoryBankId === "36-0" && !this.interdependencyObjSetIdentified) {
                newObj = [];


                newObj = newObj.concat(dynadimmerObj).concat(lineswitchObj).concat(ampDimObj).concat(codedmainsObj);
                this.interdependencyObjSetIdentified = true;
                atleastOneObj=true;
              }
             
              else if (bankId.memoryBankId === "27-0") {
                //let payloadMod = this.matchPayload15(bankId.payload)
                newObj = [];
                if (enyDryFlag) {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      ciphertext: bankId.ciphertext,
                    },
                  ];
                } else {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      memoryBankVersion: bankId.memoryBankVersion,
                      payload: bankId.payload,
                    },
                  ];
                }
                atleastOneObj=true;
              }
              else if (
                bankId.memoryBankId === "19-0" ||
                bankId.memoryBankId === "35-0" ||
                bankId.memoryBankId === "1-0" ||
                bankId.memoryBankId === "114-0" ||
                bankId.memoryBankId === "14-0" ||
                bankId.memoryBankId === "73-0" ||
                bankId.memoryBankId === "29-0" ||
                bankId.memoryBankId === "75-0" ||
                bankId.memoryBankId === "74-0" ||
                bankId.memoryBankId === "113-0" ||
                bankId.memoryBankId === "30-0" ||
                bankId.memoryBankId === "16-0" ||
                bankId.memoryBankId === "207-0" ||
                bankId.memoryBankId === "158-0" ||
                bankId.memoryBankId === "156-0" ||
                bankId.memoryBankId === "162-0"               
                ) {
                newObj = [];
                if (enyDryFlag) {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      ciphertext: bankId.ciphertext,
                    },
                  ];
                } else {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      memoryBankVersion: bankId.memoryBankVersion,
                      payload: bankId.payload,
                    },
                  ];
                }
                atleastOneObj=true;
              }

              let newConfigurationIDUnq = uniqueConfigIDs[0];

              if (!(newConfigurationIDUnq === "NA")) {
                uniqueConfigIDs.shift();
              }
              this.unsubscribe.next();
              //let uniqueConfigID =  this.UniqueCofigIDImportPayload();

              ///////////
              /*     this.service.getEnvValue();
           this.service.getEnvData.pipe(takeUntil(this.unsubscribe)).subscribe(url => {
             if (url.length > 0) {
               this.unsubscribe.next();
               this.startConfigurationURL = url;
               this.configurationAPI(this.readerDeviceData.deviceMetadata).subscribe((data) => {
                 if (data) {
                  this.apiListResponse[i] = this.service.apiCall('post', this.envScehmaLIstApi.replace('{configurationId}', data['configurationID']), { "deviceName": this.mem.deviceName, "device12nc": this.mem.device12nc, "deviceSwVersion": this.mem.deviceSwVersion, "interface": this.mem.interface, "properties": newObj });
           
                  //this.apiListResponse[i] = this.service.apiCall('post', this.envScehmaLIstApi.replace('{configurationId}', this.startConfiguration['configurationID']), { "deviceName": this.mem.deviceName, "device12nc": this.mem.device12nc, "deviceSwVersion": this.mem.deviceSwVersion, "interface": this.mem.interface, "properties": newObj });
                   i++;
                 }
               }, err => {

               })
             }
       
       
           }); */

              /////Below chnage for passing new config id

              if (!(newConfigurationIDUnq === "NA")) {
                if (newConfigurationIDUnq === undefined) {
                  let newConfigId = "";
                  this.service.getEnvValue();
                  this.service.getEnvData
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((url) => {
                      if (url.length > 0) {
                        this.unsubscribe.next();
                        this.startConfigurationURL = url;
                        this.configurationAPI(
                          this.readerDeviceData.deviceMetadata
                        ).subscribe(
                          (data) => {
                            if (data) {
                              newConfigId = data["configurationID"];
                              if (this.mem.hasOwnProperty("iv")) {
                                if(atleastOneObj){
                                this.apiListResponse[i] = this.service.apiCall(
                                  "post",
                                  this.envScehmaLIstApi.replace(
                                    "{configurationId}",
                                    newConfigId
                                  ),
                                  {
                                    deviceName: this.mem.deviceName,
                                    device12nc: this.mem.device12nc,
                                    deviceSwVersion: this.mem.deviceSwVersion,
                                    interface: this.mem.interface,
                                    iv: this.mem.iv,
                                    properties: newObj,
                                  }
                                );}
                                else{
                                  i--;
                                }
                              } else {
                                if(atleastOneObj){
                                this.apiListResponse[i] = this.service.apiCall(
                                  "post",
                                  this.envScehmaLIstApi.replace(
                                    "{configurationId}",
                                    newConfigId
                                  ),
                                  {
                                    deviceName: this.mem.deviceName,
                                    device12nc: this.mem.device12nc,
                                    deviceSwVersion: this.mem.deviceSwVersion,
                                    interface: this.mem.interface,
                                    properties: newObj,
                                  }
                                );
                              }
                              else{
                                i--;
                              }
                              }
                            }
                          },
                          (err) => { }
                        );
                      }
                    });
                } else {
                  if (this.mem.hasOwnProperty("iv")) {
                    if(atleastOneObj){
                    this.apiListResponse[i] = this.service.apiCall(
                      "post",
                      this.envScehmaLIstApi.replace(
                        "{configurationId}",
                        newConfigurationIDUnq
                      ),
                      {
                        deviceName: this.mem.deviceName,
                        device12nc: this.mem.device12nc,
                        deviceSwVersion: this.mem.deviceSwVersion,
                        interface: this.mem.interface,
                        iv: this.mem.iv,
                        properties: newObj,
                      }
                    );}
                    else{
                      i--;
                    }
                  } else {
                    if(atleastOneObj){
                    this.apiListResponse[i] = this.service.apiCall(
                      "post",
                      this.envScehmaLIstApi.replace(
                        "{configurationId}",
                        newConfigurationIDUnq
                      ),
                      {
                        deviceName: this.mem.deviceName,
                        device12nc: this.mem.device12nc,
                        deviceSwVersion: this.mem.deviceSwVersion,
                        interface: this.mem.interface,
                        properties: newObj,
                      }
                    );}
                    else{
                      i--;
                    }
                  }
                }
                i++;
              }
              //////// Changes are eblow code
              else {
                if (this.mem.hasOwnProperty("iv")) {
                  if(atleastOneObj){
                  this.apiListResponse[i] = this.service.apiCall(
                    "post",
                    this.envScehmaLIstApi.replace(
                      "{configurationId}",
                      this.startConfiguration["configurationID"]
                    ),
                    {
                      deviceName: this.mem.deviceName,
                      device12nc: this.mem.device12nc,
                      deviceSwVersion: this.mem.deviceSwVersion,
                      interface: this.mem.interface,
                      iv: this.mem.iv,
                      properties: newObj,
                    }
                  );
                }
                else{
                 i--;
                }
                } else {
                  if(atleastOneObj){
                  this.apiListResponse[i] = this.service.apiCall(
                    "post",
                    this.envScehmaLIstApi.replace(
                      "{configurationId}",
                      this.startConfiguration["configurationID"]
                    ),
                    {
                      deviceName: this.mem.deviceName,
                      device12nc: this.mem.device12nc,
                      deviceSwVersion: this.mem.deviceSwVersion,
                      interface: this.mem.interface,
                      properties: newObj,
                    }
                  );
                }
                else{
                  i--;
                }
                }
                i++;
              }

              /////////////
              /*      setTimeout(() => {
            this.apiListResponse[i] = this.service.apiCall('post', this.envScehmaLIstApi.replace('{configurationId}', uniqueConfigID), { "deviceName": this.mem.deviceName, "device12nc": this.mem.device12nc, "deviceSwVersion": this.mem.deviceSwVersion, "interface": this.mem.interface, "properties": newObj });
           
            //this.apiListResponse[i] = this.service.apiCall('post', this.envScehmaLIstApi.replace('{configurationId}', this.startConfiguration['configurationID']), { "deviceName": this.mem.deviceName, "device12nc": this.mem.device12nc, "deviceSwVersion": this.mem.deviceSwVersion, "interface": this.mem.interface, "properties": newObj });
             i++;
           }, 1000); */

              /*  this.configurationAPI(this.mem).subscribe((data)=>{

            
           })
             */
            }
          });



          forkJoin(this.apiListResponse).subscribe(
            (data) => {
              if (data) {
                console.log(data)
                this.hideStatus = true;
                let greenFeat = [];
                let redFeat = [];
                let popupFailedFeat = [];
                data.forEach((element) => {
                  let UnauthorizedErrorFeature: boolean = false;
                  if (element["failedFeatures"].length == 1) {
                    if (
                      element["failedFeatures"][0].message === "Unauthorized."
                    ) {
                      UnauthorizedErrorFeature = true;
                    }
                  }

                  if (!UnauthorizedErrorFeature) {
                    if (
                      element["successfulFeatures"].length > 0 ||
                      (element["failedFeatures"].length > 0 &&
                        element["status"]
                          .split(" ")
                          .join("")
                          .toLowerCase()
                          .trim() === "completedwitherror")
                    ) {
                      if (
                        element["failedFeatures"].length > 0 &&
                        element["status"]
                          .split(" ")
                          .join("")
                          .toLowerCase()
                          .trim() === "completedwitherror"
                      ) {
                        let invalidData = {};

                        if (
                          !isNullOrUndefined(
                            element["failedFeatures"] &&
                            element["failedFeatures"][0]["featureParameters"]
                          ) &&
                          element["failedFeatures"][0]["featureParameters"]
                            .length > 0
                        ) {
                          invalidData["featureName"] =
                            element["failedFeatures"][0]["featureName"];
                          invalidData["featureParameters"] =
                            element["failedFeatures"][0][
                            "featureParameters"
                            ][0];
                          this.invalidFeatureData.push(invalidData);
                        } else {
                          popupFailedFeat.push([
                            element["failedFeatures"][0]["featureName"],
                          ]);
                        }

                        greenFeat.push([
                          element["failedFeatures"][0]["featureName"],
                        ]);
                      }
                      greenFeat.push(element["successfulFeatures"]);
                    } else if (element["failedFeatures"].length > 0) {
                      redFeat.push(element["failedFeatures"]);
                    }
                  }
                  // if(element['successfulFeatures'].length>0) {
                  //   greenFeat.push(element['successfulFeatures']);
                  // } else if(element['failedFeatures'].length>0) {
                  //   redFeat.push(element['failedFeatures']);
                  // }
                  UnauthorizedErrorFeature = false;
                });
                // this.service.redFailedFeatures(0);
                localStorage.setItem(
                  "resultFeatures",
                  JSON.stringify({
                    green: greenFeat,
                    red: redFeat,
                    orange: this.corrupt,
                    popupFailedFeat: popupFailedFeat,
                    deviceInfo: this.deviceInfo,
                  })
                );
                // this.service.sendNonCorrupt({green: greenFeat, red: redFeat});
                // this.service.sendCorruptFeat(this.corrupt);
              }
              this.customerLoaderAfter = false;
            },
            (err) => {
              this.feature12NcName = [];
              this.service.sendFeatures(this.feature12NcName);
              this.readerData = false;
              this.noReader = true;
              this.customerLoader = false;
              this.loading = false;
              this.customerLoaderAfter = false;
              let myObj = err;
              this.errorMessage = myObj.message;
              if (
                this.errorMessage ===
                "An error occurred while returning the memory bank properties! \r\n Please check the Device or Peripheral ID and try again!"
              ) {
                this.errorMessage =
                  "An error occurred while returning the properties!  Please check the Device or Peripheral ID and try again!";
                this.retryButton = true;
              }
              this.isShowCancelBtn = true;
            }
          );
        }
      });
  }
  // UniqueCofigIDImportPayload() {
  //   this.service.getEnvValue();
  //   this.service.getEnvData
  //     .pipe(takeUntil(this.unsubscribe))
  //     .subscribe((url) => {
  //       if (url.length > 0) {
  //         this.unsubscribe.next();
  //         this.startConfigurationURL = url;
  //         this.configurationAPI(this.readerDeviceData.deviceMetadata).subscribe(
  //           (data) => {
  //             if (data) {
  //               return data;
  //             }
  //           },
  //           (err) => {}
  //         );
  //       }
  //     });
  // }
  // workAroundPayAmpDim(payload: any) {
  //   let newUpdatedPayload: any[] = [];

  //   newUpdatedPayload.push(payload[payload.length - 1]);
  //   for (let index = 0; index < payload.length - 1; index++) {
  //     const element = payload[index];

  //     newUpdatedPayload.push(element);
  //   }
  //   return newUpdatedPayload;
  // }
  // mockPayload(payload: any) {
  //   let newUpdatedPayload: any[] = [];

  //   //newUpdatedPayload.push(payload[payload.length - 1]);
  //   for (let index = 0; index < payload.length - 5; index++) {
  //     const element = payload[index];

  //     newUpdatedPayload.push(element);
  //   }
  //   return newUpdatedPayload;
  // }
  // matchPayload15(payload: any) {
  //   let dynaReadPayload: any[] = payload;
  //   let newUpdatedPayload: any[] = [];

  //   if (localStorage.getItem("isWriting") === "true") {
  //     let dynaWritePayload: any[] = [];
  //     let timeRef = dynaReadPayload[3];
  //     let overrideMd = dynaReadPayload[4];
  //     let finalBitOffset7 = 0;
  //     dynaReadPayload.splice(3, 1);
  //     dynaReadPayload.splice(4, 1);
  //     dynaReadPayload.splice(5, 1);
  //     if (timeRef === 0 && overrideMd === 1) {
  //       finalBitOffset7 = 2;
  //     } else if (timeRef === 1 && overrideMd === 0) {
  //       finalBitOffset7 = 1;
  //     } else if (timeRef === 1 && overrideMd === 1) {
  //       finalBitOffset7 = 3;
  //     } else if (timeRef === 0 && overrideMd === 0) {
  //       finalBitOffset7 = 0;
  //     }
  //     for (let index = 0; index < dynaReadPayload.length; index++) {
  //       const element = dynaReadPayload[index];

  //       if (index === 3) {
  //         dynaWritePayload[index] = finalBitOffset7;
  //         index = 5;
  //       } else dynaWritePayload[index] = element;
  //     }

  //     return dynaWritePayload;
  //   }
  //   let i = 0;
  //   newUpdatedPayload = dynaReadPayload.slice(0, 1);
  //   newUpdatedPayload = newUpdatedPayload.slice(0, 1);
  //   return newUpdatedPayload;
  // }

  generatePluginOutput() {
    this.service.getEnvValue();
    this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.unsubscribe.next();
          let configuration = JSON.parse(
            localStorage.getItem("configurationData")
          );
          let writngUrl;
          writngUrl = url + this.writingURLViaPlugin;

          this.service
            .apiCall(
              "get",
              writngUrl.replace(
                "{configurationId}",
                configuration["configurationID"]
              )
            )
            .subscribe(
              (data) => {
                if (data) {
                  this.mem = data;
                  this.writingDeviceWithPC(data);
                }
              },
              (err) => {
                this.readerData = false;
                this.writingDevicesContainer = false;
                this.noReader = true;
                this.customerLoader = false;
                this.loading = false;
                this.customerLoaderAfter = false;
                let myObj = err;
                this.errorMessage = myObj.message;
                if (err.status) {
                  this.errorCodedDC.forEach((errorCode) => {
                    if (errorCode.includes(err.status)) {
                      if (err.status === errorCode.split("-")[0]) {
                        this.errorMessage = errorCode.split("-")[1];
                        this.showDCError(
                          err.message,
                          this.errorMessage,
                          this.showExtraPopUp
                        );
                        this.retryButton = true;
                      }
                    }
                  });
                } else {
                  const modalRef = this.modalService.open(ErrorComponent, {
                    keyboard: false,
                    centered: true,
                    windowClass: "error-style, error-style1",
                  });
                  modalRef.componentInstance.errorName = err["message"];
                  modalRef.componentInstance.errorDescription =
                    err["description"];
                }
                this.isShowCancelBtn = true;
              }
            );
        }
      });
  }
  // getModified13MBDataForDCS(data: ArrayBuffer) {}

  writingDeviceWithPC(data) {
    let readerName = JSON.parse(localStorage.getItem("readerInfo"));
    readerName = readerName[0];
    let readerDeviceData = JSON.parse(
      localStorage.getItem("setReaderDEviceInfo")
    );
    let startConfiguration = JSON.parse(
      localStorage.getItem("configurationData")
    );
    let i = 0;
    this.apiListResponse = [];
    this.service.getBaseUrl();
    this.service.getReadData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.writingURL = url + this.writingInDeviceURL;
          this.readerBaseUrl = url;
          this.unsubscribe.next();
          let writingObj = {};
          /*  data.properties = this.checkInterdependencyPayLoadOffset(
            data.properties );*/

          //data.properties = this.touchDimWorkAroundForWrite(data.properties);
          if (data.hasOwnProperty("iv")) {
            writingObj = {
              deviceName: data.deviceName,
              device12nc: data.device12nc,
              deviceSwVersion: data.deviceSwVersion,
              interface: data.interface,
              iv: data.iv,
              properties: data.properties,
              metadata: data.metadata,
            };
          } else {
            writingObj = {
              deviceName: data.deviceName,
              device12nc: data.device12nc,
              deviceSwVersion: data.deviceSwVersion,
              interface: data.interface,
              properties: data.properties,
            };
          }
          this.service
            .putApi(
              "put",
              this.writingURL
                .replace("{peripheralId}", readerName["peripheralId"])
                .replace("{deviceNfcId}", readerDeviceData.deviceNfcId),
              writingObj
              /* {
                deviceName: data.deviceName,
                device12nc: data.device12nc,
                deviceSwVersion: data.deviceSwVersion,
                interface: data.interface,
                properties: data.properties,
              } */
            )
            .subscribe(
              (data) => {
                localStorage.removeItem("saveSetData");
                this.removeWritingHeader = false;
                this.removeSuccessText = false;
                this.readerData = JSON.parse(
                  localStorage.getItem("readerInfo")
                );
                this.readerName = this.readerData[0];
                this.readerDeviceData = JSON.parse(
                  localStorage.getItem("setReaderDEviceInfo")
                );
                this.startConfiguration = JSON.parse(
                  localStorage.getItem("configurationData")
                );
                this.feature12NcName["allFeature"] =
                  startConfiguration["selectedFeaturesInfo"];
                this.service.sendFeatures(this.feature12NcName["allFeature"]);
                if (localStorage.getItem("compatibleFeatureID")) {
                  this.featureWithId = JSON.parse(
                    localStorage.getItem("compatibleFeatureID")
                  );
                  this.getImportPayLoad();
                } else {
                  this.getMemoreyBankList();
                }
              },
              (err) => {
                this.retryButton = true;
                this.readerData = false;
                this.writingDevicesContainer = false;
                this.noReader = true;
                this.customerLoader = false;
                this.loading = false;
                this.customerLoaderAfter = false;
                let myObj = err;
                this.errorMessage = myObj.Message;
                if (err.status) {
                  this.errorCodedDC.forEach((errorCode) => {
                    if (errorCode.includes(err.status)) {
                      if (err.status === errorCode.split("-")[0]) {
                        this.errorMessage = errorCode.split("-")[1];
                        this.showDCError(
                          err.message,
                          this.errorMessage,
                          this.showExtraPopUp
                        );
                        this.retryButton = true;
                      }
                    }
                  });
                }
                this.isShowCancelBtn = true;
              }
            );
        }
      });
  }
  // checkInterdependencyPayLoadOffset(properties: any): any {
  //   let datapropspayloadonly: any[] = [];
  //   for (let index = 0; index < properties.length; index++) {
  //     const element = properties[index];
  //     if (!(element["payload"] === undefined)) {
  //       if (element.memoryBankId === "18-0") {
  //         let newUpdatedPayload: any[] = [];
  //         for (let index = 1; index < element.payload.length; index++) {
  //           const element1 = element.payload[index];

  //           newUpdatedPayload.push(element1);
  //         }
  //         newUpdatedPayload.push(element.payload[0]);
  //         element.payload = newUpdatedPayload;
  //       }
  //       datapropspayloadonly.push(element);
  //     }
  //   }

  //   return datapropspayloadonly;
  // }
  // touchDimWorkAroundForWrite(properties: any): any {
  //   let datapropspayloadonly: any[] = [];
  //   for (let index = 0; index < properties.length; index++) {
  //     const element = properties[index];
  //     if (!(element["memoryBankId"] === undefined)) {
  //       if (element.memoryBankId === "27") {
  //         element.memoryBankId = "27-0";
  //       }
  //       datapropspayloadonly.push(element);
  //     }
  //   }

  //   return datapropspayloadonly;
  // }

  getDeviceReader() {
    return this.service.getServerStatus(this.readerBaseUrl);
    // return this.service.apiCall('get', '/assets/reader/status.json');
  }
  getDeviceReader1() {
    setTimeout(() => {
      return this.service.getServerStatus(this.readerBaseUrl);
    }, 1000);

    // return this.service.apiCall('get', '/assets/reader/status.json');
  }
  getReaderList() {
    return this.service.apiCall("get", this.readerBaseUrl + this.readerListUrl);
    // return this.service.apiCall('get', '/assets/reader/list.json');
  }

  getDeviceInfo() {
    //return this.service.apiCall('get', this.readerBaseUrl+this.deviceInfoUrl.replace('{peripheralId}',this.readerName['peripheralType']))
    if (!JSON.parse(localStorage.getItem("isWriting")) || this.isRetry) {
      this.deviceInfoUrl = this.deviceInfoUrl.concat("?refresh=true");
    }

    return this.service.apiCall(
      "get",
      this.readerBaseUrl +
      this.deviceInfoUrl.replace(
        "{peripheralId}",
        this.readerName["peripheralId"]
      )
    );
    // return this.service.apiCall('get', '/assets/reader/readerInfo.json')
  }

  getMemoryBank() {
    //return this.service.apiCall('get', this.readerBaseUrl+this.memoryBankUrl.replace('{peripheralId}',this.readerName['peripheralType']).replace('{deviceNfcId}', this.readerDeviceData['deviceNfcId']));
    return this.service.apiCall(
      "get",
      this.readerBaseUrl +
      this.memoryBankUrl
        .replace("{peripheralId}", this.readerName["peripheralId"])
        .replace("{deviceNfcId}", this.readerDeviceData["deviceNfcId"])
    );

    // return this.service.apiCall('get', '/assets/reader/memoryBank.json');
  }

  getReaderData(device12nc, deviceSwVersion) {
    return this.service.getSupportedSchemaList(
      this.envScehmaLIstApi,
      device12nc,
      deviceSwVersion
    );
  }

  checkCompatibleFeat() {
    if (window.location.hostname == "localhost") {
      return this.service.apiCall("get", "/assets/reader/memoryId.json");
    } else {
      return this.service.apiCall("get", "memoryId.json");
    }
  }

  checkCorruptBit() {
    //return this.service.apiCall('get', this.readerBaseUrl+this.corruptBitUrl.replace('{peripheralId}',this.readerName['peripheralType']).replace('{deviceNfcId}', this.readerDeviceData['deviceNfcId']));
    return this.service.apiCall(
      "get",
      this.readerBaseUrl +
      this.corruptBitUrl
        .replace("{peripheralId}", this.readerName["peripheralId"])
        .replace("{deviceNfcId}", this.readerDeviceData["deviceNfcId"])
    );
    // return this.service.apiCall('get','/assets/reader/corruptBit.json');
  }

  configurationAPI(deviceInfo) {
    let mockDevice = {
      device12nc: deviceInfo.device12nc,
      deviceName: deviceInfo.deviceName,
      softwareVersion: deviceInfo.deviceSwVersion,
    };
    return this.service.getStartConfiguration(
      mockDevice,
      this.startConfigurationURL
    );
  }

  featurePayLoad() {
    //return this.service.apiCall('get', this.readerBaseUrl+this.payLoadUrl.replace('{peripheralId}',this.readerName['peripheralType']).replace('{deviceNfcId}', this.readerDeviceData['deviceNfcId']).replace('{memorybankId}', this.bankIdByIndex));
    return this.service.apiCall(
      "get",
      this.readerBaseUrl +
      this.payLoadUrl
        .replace("{peripheralId}", this.readerName["peripheralId"])
        .replace("{deviceNfcId}", this.readerDeviceData["deviceNfcId"])
        .replace("{memorybankId}", this.bankIdByIndex)
    );

    // return this.service.apiCall('get','/assets/reader/payload.json');
  }

  importPayLoadAPI(payLoad) {
    return this.service.apiCall(
      "post",
      this.envScehmaLIstApi.replace(
        "{configurationId}",
        this.startConfiguration["configurationID"]
      ),
      {
        deviceName: this.specificFeaturePayLoad.deviceName,
        device12nc: this.specificFeaturePayLoad.device12nc,
        deviceSwVersion: this.specificFeaturePayLoad.deviceSwVersion,
        interface: this.specificFeaturePayLoad.interface,
        properties: [payLoad],
      }
    );
    // return this.service.apiCall('get','/assets/reader/responePayload.json');
  }

  getDeviceVersion() {
    var latestVersion = [];
    this.envAloschemaDetails = this.url;
    this.configurationData = this.readerDeviceData.deviceMetadata.device12nc;
    this.getDeviceSoftwareVersion().subscribe((deviceVersion) => {
      // for (let i = 0; i < deviceVersion['items'].length; i++) {
      //   for(let j = 0; j < deviceVersion['items'][i].softwareVersions.length; j++) {
      //     latestVersion.push(deviceVersion['items'][i].softwareVersions[j]);
      //     this.latestDeviceVersion.push(deviceVersion['items'][i].deviceType.nameAndVersion.split(':')[2]);
      //   }
      // }

      for (let i = deviceVersion["items"].length - 1; i >= 0; i--) {
        for (
          let j = deviceVersion["items"][i].softwareVersions.length - 1;
          j >= 0;
          j--
        ) {
          latestVersion.push(deviceVersion["items"][i].softwareVersions[j]);
          this.latestDeviceVersion.push(
            deviceVersion["items"][i].deviceType.nameAndVersion.split(":")[2]
          );
        }
      }
      this.latestDvValue = this.latestDeviceVersion[0];
    });

    /*     setTimeout(() => {

      this.latestDvValue = this.latestDeviceVersion[0];

    }, 1000);//3000);MOWEB 1.1 */
  }
  getDeviceSoftwareVersion() {
    return this.service.getDeviceSoftwareVersion(
      this.envAloschemaDetails,
      this.configurationData
    );
  }

  retry() {
    if (this.isWritingRetryCheck) {
      //  this.cancel();
      this.service.modalClose("");
      this.service.readerInfo("");
      this.unsubscribe.unsubscribe();
      this.service.sendFeatures("");
      this.service.sendCorruptFeat("");
      this.service.sendNonCorrupt("");
      this.service.redFailedFeatures("");

      this.retryClicked = true;

      this.service.modalConfigurationData("readDevice");

      const modalRef = this.modalService.open(ReadDeviceComponent, {
        windowClass: "select-feature-modal",
        backdropClass: "customBackdrop",
      });
      modalRef.componentInstance.isRetry = true;
    } else {
      this.cancel();
      this.service.modalClose("");
      this.service.readerInfo("");
      this.unsubscribe.unsubscribe();
      this.service.sendFeatures("");
      this.service.sendCorruptFeat("");
      this.service.sendNonCorrupt("");
      this.service.redFailedFeatures("");

      this.retryClicked = true;
      localStorage.setItem("normalFlow", JSON.stringify(false));
      localStorage.removeItem("CCTBoolen");
      localStorage.removeItem("saveReadValue");
      localStorage.removeItem("resultFeatures");
      localStorage.removeItem("supporteFeatures");
      localStorage.removeItem("configurationData");
      localStorage.removeItem("readerDeviceFullInfo");
      this.service.removeModes("invalid");
      localStorage.setItem("reading", JSON.stringify(true));
      localStorage.removeItem("saveSetData");
      let isLoggedin = JSON.parse(localStorage.getItem("loggedIn"));

      localStorage.setItem("isWriting", JSON.stringify(false));
      this.service.modalConfigurationData("readDevice");

      const modalRef = this.modalService.open(ReadDeviceComponent, {
        windowClass: "select-feature-modal",
        backdropClass: "customBackdrop",
      });
      modalRef.componentInstance.isRetry = true;
    }

    //}
  }

  retryConfigure() {
    this.newReaderInfo();
  }
  ngOnDestroy() {
    this.service.modalClose("");
    this.service.readerInfo("");
    //this.unsubscribe.unsubscribe();
    this.service.sendFeatures("");
    this.service.sendCorruptFeat("");
    this.service.sendNonCorrupt("");
    this.service.redFailedFeatures("");
    if (this.readerInfoSub) {
      this.readerInfoSub.unsubscribe();
    }
    if (this.statusObs$) {
      this.statusObs$.unsubscribe();
    }
    if (this.modalRefUnSubscribe) {
      this.modalRefUnSubscribe.close();
    }
  }
}
