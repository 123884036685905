import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  setTestabilityGetter,
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, FormArray, FormControl } from "@angular/forms";
import { ApiService } from "../../../core-module/services/api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { saveAs } from "file-saver";
import { Subject, forkJoin } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SelectingDeviceModalComponent } from "../selecting-device-modal/selecting-device-modal.component";
import { DatePipe } from "@angular/common";
import { FeatureFilePartialCompatibleModalComponent } from "src/app/feature-file-partial-compatible-modal/feature-file-partial-compatible-modal.component";
import { element } from "protractor";
import { ErrorComponent } from "src/app/error/error.component";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-configure-device-modal",
  templateUrl: "./configure-device-modal.component.html",
  styleUrls: ["./configure-device-modal.component.scss"],
})
export class ConfigureDeviceModalComponent implements OnInit {
  @Input() public deviceInfo;
  @Input() public featureInfo;

  luminaireValues = ["AOC", "CLO", "DTL", "MTP"];
  lightControlValues = ["ALO", "Dynadim"];
  luminaireInfoValues = ["Luminaire Info"];
  featureFile;
  isLuminaire = 0;
  isLightControl = 0;
  isLuminaireInfo = 0;
  countChecked = 0;
  isDisabledButton = true;
  disableCheckbox = true;
  schemaResp: any;
  loading = false;
  customLoader = true;
  featureArray: any[] = [];
  filteredFeatureArray: any[] = [];
  buttonDisable = false;
  feature12NcName: any = [];
  ztvFeatureSelected: any;
  fileLoad: boolean;
  newConfigId: any;
  filteredItems: any;
  trimSoftwareVersion: any;
  searchName: any;
  luminSoftwareVersionSchema: any;
  currentDate: any;
  newDeviceList: any = [];
  softwareVerTrim: any[];
  deviceSelected: boolean = false;
  isRelaod: boolean = false;
  isFeatureAvailable: boolean;
  readingStatus;
  directSubscription: any;
  regions: any = [];
  regionStr: string = "";
  apiList: any = [];
  dtlFeatureSelected: any;
  minDimLevelFeatureSelected: any;
  deviceDataFromMetaData: any;
  corridorFeatureSelected: any;
  lineswitchFeatureSelected: any;
  minDimAlwaysChecked: any;
  dtlSoftwareVersionSchema: any;
  mindimlevelSoftwareVersionSchema: any;
  dalipsuSoftwareVersionSchema: any;
  ampdimSoftwareVersionSchema: any;
  lineswitchSoftwareVersionSchema: any;
  owpSoftwareVersionSchema: any;
  codedmainsSoftwareVersionSchema: any;
  mtpSoftwareVersionSchema: any;
  dimminginterfaceSoftwareVersionSchema: any;
  latestDeviceVersion: any = [];
  latestDvValue: any;
  softwareVer: any = [];
  selectedFeature: any;
  featuresArraySelected: any;
  ztvSoftwareVersionSchema: any;
  ztvoutputSoftwareVersionSchema: any;
  lipSoftwareVersionSchema: any;
  rsoSoftwareVersionSchema: any;
  aocSoftwareVersionSchema: any;
  touchdimSoftwareVersionSchema: any;
  diia253maintenanceSoftwareVersionSchema: any;
  corridorSoftwareVersionSchema: any;
  astSoftwareVersionSchema: any;
  cloSoftwareVersionSchema: any;
  cloliteSoftwareVersionSchema: any;
  dynadimmerSoftwareVersionSchema: any;
  dcemergencySoftwareVersionSchema: any;
  aloFeatureSelected;
  aocFeatureSelected;
  cloFeatureSelected;
  psucloFeatureSelected;
  luminoFeatureSelected;
  softwareVersionSchema;
  alwaysChecked = false;
  cloAlwaysChecked;
  cloLiteAlwaysChecked;
  aloAlwaysChecked;
  aocAlwaysChecked;
  psuAlwaysChecked;
  LumInfoAlwaysChecked;
  zTvAlwaysChecked;
  subscription;

  startConfigForm: FormGroup;
  aloschemaConfig = {};

  featureFileUrl = "config/api/v1/configuration/{configurationId}/feature-file";
  envFeatureFileUrl;
  envStartConfigURL;
  envScehmaLIstApi;
  aloschemaDetails =
    "config/api/v1/devices/schemas/details/nc12/{nc12}/v/{version}";
  envAloschemaDetails: string;
  importFeatureFile = "config/api/v1/configuration/";

  startConfigurationData = {};
  isFeatureSelected = false;
  isFeatureChecked = true;
  isDeviceVersionMismatch = false;
  alreadySelectedFeature;
  configurationData;
  files: any[] = [];
  fileName;
  showFileName = false;
  flag: any;
  newDevice;
  existing = false;
  deviceSearch;
  is12nc;
  envDeviceListURL;
  deviceList: any;
  url;
  fullDeviceList = "search/api/v1/search/v1/devices";
  keyword = "device12nc";
  nameKeyword = "deviceName";
  searchTypes = [
    { id: 1, name: "12nc" },
    { id: 2, name: "productname" },
  ];
  isName;
  enableButton;
  metaDataAPILink = "feature/api/v1/getMetaData";
  textContent;
  deviceData;
  importLinkFeature;
  configID;
  isError = false;

  unsubscribe = new Subject<any>();

  startupFeatureSelected;
  startupAlwaysChecked;
  DTLAlwaysChecked;
  corridorAlwaysChecked;
  dcemergencyAlwaysChecked;
  dcemergencyFeatureSelected;
  touchDimAlwaysChecked;
  touchDimFeatureSelected;
  loadingUpload;
  featuresData: any = [];
  pathVal: string;
  constructor(
    private datePipe: DatePipe,
    public activemodal: NgbActiveModal,
    private fb: FormBuilder,
    private http: HttpClient,
    private service: ApiService,
    public route: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.pathVal = this.service.getImageUrl(window.location.hostname);
    this.service.getTriggerConfigureDevice.subscribe((val) => {
      if (val) {
        this.configureDevice();
      }
    });
    this.flag = 1;
    var currentDate = new Date();
    this.currentDate = this.datePipe.transform(currentDate, "dd-MM-yyyy");
    this.service.getEnvValue();
    this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        this.url = url;
      });

    this.service.getdirectFeature.subscribe((data) => {
      if (data === "success") {
        this.configureDevice();
      }
    });

    this.service.makeDisable.subscribe((data) => {
      if (data === "confirm") {
        this.deviceSelected = true;
      }
    });
    this.service.sendCloseConfigPopup("");
    setTimeout(() => {
      this.service.checkCloseConfigPopup
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((resp) => {
          if (resp === "close") {
            this.activemodal.close();
          }
        });
    }, 100);

    this.startConfigForm = this.fb.group({
      selectedFeatures: this.fb.array([]),
    });
    this.subscription = this.service.getconfigData.subscribe((data) => {
      this.featureFile = data;
      if (this.featureFile === "configData") {
        this.configurationData = JSON.parse(localStorage.getItem("device12nc"));
        if (
          this.deviceInfo &&
          this.deviceInfo.selectedFeaturesInfo !== undefined
        ) {
          this.alreadySelectedFeature =
            this.deviceInfo.selectedFeaturesInfo.forEach((element) => {
              this.onChange(element);
            });
        }
        let checkedDevicePopup: any = JSON.parse(
          localStorage.getItem("storeOmitOption")
        );
        let compareLatest = JSON.parse(
          localStorage.getItem("comparefirmwarenoLatest")
        );
        if (localStorage.getItem("firmwarenoLatest")) {
          this.softwareVer = JSON.parse(
            localStorage.getItem("firmwarenoLatest")
          );
        }
        if (
          compareLatest == null &&
          checkedDevicePopup &&
          checkedDevicePopup["storeDevicePopupValue"]
        ) {
          // && checkedDevicePopup['storeDevicePopupCheck'] == true){
        }
        if (localStorage.getItem("comparefirmwarenoLatest")) {
          this.softwareVerTrim = JSON.parse(
            localStorage.getItem("comparefirmwarenoLatest")
          );
          if (this.softwareVerTrim.length > 3) {
            this.softwareVerTrim = this.softwareVerTrim.slice(0, -2);
          }
        } else {
          this.getSoftwareVersion();
        }
        setTimeout(() => {
          if (this.softwareVer.length > 3) {
            if (Array.isArray(this.softwareVer)) {
              this.softwareVerTrim = this.softwareVer[0].slice(0, -2);
            } else {
              this.softwareVerTrim = this.softwareVer.slice(0, -2);
            }
          }

          this.schemalist();
        }, 2500);
        this.latestDvValue = this.latestDeviceVersion[0];
      }

      if (this.featureFile === "featureData") {
        this.featuresArraySelected = JSON.parse(
          localStorage.getItem("configurationData")
        );
        this.deviceInfo = this.featuresArraySelected;
        for (
          let i = 0;
          i < this.featuresArraySelected.selectedFeaturesInfo.length;
          i++
        ) {
          if (this.featuresArraySelected.selectedFeaturesInfo[i] === "Alo") {
            this.aloFeatureSelected =
              this.featuresArraySelected.selectedFeaturesInfo[i];
          }
        }
        for (
          let j = 0;
          j < this.featuresArraySelected.selectedFeaturesInfo.length;
          j++
        ) {
          if (this.featuresArraySelected.selectedFeaturesInfo[j] === "Aoc") {
            this.aocFeatureSelected =
              this.featuresArraySelected.selectedFeaturesInfo[j];
          }
        }
        for (
          let k = 0;
          k < this.featuresArraySelected.selectedFeaturesInfo.length;
          k++
        ) {
          if (
            this.featuresArraySelected.selectedFeaturesInfo[k] === "Clo" ||
            this.featuresArraySelected.selectedFeaturesInfo[k] === "CloLite" ||
            this.featuresArraySelected.selectedFeaturesInfo[k] === "Clolite"
          ) {
            this.cloFeatureSelected =
              this.featuresArraySelected.selectedFeaturesInfo[k];
          }
        }
        //For DTL
        for (
          let k = 0;
          k < this.featuresArraySelected.selectedFeaturesInfo.length;
          k++
        ) {
          if (
            this.featuresArraySelected.selectedFeaturesInfo[k] === "DTL" ||
            this.featuresArraySelected.selectedFeaturesInfo[k] === "dtl" ||
            this.featuresArraySelected.selectedFeaturesInfo[k] === "Dtl"
          ) {
            this.dtlFeatureSelected =
              this.featuresArraySelected.selectedFeaturesInfo[k];
          }
        }
        //FOr Min DIm Level
        for (
          let k = 0;
          k < this.featuresArraySelected.selectedFeaturesInfo.length;
          k++
        ) {
          if (
            this.featuresArraySelected.selectedFeaturesInfo[k] ===
              "MIN DIM LEVEL" ||
            this.featuresArraySelected.selectedFeaturesInfo[k] ===
              "Min Dim Level" ||
            this.featuresArraySelected.selectedFeaturesInfo[k] ===
              "Min dim Level"
          ) {
            this.minDimLevelFeatureSelected =
              this.featuresArraySelected.selectedFeaturesInfo[k];
          }
        }
        //Min Dim Level End
        for (
          let k = 0;
          k < this.featuresArraySelected.selectedFeaturesInfo.length;
          k++
        ) {
          if (this.featuresArraySelected.selectedFeaturesInfo[k] === "Psu") {
            this.psucloFeatureSelected =
              this.featuresArraySelected.selectedFeaturesInfo[k];
          }
        }
        for (
          let k = 0;
          k < this.featuresArraySelected.selectedFeaturesInfo.length;
          k++
        ) {
          if (
            this.featuresArraySelected.selectedFeaturesInfo[k] === "LumInfo"
          ) {
            this.luminoFeatureSelected =
              this.featuresArraySelected.selectedFeaturesInfo[k];
          }
        }
        for (
          let k = 0;
          k < this.featuresArraySelected.selectedFeaturesInfo.length;
          k++
        ) {
          if (this.featuresArraySelected.selectedFeaturesInfo[k] === "clo") {
            this.luminoFeatureSelected =
              this.featuresArraySelected.selectedFeaturesInfo[k];
          }
        }
        for (
          let k = 0;
          k < this.featuresArraySelected.selectedFeaturesInfo.length;
          k++
        ) {
          if (
            this.featuresArraySelected.selectedFeaturesInfo[k] ===
            "0-10v / 1-10v"
          ) {
            this.ztvFeatureSelected =
              this.featuresArraySelected.selectedFeaturesInfo[k];
          }
        }
        for (
          let k = 0;
          k < this.featuresArraySelected.selectedFeaturesInfo.length;
          k++
        ) {
          if (
            this.featuresArraySelected.selectedFeaturesInfo[k] ===
            "Startup time"
          ) {
            this.startupFeatureSelected =
              this.featuresArraySelected.selectedFeaturesInfo[k];
          }
        }
        for (
          let k = 0;
          k < this.featuresArraySelected.selectedFeaturesInfo.length;
          k++
        ) {
          if (
            this.featuresArraySelected.selectedFeaturesInfo[k] === "Corridor"
          ) {
            this.corridorFeatureSelected =
              this.featuresArraySelected.selectedFeaturesInfo[k];
          }
        }
        for (
          let k = 0;
          k < this.featuresArraySelected.selectedFeaturesInfo.length;
          k++
        ) {
          if (
            this.featuresArraySelected.selectedFeaturesInfo[k] === "Line Switch"
          ) {
            this.lineswitchFeatureSelected =
              this.featuresArraySelected.selectedFeaturesInfo[k];
          }
        }
        for (
          let k = 0;
          k < this.featuresArraySelected.selectedFeaturesInfo.length;
          k++
        ) {
          if (
            this.featuresArraySelected.selectedFeaturesInfo[k] ===
            "DC Emergency"
          ) {
            this.dcemergencyFeatureSelected =
              this.featuresArraySelected.selectedFeaturesInfo[k];
          }
        }

        for (
          let k = 0;
          k < this.featuresArraySelected.selectedFeaturesInfo.length;
          k++
        ) {
          if (
            this.featuresArraySelected.selectedFeaturesInfo[k] ===
              "Touch and Dim" ||
            this.featuresArraySelected.selectedFeaturesInfo[k] === "touchdim" ||
            this.featuresArraySelected.selectedFeaturesInfo[k] === "touchdim-"
          ) {
            this.touchDimFeatureSelected =
              this.featuresArraySelected.selectedFeaturesInfo[k];
          }
        }
      }

      if (this.featureFile === "readDevice") {
        this.loading = true;
        this.getDeviceReader().subscribe((resp) => {
          if (resp) {
            this.readingStatus = resp;
            this.loading = false;
          }
        });
      }
    });

    this.isFeatureSelected = this.startConfigForm.value.selectedFeatures.length;
    this.deviceSearch = "first";
    if (
      this.featureFile === "uploadFeatureFile" ||
      this.featureFile === "searchNewDevice"
    ) {
      this.getDevice();
    }
    if (this.featureFile === "searchNewDevice") {
      this.deviceSelected = true;
    }
    // For select Feature Story
    // setTimeout(() => {
    //   if(Array.isArray(this.softwareVer)) {
    //     this.trimSoftwareVersion = this.softwareVer[0];
    //     this.trimSoftwareVersion = this.trimSoftwareVersion.slice(0, -2);
    //   } else {
    //     this.trimSoftwareVersion = this.softwareVer.slice(0, -2);
    //   }
    // }, 500);

    this.service.getConfigMethod.subscribe((data) => {
      if (data === "success") {
        this.confirm();
      }
    });
  }

  getSoftwareVersion() {
    this.loading = true;
    if (this.featureFile === "uploadFeatureFile") {
      this.configurationData = this.deviceInfo.device12nc;
    }
    this.service.getEnvValue();
    this.subscription = this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (this.url.length) {
          this.unsubscribe.next();
          let latestVersion = [];
          this.envAloschemaDetails = this.url;
          this.getDeviceSoftwareVersion().subscribe((deviceVersion) => {
            // for (let i = 0; i < deviceVersion['items'].length; i++) {
            //   for(let j = 0; j < deviceVersion['items'][i].softwareVersions.length; j++) {
            //     latestVersion.push(deviceVersion['items'][i].softwareVersions[j]);
            //     this.latestDeviceVersion.push(deviceVersion['items'][i].deviceType.nameAndVersion.split(':')[2]);
            //   }
            // }
            deviceVersion["items"].forEach(function (arrayItem) {
              for (var key in arrayItem) {
                if (!arrayItem.hasOwnProperty("softwareVersions")) {
                  delete arrayItem[key];
                }
              }
            });
            deviceVersion["items"] = deviceVersion["items"].filter((ele) => {
              return ele.constructor === Object && Object.keys(ele).length > 0;
            });
            for (let i = deviceVersion["items"].length - 1; i >= 0; i--) {
              for (
                let j = deviceVersion["items"][i].softwareVersions.length - 1;
                j >= 0;
                j--
              ) {
                this.softwareVer.push(
                  deviceVersion["items"][i].softwareVersions[j]
                );
                latestVersion.push(
                  deviceVersion["items"][i].softwareVersions[j]
                );
                this.latestDeviceVersion.push(
                  deviceVersion["items"][i].deviceType.nameAndVersion.split(
                    ":"
                  )[2]
                );
              }
            }
            let ascSoftwareVersions = latestVersion
              .map((a) =>
                a
                  .split(".")
                  .map((n) => +n + 100000)
                  .join(".")
              )
              .sort()
              .map((a) =>
                a
                  .split(".")
                  .map((n) => +n - 100000)
                  .join(".")
              );
            ascSoftwareVersions.reverse();
            this.softwareVerTrim = [];
            this.softwareVer = ascSoftwareVersions;

            var url = window.location.href;
            this.latestDvValue = this.latestDeviceVersion[0];
            this.softwareVer.forEach((element) => {
              let ver = element.slice(0, -2);
              this.softwareVerTrim.push(ver);
            });
            if (this.softwareVerTrim.length > 1 && url.includes("deviceList")) {
              this.softwareVerTrim = this.softwareVerTrim[0];
              this.latestDvValue = this.latestDeviceVersion[0];
            }
            localStorage.setItem(
              "comparefirmwarenoLatest",
              JSON.stringify(this.softwareVer[0])
            );
            this.loading = false;
          });
        }
      });
  }

  getDeviceReader() {
    return this.service.apiCall(
      "get",
      "http://localhost:2020/server/v1/status"
    );
  }
  getDevice() {
    this.service.getEnvValue();
    this.subscription = this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        this.url = url;
        this.loading = true;
        if (url.length) {
          this.envDeviceListURL =
            url + this.fullDeviceList + "?showArchive=true";
          this.regions = ["EMEA", "NA", "CN", "ROA"];
          const htHeader = new HttpHeaders()
            .set("region", this.regions[0])
            .append("region", this.regions[1])
            .append("region", this.regions[2])
            .append("region", this.regions[3]);
          this.apiList = this.service.apiCall(
            "get",
            this.envDeviceListURL,
            "",
            { headers: htHeader }
          );
          this.unsubscribe.next();
          this.newDeviceList = [];
          forkJoin(this.apiList).subscribe((resp) => {
            if (resp) {
              this.deviceList = [].concat.apply([], resp);
              this.deviceList.forEach((element) => {
                this.newDeviceList.push({
                  name: element.device12nc + " " + element.deviceName,
                });
              });
              this.loading = false;
            }
          });
        }
      });
  }

  searchWithAllDevice12Nc(searchText) {
    let headers: any = new HttpHeaders({});
    let j = 0;
    this.regions = ["EMEA", "NA", "CN", "ROA"];
    const htHeader = new HttpHeaders()
      .set("region", this.regions[0])
      .append("region", this.regions[1])
      .append("region", this.regions[2])
      .append("region", this.regions[3]);
    this.searchTextValue(searchText, htHeader);
    this.unsubscribe.next();
    this.searchName = [];
    forkJoin(this.apiList).subscribe((resp) => {
      this.searchName = [].concat.apply([], resp);
      localStorage.setItem(
        "readerDeviceFullInfo",
        JSON.stringify(this.searchName)
      );
    });
  }

  searchTextValue(searchText, headers) {
    if (this.url.length) {
      this.loading = true;
      this.envDeviceListURL =
        this.url + this.fullDeviceList + "?query=nc12%3A*";
      this.unsubscribe.next();
      this.apiList = this.service.apiCall(
        "get",
        this.envDeviceListURL + searchText + "*" + "&showArchive=true",
        "",
        { headers: headers }
      );
    }
  }

  getDeviceSearchList() {
    return this.service.apiCall("get", this.envDeviceListURL);
  }

  onSubmit() {
    // if(localStorage.getItem('comparefirmwarenoLatest')) {
    //   this.softwareVer = JSON.parse(localStorage.getItem('comparefirmwarenoLatest') || '{}');
    // }
    this.loading = true;
    this.isFeatureSelected = false;
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // let token=currentUser['token']
    var tmp = [];
    if (this.featureFile === "uploadFeatureFile") {
    }
    for (
      let i = 0;
      i < this.startConfigForm.value.selectedFeatures.length;
      i++
    ) {
      if (tmp.indexOf(this.startConfigForm.value.selectedFeatures[i]) === -1) {
        tmp.push(this.startConfigForm.value.selectedFeatures[i]);
      }
    }

    var isSelectFile = JSON.parse(localStorage.getItem("newDeviceBoolen"));
    if (this.url.length && !isSelectFile) {
      if (localStorage.getItem("comparefirmwarenoLatest")) {
        this.softwareVer = JSON.parse(
          localStorage.getItem("comparefirmwarenoLatest") || "{}"
        );
      }
      if (this.deviceInfo) {
        var domain = "https://www.api.easydesignintool.signify.com";
        this.deviceInfo.devicetypePicture = this.deviceInfo.devicetypePicture
          ? this.deviceInfo.devicetypePicture
          : this.deviceInfo.picture;
        if (this.deviceInfo.devicetypePicture != undefined) {
          this.deviceInfo.devicetypePicture =
            domain + this.deviceInfo.devicetypePicture.replace(domain, "");
        }
      }
      this.unsubscribe.next();
      this.envAloschemaDetails = this.url;
      var passingArr: any = [];

      tmp.forEach((element) => {
        const index = tmp.indexOf("Startup time");
        if (index > -1) {
          tmp.splice(index, 1);
          tmp.push("Ast");
        }
        const index1 = tmp.indexOf("0-10v / 1-10v");
        if (index1 > -1) {
          tmp.splice(index1, 1);
          tmp.push("Ztv");
        }
        const index2 = tmp.indexOf("LumInfo");
        if (index2 > -1) {
          tmp.splice(index2, 1);
          tmp.push("LumInfoFormat");
        }
        const index3 = tmp.indexOf("Psu");
        if (index3 > -1) {
          tmp.splice(index3, 1);
          tmp.push("SrPsu");
        }
      });
      passingArr = tmp.length > 1 ? "" : "&featureName=" + tmp;
      var configurationID;
      configurationID = localStorage.getItem("configId");

      this.service
        .getConfigurationAPI(this.envAloschemaDetails, configurationID)
        .subscribe(
          (resp) => {
            this.proceedToConfigurationData(tmp, resp, configurationID);
          },
          (err) => {
            this.loading = false;
            let myObj = err;
            if (myObj.description === "Feature Configuration is empty.") {
              let resp = {};
              this.proceedToConfigurationData(tmp, resp, configurationID);
            } else {
              alert(myObj.message);
              this.activemodal.close();
            }
          }
        );
    } else {
      this.service.getEnvValue();
      this.service.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (url.length) {
            if (localStorage.getItem("comparefirmwarenoLatest")) {
              this.softwareVer = JSON.parse(
                localStorage.getItem("comparefirmwarenoLatest") || "{}"
              );
            }
            this.unsubscribe.next();
            this.envStartConfigURL = url;
            if (this.deviceInfo) {
              var domain = "https://www.api.easydesignintool.signify.com";
              this.deviceInfo.devicetypePicture = this.deviceInfo
                .devicetypePicture
                ? this.deviceInfo.devicetypePicture
                : this.deviceInfo.picture;
              this.deviceInfo.devicetypePicture =
                domain + this.deviceInfo.devicetypePicture.replace(domain, "");
            }
            if (localStorage.getItem("configurationData")) {
              var dataFromConfig = JSON.parse(
                localStorage.getItem("configurationData")
              );
              this.deviceInfo = {
                deviceName: dataFromConfig.deviceName,
                device12nc: dataFromConfig.device12nc,
                softwareVerSchema: dataFromConfig.softwareVerSchema,
                picture: dataFromConfig.picture,
                luminSoftVer: dataFromConfig.luminSoftVer,
                dtlSoftVer: dataFromConfig.dtlSoftVer,
                mindimlevelSoftVer: dataFromConfig.mindimlevelSoftVer,
                mtpSoftVer: dataFromConfig.mtpSoftVer,
                dimminginterfaceSoftVer: dataFromConfig.dimminginterfaceSoftVer,
                dynadimmerSoftVer: dataFromConfig.dynadimmerSoftVer,
                dcemergencySoftVer: dataFromConfig.dcemergencySoftVer,
                ztvSoftVer: dataFromConfig.ztvSoftVer,
                ztvoutputSoftVer: dataFromConfig.ztvoutputSoftVer,
                lipSoftVer: dataFromConfig.lipSoftVer,
                rsoSoftVer: dataFromConfig.rsoSoftVer,
                aocSoftVer: dataFromConfig.aocSoftVer,
                astSoftVer: dataFromConfig.astSoftVer,
                cloliteSoftVer: dataFromConfig.cloliteSoftVer,
                touchdimSoftVer: dataFromConfig.touchdimSoftVer,
                corridorSoftVer: dataFromConfig.corridorSoftVer,
                cloSoftVer: dataFromConfig.cloSoftVer,
                ampdimSoftVer: dataFromConfig.ampdimSoftVer,
                lineswitchSoftVer: dataFromConfig.lineswitchSoftVer,
                owpSoftVer: dataFromConfig.owpSoftVer,
                codedmainsSoftVer: dataFromConfig.codedmainsSoftVer,
                selectedFeaturesInfo: dataFromConfig.selectedFeaturesInfo,
                feature12Nc: dataFromConfig.feature12Nc,
                resp: dataFromConfig.resp,
              };
              this.softwareVer = dataFromConfig.softwareVersion;
            }
            if (Array.isArray(this.softwareVer)) {
              this.softwareVer = this.softwareVer[0];
            }
            if (this.softwareVer.length > 3) {
              this.softwareVer = this.softwareVer.slice(0, -2);
            }
            let mockDevice = {
              device12nc: this.deviceInfo.device12nc,
              deviceName: this.deviceInfo.deviceName,
              softwareVersion: this.softwareVer,
            };
            this.service
              .getStartConfiguration(mockDevice, this.envStartConfigURL)
              .subscribe(
                (data) => {
                  localStorage.removeItem("deviceVersion");
                  var configurationID;
                  if (localStorage.getItem("configId")) {
                    configurationID = localStorage.getItem("configId");
                  } else {
                    configurationID = data["configurationID"];
                  }
                  if (this.softwareVer.length > 3) {
                    this.softwareVer = this.softwareVer.slice(0, -2);
                  }
                  this.startConfigurationData["configurationID"] =
                    configurationID;
                  this.newConfigId = configurationID;
                  this.startConfigurationData["deviceName"] =
                    this.deviceInfo["deviceName"];
                  this.startConfigurationData["softwareVersion"] =
                    this.softwareVer;
                  this.startConfigurationData["selectedFeaturesInfo"] =
                    tmp.length > 0 ? tmp : this.deviceInfo.selectedFeaturesInfo;
                  this.startConfigurationData["softwareVerSchema"] = this
                    .softwareVersionSchema
                    ? this.softwareVersionSchema
                    : this.deviceInfo.softwareVerSchema;
                  this.startConfigurationData["luminSoftVer"] = this
                    .luminSoftwareVersionSchema
                    ? this.luminSoftwareVersionSchema
                    : this.deviceInfo.luminSoftVer;
                  this.startConfigurationData["dtlSoftVer"] = this
                    .dtlSoftwareVersionSchema
                    ? this.dtlSoftwareVersionSchema
                    : this.deviceInfo.dtlSoftVer;
                  this.startConfigurationData["mindimlevelSoftVer"] = this
                    .mindimlevelSoftwareVersionSchema
                    ? this.mindimlevelSoftwareVersionSchema
                    : this.deviceInfo.mindimlevelSoftVer;
                  this.startConfigurationData["ampdimSoftVer"] = this
                    .ampdimSoftwareVersionSchema
                    ? this.ampdimSoftwareVersionSchema
                    : this.deviceInfo.ampdimSoftVer;
                  this.startConfigurationData["lineswitchSoftVer"] = this
                    .lineswitchSoftwareVersionSchema
                    ? this.lineswitchSoftwareVersionSchema
                    : this.deviceInfo.lineswitchSoftVer;
                  this.startConfigurationData["owpSoftVer"] = this
                    .owpSoftwareVersionSchema
                    ? this.owpSoftwareVersionSchema
                    : this.deviceInfo.owpSoftVer;
                  this.startConfigurationData["codedmainsSoftVer"] = this
                    .codedmainsSoftwareVersionSchema
                    ? this.codedmainsSoftwareVersionSchema
                    : this.deviceInfo.codedmainsSoftVer;
                  this.startConfigurationData["dalipsuSoftVer"] = this
                    .dalipsuSoftwareVersionSchema
                    ? this.dalipsuSoftwareVersionSchema
                    : this.deviceInfo.dalipsuSoftVer;
                  this.startConfigurationData["mtpSoftVer"] = this
                    .mtpSoftwareVersionSchema
                    ? this.mtpSoftwareVersionSchema
                    : this.deviceInfo.mtpSoftVer;
                  this.startConfigurationData["dimminginterfaceSoftVer"] = this
                    .dimminginterfaceSoftwareVersionSchema
                    ? this.dimminginterfaceSoftwareVersionSchema
                    : this.deviceInfo.dimminginterfaceSoftVer;
                  this.startConfigurationData["dynadimmerSoftVer"] = this
                    .dynadimmerSoftwareVersionSchema
                    ? this.dynadimmerSoftwareVersionSchema
                    : this.deviceInfo.dynadimmerSoftVer;
                  this.startConfigurationData["dcemergencySoftVer"] = this
                    .dcemergencySoftwareVersionSchema
                    ? this.dcemergencySoftwareVersionSchema
                    : this.deviceInfo.dcemergencySoftVer;
                  this.startConfigurationData["cloSoftVer"] = this
                    .cloSoftwareVersionSchema
                    ? this.cloSoftwareVersionSchema
                    : this.deviceInfo.cloSoftVer;
                  this.startConfigurationData["astSoftVer"] = this
                    .astSoftwareVersionSchema
                    ? this.astSoftwareVersionSchema
                    : this.deviceInfo.astSoftVer;
                  this.startConfigurationData["cloliteSoftVer"] = this
                    .cloliteSoftwareVersionSchema
                    ? this.cloliteSoftwareVersionSchema
                    : this.deviceInfo.cloliteSoftVer;
                  this.startConfigurationData["aocSoftVer"] = this
                    .aocSoftwareVersionSchema
                    ? this.aocSoftwareVersionSchema
                    : this.deviceInfo.aocSoftVer;
                  this.startConfigurationData["touchdimSoftVer"] = this
                    .touchdimSoftwareVersionSchema
                    ? this.touchdimSoftwareVersionSchema
                    : this.deviceInfo.touchdimSoftVer;
                  this.startConfigurationData["diia253maintenanceSoftVer"] =
                    this.diia253maintenanceSoftwareVersionSchema
                      ? this.diia253maintenanceSoftwareVersionSchema
                      : this.deviceInfo.diia253maintenanceSoftVer;
                  this.startConfigurationData["corridorSoftVer"] = this
                    .corridorSoftwareVersionSchema
                    ? this.corridorSoftwareVersionSchema
                    : this.deviceInfo.corridorSoftVer;
                  this.startConfigurationData["ztvSoftVer"] = this
                    .ztvSoftwareVersionSchema
                    ? this.ztvSoftwareVersionSchema
                    : this.deviceInfo.ztvSoftVer;
                  this.startConfigurationData["ztvoutputSoftVer"] = this
                    .ztvoutputSoftwareVersionSchema
                    ? this.ztvoutputSoftwareVersionSchema
                    : this.deviceInfo.ztvoutputSoftVer;
                  this.startConfigurationData["lipSoftVer"] = this
                    .lipSoftwareVersionSchema
                    ? this.lipSoftwareVersionSchema
                    : this.deviceInfo.lipSoftVer;
                  this.startConfigurationData["rsoSoftVer"] = this
                    .rsoSoftwareVersionSchema
                    ? this.rsoSoftwareVersionSchema
                    : this.deviceInfo.rsoSoftVer;
                  this.startConfigurationData["device12nc"] =
                    this.deviceInfo.device12nc;
                  this.deviceInfo.resp = this.deviceInfo.resp
                    ? this.deviceInfo.resp
                    : {};
                  this.startConfigurationData["resp"] = this.deviceInfo.resp;
                  this.startConfigurationData["picture"] = this.deviceInfo
                    .devicetypePicture
                    ? this.deviceInfo.devicetypePicture
                    : this.deviceInfo.picture;
                  this.startConfigurationData["feature12Nc"] =
                    this.feature12NcName.length > 0
                      ? this.feature12NcName
                      : this.deviceInfo.feature12Nc;
                  localStorage.setItem(
                    "configurationData",
                    JSON.stringify(this.startConfigurationData)
                  );
                  localStorage.setItem(
                    "oldConfigurationData",
                    JSON.stringify(this.startConfigurationData)
                  );
                  // this.service.setConfigurationData(this.startConfigurationData);
                  this.loading = false;
                  this.isFeatureSelected = true;
                  this.activemodal.close();
                  localStorage.removeItem("deviceVersion");
                  // this.service.sendModes('Advanced')
                  this.loadFFStandardMode();
                  this.route.navigate(["/featureLanding"]);
                  var url = window.location.href;
                  if (url.includes("/featureLanding") && !this.isRelaod) {
                    var removeResp = JSON.parse(
                      localStorage.getItem("configurationData")
                    );
                    if (removeResp["resp"]["CLO"]) {
                      delete removeResp["resp"]["CLO"];
                    } else if (removeResp["resp"]["CLOLite"]) {
                      delete removeResp["resp"]["CLOLite"];
                    }
                    localStorage.setItem(
                      "configurationData",
                      JSON.stringify(removeResp)
                    );
                    window.location.reload();
                  }
                },
                (error) => {
                  this.loading = false;
                  const modalRef = this.modalService.open(ErrorComponent, {
                    keyboard: false,
                    centered: true,
                    windowClass: "error-style, error-style1",
                  });
                  modalRef.componentInstance.errorName = error["message"];
                  modalRef.componentInstance.errorDescription =
                    error["description"];
                  //alert(error["description"]);
                }
              );
          }
        });
    }
  }

  proceedToConfigurationData(tmp, resp, configurationID) {
    this.featuresData["failedFeatures"].forEach((element) => {
      if (
        element["message"].split(" ").join("").toLowerCase().trim() ==
        "invaliddata."
      ) {
        if (element.hasOwnProperty("featureParameters")) {
          resp[element["featureName"]] = element["featureParameters"][0];
        }
      }
    });
    this.startConfigurationData["configurationID"] = configurationID;
    this.startConfigurationData["deviceName"] = this.deviceInfo["deviceName"];
    this.startConfigurationData["softwareVersion"] =
      this.deviceInfo["softwareVersion"];
    tmp.forEach((element) => {
      const index = tmp.indexOf("Ast");
      if (index > -1) {
        tmp.splice(index, 1);
        tmp.push("Startup time");
      }
      const index1 = tmp.indexOf("Ztv");
      if (index1 > -1) {
        tmp.splice(index1, 1);
        tmp.push("0-10v / 1-10v");
      }
      const index2 = tmp.indexOf("LumInfoFormat");
      if (index2 > -1) {
        tmp.splice(index2, 1);
        tmp.push("LumInfo");
      }
      const index3 = tmp.indexOf("SrPsu");
      if (index3 > -1) {
        tmp.splice(index3, 1);
        tmp.push("Psu");
      }
    });
    if (this.softwareVer.length > 3) {
      this.softwareVer = this.softwareVer.slice(0, -2);
    }

    //MOWEB 1.0.1 DONE
    let supportedFeat12NC = this.removeUnSupportedFeat(this.feature12NcName);
    this.startConfigurationData["selectedFeaturesInfo"] = supportedFeat12NC;
    /* this.startConfigurationData["selectedFeaturesInfo"] =
      this.feature12NcName; */
    //MOWEB 1.0.1 DONE
    this.startConfigurationData["luminSoftVer"] =
      this.luminSoftwareVersionSchema;
    this.startConfigurationData["dtlSoftVer"] = this.dtlSoftwareVersionSchema;
    this.startConfigurationData["mindimlevelSoftVer"] =
      this.mindimlevelSoftwareVersionSchema;
    this.startConfigurationData["ampdimSoftVer"] =
      this.ampdimSoftwareVersionSchema;
    this.startConfigurationData["lineswitchSoftVer"] =
      this.lineswitchSoftwareVersionSchema;
    this.startConfigurationData["owpSoftVer"] = this.owpSoftwareVersionSchema;
    this.startConfigurationData["codedmainsSoftVer"] =
      this.codedmainsSoftwareVersionSchema;
    this.startConfigurationData["dalipsuSoftVer"] =
      this.dalipsuSoftwareVersionSchema;
    this.startConfigurationData["mtpSoftVer"] = this.mtpSoftwareVersionSchema;
    this.startConfigurationData["dimminginterfaceSoftVer"] =
      this.dimminginterfaceSoftwareVersionSchema;
    this.startConfigurationData["dynadimmerSoftVer"] =
      this.dynadimmerSoftwareVersionSchema;
    this.startConfigurationData["dcemergencySoftVer"] =
      this.dcemergencySoftwareVersionSchema;
    this.startConfigurationData["ztvSoftVer"] = this.ztvSoftwareVersionSchema;
    this.startConfigurationData["ztvoutputSoftVer"] =
      this.ztvoutputSoftwareVersionSchema;
    this.startConfigurationData["lipSoftVer"] = this.lipSoftwareVersionSchema;
    this.startConfigurationData["rsoSoftVer"] = this.rsoSoftwareVersionSchema;
    this.startConfigurationData["aocSoftVer"] = this.aocSoftwareVersionSchema;
    this.startConfigurationData["touchdimSoftVer"] =
      this.touchdimSoftwareVersionSchema;
    this.startConfigurationData["diia253maintenanceSoftVer"] =
      this.diia253maintenanceSoftwareVersionSchema;
    this.startConfigurationData["corridorSoftVer"] =
      this.corridorSoftwareVersionSchema;
    this.startConfigurationData["astSoftVer"] = this.astSoftwareVersionSchema;
    this.startConfigurationData["cloliteSoftVer"] =
      this.cloliteSoftwareVersionSchema;
    this.startConfigurationData["cloSoftVer"] = this.cloSoftwareVersionSchema;
    this.startConfigurationData["softwareVersion"] = this.softwareVer;
    this.startConfigurationData["softwareVerSchema"] =
      this.softwareVersionSchema;
    this.startConfigurationData["device12nc"] = this.deviceInfo.device12nc;
    this.startConfigurationData["feature12Nc"] = this.feature12NcName;
    this.startConfigurationData["picture"] = this.deviceInfo.devicetypePicture;
    this.startConfigurationData["resp"] = resp;

    let deviceObj = {
      device12nc: this.startConfigurationData["device12nc"],
      softwareVersion: this.startConfigurationData["softwareVersion"],
    };
    this.service
      .getStartConfiguration(deviceObj, this.url)
      .subscribe((data1) => {
        this.startConfigurationData["configurationID"] =
          data1["configurationID"];
        localStorage.setItem(
          "configurationData",
          JSON.stringify(this.startConfigurationData)
        );
      });
    setTimeout(() => {
      localStorage.setItem(
        "configurationData",
        JSON.stringify(this.startConfigurationData)
      );
      this.service.setConfigurationData(this.startConfigurationData);
      this.loading = false;
      this.isFeatureSelected = true;
      this.activemodal.close();
      localStorage.removeItem("deviceVersion");

      // localStorage.setItem('loadFeatureFile',"true");
      // this.service.sendModes('Advanced')
      if (this.startConfigurationData["resp"].CLOLite)
        this.service.setCloLoadConfigureExtraValidation("Configure_CLOLite");

      if (this.startConfigurationData["resp"].CLO)
        this.service.setCloLoadConfigureExtraValidation("Configure_CLO");
      localStorage.setItem("loadffflow", "true");
      this.loadFFStandardMode();
      this.route.navigate(["/featureLanding"]);
    }, 4000);
  }

  removeUnSupportedFeat(feature12NcName: any) {
    let supportFeatData: any = [];
    feature12NcName.forEach((element) => {
      if (
        !(
          // element === "ztv-" ||
          (
            element === "ampdim-" ||
            element === "owp-" ||
            //element === "lineswitch-" ||
            element === "codedmains-" ||
            element === "mtp-" ||
            //element === "dynadimmer-" ||
            element === "luminfoformat1-" ||
            //element === "luminfoformat0-" ||
            element === "dcemergency-" ||
            element === "mindimlevel-" ||
            // element === "dimminginterface-" ||
            element === "dtl-" ||
            element === "corridor-"
          )
          //|| element === "touchdim-"
        )
      ) {
        supportFeatData.push(element);
      }
    });
    return supportFeatData;
  }

  removeId() {
    localStorage.removeItem("configId");
    // localStorage.removeItem('configurationData');
    localStorage.setItem("newDeviceBoolen", JSON.stringify(true));
    // localStorage.removeItem('comparefirmwarenoLatest');
  }

  removeVer() {
    if (localStorage.getItem("setLastSoftwareVersion")) {
      let ver = JSON.parse(localStorage.getItem("setLastSoftwareVersion"));
      localStorage.setItem("comparefirmwarenoLatest", JSON.stringify(ver));
    }
  }

  getConfigurationNumber() {
    if (localStorage.getItem("comparefirmwarenoLatest")) {
      this.softwareVer = JSON.parse(
        localStorage.getItem("comparefirmwarenoLatest")
      );
    }
    this.loading = true;
    if (this.url.length) {
      this.unsubscribe.next();
      this.envStartConfigURL = this.url;
      let mockDevice = {
        device12nc: this.deviceInfo.device12nc,
        deviceName: this.deviceInfo.deviceName,
        softwareVersion: this.softwareVer,
      };
      this.service
        .getStartConfiguration(mockDevice, this.envStartConfigURL)
        .subscribe(
          (data) => {
            this.startConfigurationData["configurationID"] =
              data["configurationID"];
            localStorage.setItem(
              "configId",
              this.startConfigurationData["configurationID"]
            );
            this.startConfigurationData["deviceName"] =
              this.deviceInfo["deviceName"];
            this.startConfigurationData["softwareVersion"] =
              this.deviceInfo["softwareVersion"];
            this.startConfigurationData["softwareVerSchema"] =
              this.softwareVersionSchema;
            this.startConfigurationData["device12nc"] =
              this.deviceInfo.device12nc;
            this.startConfigurationData["feature12Nc"] = this.feature12NcName;
            localStorage.setItem(
              "configurationData",
              JSON.stringify(this.startConfigurationData)
            );
            this.featureFileImport();
            // this.loading=false;
            // this.isFeatureSelected=true;
          },
          (err) => {
            this.loading = false;
            const modalRef = this.modalService.open(ErrorComponent, {
              keyboard: false,
              centered: true,
              windowClass: "error-style, error-style1",
            });
            modalRef.componentInstance.errorName = err["message"];
            modalRef.componentInstance.errorDescription = err["description"];
            //alert(error["description"]);
          }
        );
    }
  }

  featureFileImport() {
    this.loading = true;
    if (this.url.length) {
      this.unsubscribe.next();
      this.importLinkFeature = this.url + this.importFeatureFile;
      let modes = JSON.parse(localStorage.getItem("modes"));
      let newDeviceBoolen = JSON.parse(localStorage.getItem("newDeviceBoolen"));
      this.service
        .apiCall(
          "post",
          this.importLinkFeature +
            this.startConfigurationData["configurationID"] +
            "/feature-file",
          this.textContent
        )
        .subscribe(
          (data) => {
            this.featuresData = data;
            if (
              data["successfulFeatures"].length > 0 &&
              data["failedFeatures"].length === 0 &&
              (modes == "Advanced" || modes == "Standard") //
            ) {
              this.isError = true;
              localStorage.setItem("importFeatureData", JSON.stringify(data));
            }
            if (
              data["successfulFeatures"].length > 0 &&
              data["failedFeatures"].length > 0
            ) {
              if (this.newDevice) {
                this.isError = true;
                const modalRef = this.modalService.open(
                  SelectingDeviceModalComponent,
                  {
                    backdrop: "static",
                    keyboard: false,
                    windowClass: "select-device-modal-comp",
                    centered: true,
                  }
                );
                modalRef.componentInstance.device = this.deviceInfo;
                this.service.modalConfigurationData("uploadFeatureFile");
                this.service.sendValidationMessage("success");
              }
              localStorage.setItem("importFeatureData", JSON.stringify(data));
            }
            if (
              data["successfulFeatures"].length > 0 &&
              data["failedFeatures"].length > 0 &&
              (modes == "Advanced" || modes == "Standard") //
            ) {
              if (!this.newDevice) {
                this.isError = true;
                const modalRef = this.modalService.open(
                  FeatureFilePartialCompatibleModalComponent,
                  {
                    backdrop: "static",
                    keyboard: false,
                    windowClass: "select-device-modal-comp",
                    centered: true,
                  }
                );
                modalRef.componentInstance.device = this.deviceInfo;
                modalRef.componentInstance.hasSuccessfulFeatures = true;
                this.service.sendSelectedModala("showFFUploadModal");
                this.service.modalConfigurationData("uploadFeatureFile");
                this.service.sendValidationMessage("success");
              }
              localStorage.setItem("importFeatureData", JSON.stringify(data));
            }
            if (
              data["failedFeatures"].length > 0 &&
              data["successfulFeatures"].length === 0 &&
              (modes == "Advanced" || modes == "Standard") //
            ) {
              let failedFetureWithInvalidData = false;
              data["failedFeatures"].forEach((element) => {
                if (
                  element["message"]
                    .split(" ")
                    .join("")
                    .toLowerCase()
                    .trim() !== "invaliddata."
                ) {
                  failedFetureWithInvalidData = true;
                }
              });
              if (failedFetureWithInvalidData) {
                if (!this.newDevice) {
                  this.isError = false;
                  const modalRef = this.modalService.open(
                    FeatureFilePartialCompatibleModalComponent,
                    {
                      backdrop: "static",
                      keyboard: false,
                      windowClass: "select-device-modal-comp",
                      centered: true,
                    }
                  );
                  modalRef.componentInstance.device = this.deviceInfo;
                  modalRef.componentInstance.hasSuccessfulFeatures = false;
                  this.service.sendSelectedModala("showFFUploadModal");
                  this.service.modalConfigurationData("uploadFeatureFile");
                  this.service.sendValidationMessage("failed");
                }
              }

              localStorage.setItem("importFeatureData", JSON.stringify(data));
            }
            if (
              data["failedFeatures"].length > 0 &&
              data["successfulFeatures"].length === 0
            ) {
              if (this.newDevice) {
                this.isError = false;
                const modalRef = this.modalService.open(
                  SelectingDeviceModalComponent,
                  {
                    backdrop: "static",
                    keyboard: false,
                    windowClass: "select-device-modal-fail",
                    centered: true,
                  }
                );
                modalRef.componentInstance.device = this.deviceInfo;
                this.service.modalConfigurationData("uploadFeatureFile");
                this.service.sendValidationMessage("failed");
              }
              localStorage.setItem("importFeatureData", JSON.stringify(data));
            }
            if (
              data["successfulFeatures"].length > 0 &&
              data["failedFeatures"].length === 0 &&
              modes == "Standard" &&
              this.newDevice
            ) {
              this.loading = true;
              this.fileLoad = true;
              this.configureDevice();
            } else {
              this.loading = false;
              this.fileLoad = false;
            }
          },
          (err) => {
            this.fileLoad = false;
            this.loading = false;
            const modalRef = this.modalService.open(ErrorComponent, {
              keyboard: false,
              centered: true,
              windowClass: "error-style, error-style1",
            });
            modalRef.componentInstance.errorName = err["message"];
            modalRef.componentInstance.errorDescription = err["description"];
            //alert(error["error"]);
          }
        );
    }
  }
  onChange(feature, checked?) {
    this.selectedFeature = feature;
    if (checked === undefined) {
      checked = true;
    }
    if (feature === "Clo") {
      this.cloAlwaysChecked = checked;
    }
    if (feature === "Alo") {
      this.aloAlwaysChecked = checked;
    }
    if (feature === "Aoc") {
      this.aocAlwaysChecked = checked;
    }
    if (feature === "Psu") {
      this.psuAlwaysChecked = checked;
    }
    if (feature === "LumInfo") {
      this.LumInfoAlwaysChecked = checked;
    }
    if (feature === "0-10v / 1-10v") {
      this.zTvAlwaysChecked = checked;
    }
    if (feature === "CloLite" || feature === "Clolite") {
      this.cloLiteAlwaysChecked = checked;
    }
    if (feature === "Startup time") {
      this.startupAlwaysChecked = checked;
    }

    if (feature === "Min Dim Level") {
      this.minDimAlwaysChecked = checked;
    }
    if (feature === "DTL" || feature === "Dtl") {
      this.DTLAlwaysChecked = checked;
    }
    if (feature === "Corridor") {
      this.corridorAlwaysChecked = checked;
    }
    if (feature === "DC Emergency") {
      this.dcemergencyAlwaysChecked = checked;
    }
    if (feature === "Touch and Dim" || feature === "touchdim") {
      this.touchDimAlwaysChecked = checked;
    }
    const selectedFeaturesArray = <FormArray>(
      this.startConfigForm.controls.selectedFeatures
    );
    if (checked) {
      selectedFeaturesArray.push(new FormControl(feature));
    } else {
      let index = selectedFeaturesArray.controls.findIndex(
        (x) => x.value === feature
      );
      selectedFeaturesArray.removeAt(index);
    }
    this.isFeatureSelected = this.startConfigForm.value.selectedFeatures.length;
  }
  schemalistApi() {
    if (this.deviceInfo) {
      if (typeof this.latestDvValue !== "undefined") {
        return this.service.getSupportedSchemaList(
          this.envScehmaLIstApi,
          this.deviceInfo.device12nc,
          this.latestDvValue
        );
      }

      // return this.service.apiCall('get',this.envScehmaLIstApi+'?12nc='+this.deviceInfo.device12nc+'&'+'Software Version='+this.softwareVer);
    }
  }
  schemalist() {
    this.feature12NcName = [];
    this.customLoader = true;
    this.service.getEnvValue();
    this.subscription = this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length) {
          this.unsubscribe.next();
          this.envScehmaLIstApi = url;
          if (this.schemalistApi()) {
            this.schemalistApi().subscribe(
              (resp) => {
                this.schemaResp = resp["metadata"].schemas;
                this.isFeatureAvailable = false;
                this.schemaResp.forEach((element, index) => {
                  let birth =
                    element.match(/[^,]*mcsa-[^,]*/g) ||
                    element.match(/[^,]*birth-[^,]*/g) ||
                    element.match(/[^,]*public-[^,]*/g) ||
                    element.match(/[^,]*bl[^,]*/g) ||
                    element.match(/[^,]*''[^,]*/g);
                  if (!birth) {
                    var splitted = element.split(":")[1];
                    if (splitted) {
                      var feature12NCvalue = splitted.substring(
                        0,
                        splitted.length - this.deviceInfo.device12nc.length
                      );
                      this.feature12NcName.push(feature12NCvalue);
                    }
                  }
                });
                this.feature12NcName = this.feature12NcName.filter(Boolean);
                this.customLoader = false;
                let val = this.schemaResp.toString();
                this.featureArray = [];
                let versiontoString =
                  val.match(/[^,]*alo[^,]*/g) ||
                  val.match(/[^,]*clo[^,]*/g) ||
                  val.match(/[^,]*aoc[^,]*/g) ||
                  val.match(/[^,]*ztv[^,]*/g);
                if (versiontoString) {
                  this.softwareVersionSchema = versiontoString
                    .toString()
                    .split(":")[2];
                }
                if (val.match(/[^,]*alo[^,]*/g)) {
                  this.featureArray.push("ALO");
                }
                if (val.match(/[^,]*clo-[^,]*/g)) {
                  if (
                    val
                      .match(/[^,]*clo-[^,]*/g)
                      .toString()
                      .split(":")[1]
                      .slice(
                        0,
                        val
                          .match(/[^,]*clo-[^,]*/g)
                          .toString()
                          .split(":")[1]
                          .indexOf(this.deviceInfo.device12nc)
                      ) === "clo-"
                  ) {
                    let cloversiontoString = val.match(/[^,]*clo-[^,]*/g);
                    this.cloSoftwareVersionSchema = cloversiontoString
                      .toString()
                      .split(":")[2];
                    this.featureArray.push("CLO");
                  }
                }
                if (val.match(/[^,]*aoc[^,]*/g)) {
                  this.featureArray.push("AOC");
                  let aocversiontoString = val.match(/[^,]*aoc[^,]*/g);
                  this.aocSoftwareVersionSchema = aocversiontoString
                    .toString()
                    .split(":")[2];
                }
                if (val.match(/[^,]*ast[^,]*/g)) {
                  let astversiontoString = val.match(/[^,]*ast[^,]*/g);
                  this.astSoftwareVersionSchema = astversiontoString
                    .toString()
                    .split(":")[2];
                  this.featureArray.push("Startup time");
                  this.isLightControl = this.isLightControl + 1;
                }
                if (val.match(/[^,]*corridor[^,]*/g)) {
                  let corridorversiontoString =
                    val.match(/[^,]*corridor[^,]*/g);
                  this.corridorSoftwareVersionSchema = corridorversiontoString
                    .toString()
                    .split(":")[2];
                  this.featureArray.push("Corridor");
                }
                if (val.match(/[^,]*dynadimmer[^,]*/g)) {
                  let dynadimmerVersiontoString =
                    val.match(/[^,]*dynadimmer[^,]*/g);
                  this.dynadimmerSoftwareVersionSchema =
                    dynadimmerVersiontoString.toString().split(":")[2];
                  this.featureArray.push("Dyna Dimmer");
                }
                if (val.match(/[^,]*dimminginterface[^,]*/g)) {
                  let dimminginterfaceVersiontoString = val.match(
                    /[^,]*dimminginterface[^,]*/g
                  );
                  this.dimminginterfaceSoftwareVersionSchema =
                    dimminginterfaceVersiontoString.toString().split(":")[2];
                  this.featureArray.push("dimminginterface");
                }
                if (val.match(/[^,]*mtp[^,]*/g)) {
                  let mtpVersiontoString = val.match(/[^,]*mtp[^,]*/g);
                  this.mtpSoftwareVersionSchema = mtpVersiontoString
                    .toString()
                    .split(":")[2];
                  this.featureArray.push("Mtp");
                }
                if (val.match(/[^,]*ampdim[^,]*/g)) {
                  let ampdimVersiontoString = val.match(/[^,]*ampdim[^,]*/g);
                  this.ampdimSoftwareVersionSchema = ampdimVersiontoString
                    .toString()
                    .split(":")[2];
                  this.featureArray.push("Amp Dim");
                }
                if (val.match(/[^,]*lineswitch[^,]*/g)) {
                  let lineswitchVersiontoString =
                    val.match(/[^,]*lineswitch[^,]*/g);
                  this.lineswitchSoftwareVersionSchema =
                    lineswitchVersiontoString.toString().split(":")[2];
                  this.featureArray.push("Line Switch");
                }
                if (val.match(/[^,]*owp[^,]*/g)) {
                  let owpVersiontoString = val.match(/[^,]*owp[^,]*/g);
                  this.owpSoftwareVersionSchema = owpVersiontoString
                    .toString()
                    .split(":")[2];
                  this.featureArray.push("Owp");
                }
                if (val.match(/[^,]*codedmains[^,]*/g)) {
                  let codedmainsVersiontoString =
                    val.match(/[^,]*codedmains[^,]*/g);
                  this.codedmainsSoftwareVersionSchema =
                    codedmainsVersiontoString.toString().split(":")[2];
                  this.featureArray.push("Codedmains");
                }
                if (val.match(/[^,]*dalipsu[^,]*/g)) {
                  let dalipsuVersiontoString = val.match(/[^,]*dalipsu[^,]*/g);
                  this.dalipsuSoftwareVersionSchema = dalipsuVersiontoString
                    .toString()
                    .split(":")[2];
                  this.featureArray.push("DALI PSU");
                }
                if (val.match(/[^,]*srpsu[^,]*/g)) {
                  this.featureArray.push("SR PSU");
                }
                if (val.match(/[^,]*luminfo[^,]*/g)) {
                  let luminversiontoString = val.match(/[^,]*luminfo[^,]*/g);
                  this.luminSoftwareVersionSchema = luminversiontoString
                    .toString()
                    .split(":")[2];
                  this.featureArray.push("Luminaire info");
                  this.isLuminaireInfo = 1;
                }
                if (val.match(/[^,]*ztv[^,]*/g)) {
                  this.featureArray.push("0-10v / 1-10v");
                  let ztvversiontoString = val.match(/[^,]*ztv[^,]*/g);
                  this.ztvSoftwareVersionSchema = ztvversiontoString
                    .toString()
                    .split(":")[2];
                  this.isLightControl = this.isLightControl + 1;
                }
                if (val.match(/[^,]*ztvoutput[^,]*/g)) {
                  this.featureArray.push("ztvoutput");
                  let ztvoutputversiontoString =
                    val.match(/[^,]*ztvoutput[^,]*/g);
                  this.ztvoutputSoftwareVersionSchema = ztvoutputversiontoString
                    .toString()
                    .split(":")[2];
                  this.isLightControl = this.isLightControl + 1;
                }
                if (val.match(/[^,]*lip[^,]*/g)) {
                  this.featureArray.push("lip");
                  let lipversiontoString = val.match(/[^,]*lip[^,]*/g);
                  if (lipversiontoString.length > 1) {
                    this.lipSoftwareVersionSchema = lipversiontoString[1]
                      .toString()
                      .split(":")[2];
                  } else {
                    this.lipSoftwareVersionSchema = lipversiontoString
                      .toString()
                      .split(":")[2];
                  }
                  this.isLightControl = this.isLightControl + 1;
                }
                if (val.match(/[^,]*rso[^,]*/g)) {
                  this.featureArray.push("rso");
                  let rsoversiontoString = val.match(/[^,]*rso[^,]*/g);
                  this.rsoSoftwareVersionSchema = rsoversiontoString
                    .toString()
                    .split(":")[2];
                  this.isLightControl = this.isLightControl + 1;
                }
                if (val.match(/[^,]*dtl[^,]*/g)) {
                  let dtlVersiontoString = val.match(/[^,]*dtl[^,]*/g);
                  this.dtlSoftwareVersionSchema = dtlVersiontoString
                    .toString()
                    .split(":")[2];
                  this.featureArray.push("DTL");

                  this.isLightControl = this.isLightControl + 1;
                }
                if (val.match(/[^,]*mindimlevel[^,]*/g)) {
                  let mindimlevelVersiontoString = val.match(
                    /[^,]*mindimlevel[^,]*/g
                  );
                  this.mindimlevelSoftwareVersionSchema =
                    mindimlevelVersiontoString.toString().split(":")[2];
                  this.featureArray.push("mindimlevel");
                  this.featureArray.push("Min Dim Level");
                  this.isLightControl = this.isLightControl + 1;
                }
                if (val.match(/[^,]*dcemergency[^,]*/g)) {
                  let dcemergencyVersiontoString = val.match(
                    /[^,]*dcemergency[^,]*/g
                  );
                  this.dcemergencySoftwareVersionSchema =
                    dcemergencyVersiontoString.toString().split(":")[2];
                  this.featureArray.push("DC Emergency");
                }
                if (val.match(/[^,]*mindimlevel[^,]*/g)) {
                  this.featureArray.push("Min Dim Level");
                  this.isLightControl = this.isLightControl + 1;
                }
                if (val.match(/[^,]*clolite[^,]*/g)) {
                  let cloliteversiontoString = val.match(/[^,]*clolite[^,]*/g);
                  this.cloliteSoftwareVersionSchema = cloliteversiontoString
                    .toString()
                    .split(":")[2];
                  this.featureArray.push("CLO Lite");
                }
                if (val.match(/[^,]*touchdim[^,]*/g)) {
                  let touchdimversiontoString =
                    val.match(/[^,]*touchdim[^,]*/g);
                  this.touchdimSoftwareVersionSchema = touchdimversiontoString
                    .toString()
                    .split(":")[2];
                  this.featureArray.push("Touch and Dim");
                }
                if (val.match(/[^,]*diia253maintenance[^,]*/g)) {
                  let diia253maintenanceversiontoString = val.match(
                    /[^,]*diia253maintenance[^,]*/g
                  );
                  this.diia253maintenanceSoftwareVersionSchema =
                    diia253maintenanceversiontoString.toString().split(":")[2];
                  this.featureArray.push("diia253maintenance");
                }
                this.filteredFeatureArray = this.featureArray.filter((item) => {
                  if (
                    item === "CLO" ||
                    item === "CLO Lite" ||
                    item === "DTL" ||
                    item === "Line Switch" ||
                    item === "Corridor" ||
                    item === "DC Emergency" ||
                    item === "Min Dim Level"
                  ) {
                    this.isLightControl = this.isLightControl + 1;
                  }
                  if (item === "ALO" || item === "AOC") {
                    this.isLuminaire = this.isLuminaire + 1;
                  }
                });
                if (this.featureArray.length < 1) {
                  this.isFeatureAvailable = true;
                }
              },
              (error) => {
                alert("No Device Found !!");
                this.goBack();
              }
            );
          }
        }
      });
  }

  // Export feature file starts here

  goBack() {
    this.activemodal.close();
  }
  // onFeatureChange(featureName,event){
  //   if (event) {
  //     this.countChecked = this.countChecked+1;
  //   } else {
  //     this.countChecked = this.countChecked-1;
  //   }
  //   if (event === true) {
  //     if (this.countChecked == 1 ) {
  //       this.isDisabledButton = true;
  //     } else {
  //       this.isDisabledButton = false;
  //     }
  //   } else {
  //     if (this.countChecked <1) {
  //       this.isDisabledButton = false;
  //     } else {
  //       this.isDisabledButton = true;
  //     }

  //   }
  // }
  exportFeatureFile() {
    this.loading = true;
    this.isDisabledButton = false;
    let configurationID;
    if (localStorage.getItem("configurationData")) {
      var dataFromConfig = JSON.parse(
        localStorage.getItem("configurationData")
      );
      this.deviceInfo = dataFromConfig;
      this.softwareVer = dataFromConfig.softwareVersion;
      configurationID = this.deviceInfo.configurationID;
    } else {
      configurationID = this.featureInfo["configurationID"];
    }
    this.service.getEnvValue();
    this.service.getEnvData.pipe(takeUntil(this.unsubscribe)).subscribe(
      (url) => {
        if (url.length) {
          this.unsubscribe.next();
          this.envFeatureFileUrl = url + this.featureFileUrl;
          this.service
            .generatefile(this.envFeatureFileUrl, configurationID)
            .subscribe(
              (response) => {
                this.loading = false;
                this.isDisabledButton = true;
                let encryptedText = response;
                let blob: any = new Blob([encryptedText], {
                  type: "application/plain; charset=utf-8",
                });
                // const url= window.URL.createObjectURL(blob);
                this.service.getReload.subscribe((resp) => {
                  if (resp === "pass") {
                    this.isRelaod = true;
                  }
                });
                saveAs(
                  blob,
                  this.deviceInfo.device12nc +
                    "_" +
                    this.deviceInfo.deviceName +
                    "_" +
                    this.softwareVer +
                    "_" +
                    this.currentDate +
                    ".xml"
                );
                if (response) {
                  localStorage.setItem("newDeviceBoolen", JSON.stringify(true));
                  // localStorage.removeItem("configurationData");
                  this.onSubmit();
                  // localStorage.removeItem("firmwarenoLatest");
                  // localStorage.removeItem("comparefirmwarenoLatest");
                  this.activemodal.close();
                  localStorage.setItem("modes", JSON.stringify("Advanced"));
                  localStorage.setItem(
                    "featureFileCReation",
                    JSON.stringify(true)
                  );
                  // this.route.navigate(['/deviceList']);
                  this.removeId();
                }
              },
              (err) => {
                this.loading = false;
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = err["message"];
                modalRef.componentInstance.errorDescription =
                  err["description"];
                //alert(JSON.stringify(error));
              }
            );
        }
      },
      (err) => {
        this.loading = false;
        const modalRef = this.modalService.open(ErrorComponent, {
          keyboard: false,
          centered: true,
          windowClass: "error-style, error-style1",
        });
        modalRef.componentInstance.errorName = err["message"];
        modalRef.componentInstance.errorDescription = err["description"];
        //alert(error["description"]);
      }
    );
  }

  // selectAll() {
  //   this.startConfigForm = this.fb.group({
  //     selectedFeatures: this.fb.array([])
  //   });
  //   this.featureArray.forEach((item) => {
  //     this.alwaysChecked = true;
  //     let iConv = item.toLowerCase();
  //     let jConv = iConv.charAt(0).toUpperCase() + iConv.slice(1);
  //     if(jConv === 'Sr psu') {
  //       jConv = 'Psu';
  //     }
  //     if(jConv === 'Luminaire info') {
  //       jConv = 'LumInfo';
  //     }
  //     if(jConv === 'Clo lite') {
  //       jConv = 'CloLite';
  //     }
  //     if(jConv === 'Min dim level') {
  //       jConv = 'Min Dim Level';
  //     }
  //     if(jConv === 'Dtl') {
  //       jConv = 'DTL';
  //     }
  //     if(jConv === 'Corridor') {
  //       jConv = 'Corridor';
  //     }
  //     if(jConv === 'Dc emergency') {
  //       jConv = 'DC Emergency';
  //     }
  //     if(jConv === 'Touch and dim') {
  //       jConv = 'Touch and Dim';
  //     }
  //     // if(jConv === '0-10v / 1-10v') {
  //     //   jConv = 'Ztv';
  //     // }
  //     this.onChange(jConv, this.alwaysChecked);
  //   })
  // }

  // unselectAll(){
  //   this.startConfigForm = this.fb.group({
  //     selectedFeatures: this.fb.array([])
  //   });
  //   this.featureArray.forEach((item) => {
  //     this.alwaysChecked = false;
  //     let iConv = item.toLowerCase();
  //     let jConv = iConv.charAt(0).toUpperCase() + iConv.slice(1);
  //     if(jConv === 'Sr psu') {
  //       jConv = 'Psu';
  //     }
  //     if(jConv === 'Luminaire info') {
  //       jConv = 'LumInfo';
  //     }
  //     if(jConv === 'Clo lite') {
  //       jConv = 'CloLite';
  //     }
  //     if(jConv === 'Dtl') {
  //       jConv = 'DTL';
  //     }
  //     if(jConv === 'Min Dim level' || jConv === 'Min dim level') {
  //       jConv = 'Min Dim Level';
  //     }
  //     if(jConv === 'Corridor') {
  //       jConv = 'Corridor';
  //     }
  //     if(jConv === 'Dc emergency') {
  //       jConv = 'DC Emergency';
  //     }
  //     if(jConv === 'Touch and dim') {
  //       jConv = 'Touch and Dim';
  //     }
  //     // if(jConv === '0-10v / 1-10v') {
  //     //   jConv = 'Ztv';
  //     // }
  //     this.onChange(jConv, this.alwaysChecked);
  //   })
  // }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
    this.service.callConfigMethod("");
    this.service.sendSelectedModala("");
    this.service.modalConfigurationData("");
  }

  getDeviceSoftwareVersion() {
    if (!this.envAloschemaDetails.startsWith("https://api.")) {
      this.envAloschemaDetails.replace("https://", "https://api.");
    }
    return this.service.getDeviceSoftwareVersion(
      this.envAloschemaDetails,
      this.configurationData
    );
  }

  /**
   * handle file from browsing
   */
  openFileContent(files) {
    setTimeout(() => {
      this.showFileName = true;
      this.fileName = files[0].name;
      this.service.getEnvValue();
      this.service.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (url.length) {
            localStorage.setItem("saveFileName", this.fileName);
            localStorage.setItem("saveFileContent", this.textContent);
            this.unsubscribe.next();
            this.envAloschemaDetails = url + this.metaDataAPILink;
            this.service
              .apiCall("post", this.envAloschemaDetails, this.textContent)
              .subscribe(
                (resp: any) => {
                  if (resp) {
                    this.deviceDataFromMetaData = resp;
                    localStorage.setItem(
                      "decryptData",
                      JSON.stringify(this.deviceDataFromMetaData)
                    );
                    localStorage.setItem(
                      "comparefirmwarenoLatest",
                      JSON.stringify(
                        this.deviceDataFromMetaData["deviceSoftwareVersion"]
                      )
                    );
                    let objectLength = Object.keys(
                      this.deviceDataFromMetaData.device12ncWithSoftwareVersions
                    ).length;
                    for (let key in this.deviceDataFromMetaData
                      .device12ncWithSoftwareVersions) {
                      if (objectLength > 1) {
                        // let version = this.deviceDataFromMetaData.device12ncWithSoftwareVersions[key].slice(0, -2);
                        this.deviceDataFromMetaData.device12ncWithSoftwareVersions[
                          key
                        ].forEach((element) => {
                          element = element.slice(0, -2);
                          if (
                            element ===
                            this.deviceDataFromMetaData["deviceSoftwareVersion"]
                          ) {
                            this.deviceData = {
                              device12nc: key,
                              deviceSoftwareVersion: element,
                              deviceName:
                                this.deviceDataFromMetaData.deviceName,
                            };
                          }
                        });
                      } else {
                        // let version = this.deviceDataFromMetaData.device12ncWithSoftwareVersions[key][0].slice(0, -2);
                        for (let version of this.deviceDataFromMetaData
                          .device12ncWithSoftwareVersions[key]) {
                          let ver = "";
                          if (version.length > 5) {
                            ver = version.substring(0, 3);
                          } else {
                            ver = version.slice(0, -2);
                          }

                          if (
                            ver ===
                            this.deviceDataFromMetaData["deviceSoftwareVersion"]
                          ) {
                            this.deviceData = {
                              device12nc: key,
                              deviceSoftwareVersion: ver,
                              deviceName:
                                this.deviceDataFromMetaData.deviceName,
                            };
                          }
                        }
                      }
                    }
                    this.envAloschemaDetails = this.url;
                    this.configurationData = this.deviceData.device12nc;
                    this.getDeviceSoftwareVersion().subscribe(
                      (deviceVersion) => {
                        // for (let i = 0; i < deviceVersion['items'].length; i++) {
                        //   for(let j = 0; j < deviceVersion['items'][i].softwareVersions.length; j++) {
                        //     latestVersion.push(deviceVersion['items'][i].softwareVersions[j]);
                        //     this.latestDeviceVersion.push(deviceVersion['items'][i].deviceType.nameAndVersion.split(':')[2]);
                        //   }
                        // }
                        deviceVersion["items"].forEach(function (arrayItem) {
                          for (var key in arrayItem) {
                            if (!arrayItem.hasOwnProperty("softwareVersions")) {
                              delete arrayItem[key];
                            }
                          }
                        });
                        deviceVersion["items"] = deviceVersion["items"].filter(
                          (ele) => {
                            return (
                              ele.constructor === Object &&
                              Object.keys(ele).length > 0
                            );
                          }
                        );
                        for (
                          let i = deviceVersion["items"].length - 1;
                          i >= 0;
                          i--
                        ) {
                          for (
                            let j =
                              deviceVersion["items"][i].softwareVersions
                                .length - 1;
                            j >= 0;
                            j--
                          ) {
                            this.softwareVer.push(
                              deviceVersion["items"][i].softwareVersions[j]
                            );
                            this.latestDeviceVersion.push(
                              deviceVersion["items"][
                                i
                              ].deviceType.nameAndVersion.split(":")[2]
                            );
                          }
                        }
                        this.filteredItems = deviceVersion["items"].filter(
                          (item) => {
                            return (
                              item["softwareVersions"]
                                .toString()
                                .substring(0, 3) ===
                              this.deviceDataFromMetaData[
                                "deviceSoftwareVersion"
                              ]
                            );
                          }
                        );
                      }
                    );
                    let latestVersion =
                      this.deviceDataFromMetaData[
                        "deviceSoftwareVersion"
                      ].split(".")[0];
                    setTimeout(() => {
                      // this.latestDeviceVersion =
                      //   this.latestDeviceVersion.filter((itemVal) => {
                      //     return (
                      //       itemVal.toString() === latestVersion.toString()
                      //     );
                      //   });
                      // this.latestDvValue = this.latestDeviceVersion[0];
                      this.latestDvValue =
                        this.filteredItems[0].deviceType.nameAndVersion.split(
                          ":"
                        )[2];
                    }, 3000);
                    this.loadingUpload = false;
                    this.fileLoad = false;
                    this.onItemChange("existing");
                  }
                },
                (error) => {
                  this.showFileName = false;
                  this.loadingUpload = false;
                  this.isDeviceVersionMismatch = true;
                  this.fileLoad = false;
                  if (error.message === undefined) {
                    const modalRef = this.modalService.open(ErrorComponent, {
                      keyboard: false,
                      centered: true,
                      windowClass: "error-style, error-style1",
                    });
                    modalRef.componentInstance.errorName =
                      "Authorization error";
                    modalRef.componentInstance.errorDescription =
                      "User does not have sufficient access rights to proceed";
                  }
                }
              );
          }
        });
      this.loadingUpload = false;
    }, 1000);
  }
  backToLoadFF(): void {
    this.isDeviceVersionMismatch = false;
  }
  // uploading a File Scenario
  fileBrowseHandler(files) {
    localStorage.removeItem("saveSetData");
    localStorage.removeItem("saveSetData1");
    localStorage.setItem("isAloMin", JSON.stringify(false));
    this.loadingUpload = true;
    this.fileLoad = true;
    var parts = files[0].name.split(".");
    if (
      parts[parts.length - 1] === "xml" ||
      parts[parts.length - 1] === "txt"
    ) {
      let reader = new FileReader();
      reader.onload = () => {
        // this 'text' is the content of the file
        this.textContent = reader.result;
        this.openFileContent(files);
      };
      reader.readAsText(files[0]);
    } else {
      alert("file corrupt or format not supported");
      this.loadingUpload = false;
      this.showFileName = false;
    }
  }

  searchWith12ncDevice(searchText) {
    this.service.getEnvValue();
    this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (this.url.length) {
          this.unsubscribe.next();
          this.envAloschemaDetails =
            this.url + this.fullDeviceList + "?query=nc12:*";
          this.service
            .apiCall(
              "get",
              encodeURI(
                this.envAloschemaDetails +
                  searchText +
                  "*" +
                  "&showArchive=true"
              )
            )
            .subscribe(
              (resp: any) => {
                if (resp) {
                  this.searchName = resp;
                  this.loading = false;
                  // this.fileLoad = false;
                }
              },
              (error) => {
                //alert(error["description"]);
                const modalRef = this.modalService.open(ErrorComponent, {
                  keyboard: false,
                  centered: true,
                  windowClass: "error-style, error-style1",
                });
                modalRef.componentInstance.errorName = error["message"];
                modalRef.componentInstance.errorDescription =
                  error["description"];
                this.loading = false;
                this.showFileName = false;
                // this.fileLoad = false;
              }
            );
        }
      });
  }

  onItemChange(event) {
    if (event === "new") {
      this.newDevice = true;
      this.is12nc = true;
      this.isError = false;
      localStorage.setItem("newDeviceBoolen", JSON.stringify(false));
    } else {
      this.fileLoad = true;
      this.newDevice = false;
      this.isDisabledButton = false;
      this.isName = false;
      this.is12nc = false;
      this.enableButton = false;
      this.isError = true;
      this.searchWithAllDevice12Nc(this.deviceData.device12nc);
      localStorage.setItem("newDeviceBoolen", JSON.stringify(this.newDevice));
      setTimeout(() => {
        localStorage.setItem(
          "comparefirmwarenoLatest",
          JSON.stringify(this.deviceData["deviceSoftwareVersion"])
        );
        this.deviceInfo = this.searchName[0];
        this.getConfigurationNumber();
      }, 2000);
      // setTimeout(() => {
      //   this.featureFileImport();
      // }, 4000);
    }
  }

  onSearchDevice(event) {
    this.enableButton = false;
    if (event === "12nc") {
      this.is12nc = true;
      this.isName = false;
    } else if (event === "productname") {
      this.isName = true;
      this.is12nc = false;
    } else {
      this.isName = false;
      this.is12nc = false;
    }
  }

  selectDeviceName(deivceName) {
    if (deivceName) {
      this.deviceInfo = deivceName;
      this.getSoftwareVersion();

      this.schemalist();

      setTimeout(() => {
        this.getConfigurationNumber();
      }, 1000);
      // setTimeout(() => {
      //   this.featureFileImport();
      // }, 4000);
      localStorage.setItem("device12nc", deivceName.device12nc);
      this.enableButton = true;
    } else {
      this.enableButton = false;
    }
  }

  select12nc(deivce12) {
    if (deivce12) {
      this.deviceInfo = deivce12;
      this.getSoftwareVersion();

      this.schemalist();
      setTimeout(() => {
        this.getConfigurationNumber();
      }, 1000);
      // setTimeout(() => {
      //   this.featureFileImport();
      // }, 4000);
      localStorage.setItem("device12nc", deivce12.device12nc);
      this.enableButton = true;
    } else {
      this.enableButton = false;
    }
  }

  onChangeSearch(search: string) {
    this.isError = false;
  }

  configureDevice() {
    // let modes = JSON.parse(localStorage.getItem('modes'));
    // if (modes == "Standard"){

    this.schemalist();
    setTimeout(() => {
      this.onSubmit();
    }, 2000);
    // }else{
    //     const modalRef = this.modalService.open(ConfigureDeviceModalComponent,{windowClass: 'select-feature-modal',backdropClass: "customBackdrop"});
    //     this.activemodal.close();
    //     modalRef.componentInstance.deviceInfo = this.deviceInfo;
    //     this.service.modalConfigurationData('configData');
    // }
  }

  remove() {
    localStorage.setItem("newDeviceBoolen", JSON.stringify(true));
    this.service.removeModes("invalid");
  }

  selectNewDevice(newdevice) {
    this.deviceSelected = true;
    let pushCompatibleFeature = [];
    // this.feature12NcName = [];
    let count = 0;
    localStorage.removeItem("compatible");
    var previousFeatures = JSON.parse(
      localStorage.getItem("configurationData") || "{}"
    );
    var deviceApp = newdevice["name"].match(/^(\S+)\s(.*)/).slice(1);
    this.searchWithAllDevice12Nc(deviceApp[0]);
    let device = newdevice["name"];
    device = device.split(" ")[0];
    this.configurationData = device;
    setTimeout(() => {
      if (Array.isArray(this.searchName)) {
        this.deviceInfo = this.searchName[0];
      } else {
        this.deviceInfo = this.searchName;
      }

      this.getSoftwareVersion();

      setTimeout(() => {
        this.schemalist();
      }, 2500);
    }, 1500);
    // setTimeout(() => {

    // }, 4000);

    //MOWEB 1.0.1 Changes Only DONE
    setTimeout(() => {
      let compatibleFeatures = [];
      this.feature12NcName.forEach((element) => {
        if (
          !(
            (element === "owp-" || element === "codedmains-")
            //|| element === "touchdim-"
          )
        ) {
          //
          let index =
            element.charAt(0).toUpperCase() + element.substr(1).toLowerCase();
          previousFeatures["feature12Nc"].forEach((item) => {
            item = item.charAt(0).toUpperCase() + item.slice(1);
            if (index === "Luminfoformat3-" || index === "Luminfoformat0-") {
              index = index.slice(0, -3);
              item = item.slice(0, -3);
            }
            if (item == index) {
              index = index.slice(0, -1);
              if (index === "SRPSU" || index === "Srpsu") {
                index = "SR PSU";
              }
              if (index === "Luminfoform") {
                index = "Luminaire info";
              }
              if (index === "Ast") {
                index = "AST";
              }
              if (index === "Dalipsu") {
                index = "DALI PSU";
              }
              if (index === "Mtp") {
                index = "MTP";
              }
              if (index === "Ztv") {
                index = "0-10V / 1-10V";
              }
              if (index === "touchdim") {
                index = "Touch and Dim";
              }
              compatibleFeatures.push(index);
              count++;
            }
          });
        }
      });
      this.feature12NcName.forEach((element) => {
        previousFeatures["feature12Nc"].forEach((item) => {
          if (element === "luminfoformat3-" || element === "luminfoformat0-") {
            if (element == item) {
              compatibleFeatures.push("Luminaire info");
              count++;
            }
          }
        });
      });
      compatibleFeatures.forEach((item) => {
        if (item === "Ztv") {
          item = "0-10V / 1-10V";
        }
        if (item === "Ast") {
          item = "AST";
        }
        if (item === "SR PSU") {
          item = "Psu";
        }
        if (item === "Clolite") {
          item = "CloLite";
        }
        if (item === "Luminaire info") {
          item = "LumInfo";
        }
        previousFeatures["selectedFeaturesInfo"].forEach((element) => {
          if (element === "ztv") {
            element = "0-10V / 1-10V";
          }
          if (item === element) {
            pushCompatibleFeature.push(element);
          }
        });
      });
      let ver = JSON.parse(
        localStorage.getItem("comparefirmwarenoLatest") || "{}"
      );
      this.searchName[0].devicetypePicture =
        "https://www.api.easydesignintool.signify.com" +
        this.searchName[0].devicetypePicture;

      //Release 0.14 Chnages Only MOWEB 1.0.1 DONE
      for (let index = 0; index < compatibleFeatures.length; index++) {
        const element = compatibleFeatures[index];
        if (
          element === "owp-" ||
          element === "codedmains-" ||
          element === "corridor-" ||
          element === "touchdim-" ||
          element === "mtp-" ||
          element === "ampdim-" ||
          element === "dcemergency-" ||
          element === "mindimlevel" ||
          element === "dtl-"
        ) {
          compatibleFeatures.splice(index, 1, "zzRemovedFeature");
        }
      }
      compatibleFeatures.sort();
      compatibleFeatures.forEach((element) => {
        if (element === "zzRemovedFeature") {
          compatibleFeatures.splice(
            compatibleFeatures.indexOf("zzRemovedFeature")
          );
        }
      });
      localStorage.setItem("compatible", JSON.stringify(compatibleFeatures));
      this.deviceInfo = {
        configurationID: previousFeatures.configurationID,
        deviceName: this.deviceInfo.deviceName,
        device12nc: this.deviceInfo.device12nc,
        softwareVerSchema: this.softwareVersionSchema,
        picture: this.searchName[0].devicetypePicture,
        luminSoftVer: this.luminSoftwareVersionSchema,
        dtlSoftVer: this.dtlSoftwareVersionSchema,
        mindimlevelSoftVer: this.mindimlevelSoftwareVersionSchema,
        dcemergencySoftVer: this.dcemergencySoftwareVersionSchema,
        ampdimSoftVer: this.ampdimSoftwareVersionSchema,
        lineswitchSoftVer: this.lineswitchSoftwareVersionSchema,
        owpSoftVer: this.owpSoftwareVersionSchema,
        codedmainsSoftVer: this.codedmainsSoftwareVersionSchema,
        dalipsuSoftVer: this.dalipsuSoftwareVersionSchema,
        mtpSoftVer: this.mtpSoftwareVersionSchema,
        dimminginterfaceSoftVer: this.dimminginterfaceSoftwareVersionSchema,
        dynadimmerSoftVer: this.dynadimmerSoftwareVersionSchema,
        ztvSoftVer: this.ztvSoftwareVersionSchema,
        ztvoutputSoftVer: this.ztvoutputSoftwareVersionSchema,
        lipSoftVer: this.lipSoftwareVersionSchema,
        rsoSoftVer: this.rsoSoftwareVersionSchema,
        aocSoftVer: this.aocSoftwareVersionSchema,
        touchdimSoftVer: this.touchdimSoftwareVersionSchema,
        diia253maintenanceSoftVer: this.diia253maintenanceSoftwareVersionSchema,
        corridorSoftVer: this.corridorSoftwareVersionSchema,
        astSoftVer: this.astSoftwareVersionSchema,
        cloliteSoftVer: this.cloliteSoftwareVersionSchema,
        cloSoftVer: this.cloSoftwareVersionSchema,
        selectedFeaturesInfo: this.feature12NcName,
        feature12Nc: this.feature12NcName,
        resp: previousFeatures.resp,
        softwareVersion: ver,
      };
      if (count === this.feature12NcName.length) {
        this.confirm();
      }
      this.deviceSelected = false;
    }, 6000);
  }

  confirm() {
    localStorage.setItem("configurationData", JSON.stringify(this.deviceInfo));
    this.activemodal.close();
    this.configureDevice();
    this.service.callConfigMethod("");
    // localStorage.removeItem('configurationData');
  }

  setconfiguration() {
    var url = window.location.href;
    if (url.includes("/featureLanding")) {
      var oldData = JSON.parse(localStorage.getItem("oldConfigurationData"));
      localStorage.setItem("configurationData", JSON.stringify(oldData));
    }
  }

  onChangeVersion(selectedFirmware) {
    localStorage.setItem(
      "comparefirmwarenoLatest",
      JSON.stringify(selectedFirmware)
    );
  }

  // change device partially selecting popup
  confirmDevice() {
    var feat = JSON.parse(localStorage.getItem("compatible"));
    if (feat.length > 0) {
      const modalRef = this.modalService.open(SelectingDeviceModalComponent, {
        backdrop: "static",
        keyboard: false,
        windowClass: "select-device-modal-comp",
        centered: true,
        size: "lg",
      });
      modalRef.componentInstance.device = this.deviceInfo;
      this.service.sendSelectedModala("newDevicePartial");
      this.deviceSelected = false;
    }
    if (feat == 0) {
      const modalRef = this.modalService.open(SelectingDeviceModalComponent, {
        backdrop: "static",
        keyboard: false,
        windowClass: "select-device-modal-comp",
        centered: true,
        size: "lg",
      });
      modalRef.componentInstance.device = this.deviceInfo;
      this.service.sendSelectedModala("newDevicenotSupport");
      this.deviceSelected = true;
    }
    localStorage.setItem("newDeviceBoolen", JSON.stringify(true));
  }

  ngDoCheck() {
    if (localStorage.getItem("closeConfigureModal")) {
      this.activemodal.close();
      localStorage.removeItem("closeConfigureModal");
    }
  }

  loadFFStandardMode() {
    let modes = JSON.parse(localStorage.getItem("storeConfigMode"));
    if (
      modes["storeConfigModeData"] == "Standard" &&
      modes["storeConfigModeCheck"]
    ) {
      this.startConfigurationData["selectedFeaturesInfo"] = this.featureArray;
      this.startConfigurationData["feature12Nc"] = [
        ...new Set(this.feature12NcName),
      ];
      this.service.sendQuickConfig("quickConfig");
      localStorage.setItem("quickConfiguration", JSON.stringify("quickConfig"));
      localStorage.setItem(
        "configurationData",
        JSON.stringify(this.startConfigurationData)
      );
      this.route.navigate(["/featureLanding"]);
    }
  }

  // ngAfterViewInit(){
  //   //this.onItemChange('existing');
  // }
}
