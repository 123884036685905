import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectorRef,
  HostListener,
  ViewChild,
  ElementRef,
  OnChanges,
  Input,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
  NgForm,
} from "@angular/forms";
import { isNullOrUndefined } from "util";
import { ApiService } from "../../../core-module/services/api.service";
import { NgbTabChangeEvent, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  distinctUntilChanged,
  pairwise,
  startWith,
  takeUntil,
} from "rxjs/operators";
import { Subscription, Subject } from "rxjs";
import { restore } from "../../../../assets/json/restoreTooltip.en";
import { element } from "protractor";
import { MidnightShiftCalculationComponent } from "src/app/midnight-shift-calculation/midnight-shift-calculation.component";
import { parse } from "querystring";

@Component({
  selector: "app-chartdata-dyna",
  templateUrl: "./chartdata-dyna.component.html",
  styleUrls: ["./chartdata-dyna.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ChartdataDynaDimmerComponent
  implements OnInit, OnDestroy, OnChanges
{
  @ViewChild("testInput", { static: false }) testInput: ElementRef;
  addForm: FormGroup;
  rows: FormArray;
  rowLength = 0;
  registerForm: FormGroup;
  submitted = false;
  activeIdString: any = "1";
  checkValid = true;
  fewFieldValuePresent = false;
  dataToShow = [];
  Max;
  Steps;
  Initial;
  Final;
  timeHHMM;
  outputLevel;
  fadeTime;
  unsubscribe = new Subject<any>();
  isTableValuesChanged = false;
  isValuesNumericGenerated = false;
  tableValueSubmit = false;
  isNumericCreatedRows = false;
  isGraphUpdated = false;
  dynadisableGraph = false;
  defaultColor = true;
  disableAddBtn = false;
  timeHrsMMValuesArr: any = [];
  isAscending = false;
  isGraphDragUpdate = false;
  unsubscribeNumeric = new Subject<any>();
  menuState;
  menuPosition;
  menuPositionX;
  menuPositionY;
  menuWidth;
  menuHeight;
  windowWidth;
  windowHeight;
  isclearTableValueFieldscalled: boolean = false;
  selectedRow;
  disableAddAboveBtn = false;
  disableAddBelowBtn = false;
  hideAll: boolean = false;
  disableNumericClear = true;
  unsubscribeGraphType = new Subject<any>();
  dynaenableChecked = false;
  cloLite;
  showDynaDimmer;
  activeHours;
  initialLevel;
  subscription;
  restoreText = restore;
  dynaOverrideMode;
  dynaOverrideMessage;
  dynaEnableMode;
  disable;
  dynaTimeReferenceMode;
  dynaMidNightShiftMode;
  showDynaDimmerEnabledProp;
  showDynaOverrideProp;
  showDynaTimeReferenceProp;
  dynaMidNightShiftDefaultMinimum;
  dynaMidNightShiftDefaultMaximum;
  dynaMidNightShiftDefaultValue;
  dynaDefaultEnabled;
  dynaDefaultTimeBased;
  dynaDefaultOverride;
  dynaEnabledEditedtVal;
  dynaTimeBasedEditedVal;
  dynaOverrideEditedVal;
  isDynaDataError: boolean = false;
  @ViewChild("contextMenu", { static: false }) contextMenu: ElementRef;
  graphRepresentationType = "";
  afterClearField = false;
  fileResponse: any = {};
  count: any;
  respVal: string;
  maxRowCount: any;
  midnightShiftEditedValue: number;
  dynaMidPointShiftValueSub: Subscription;
  graphDynaData: Subscription;
  dynaDefaultValue: Subscription;
  isEdited: any;
  pathVal: string;
  getDynaMidPointShiftValueSub: Subscription;
  getDynaMidPointSHiftDefaultPropertiesSub: Subscription;
  getDynaOverrideModeSub: Subscription;
  getDynaShowOverrideEnabledSub: Subscription;
  showPdl4:boolean;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private cdRef: ChangeDetectorRef,
    private modalService: NgbModal
  ) {
    this.addForm = this.fb.group({});
    this.rows = this.fb.array([]);
  }

  ngOnInit() {
    this.pathVal = this.apiService.getImageUrl(window.location.hostname);
    this.isEdited = false;
    const dynaDimmerReadSchema = localStorage.getItem("dynaDimmerSchema");
    const parsedData = JSON.parse(localStorage.getItem("dynaDimmerSchema"));
    this.maxRowCount = parsedData[0]["properties"]["NumberOfScenes"]["maximum"];
    if (this.maxRowCount > 9){
      this.showPdl4=true;
    }else{
      this.showPdl4=false;
    }
    //  >= 5
    //   ? parsedData[0]["properties"]["NumberOfScenes"]["maximum"]
    //   : 5;
    setTimeout(() => {
      if (parsedData[0]["properties"].hasOwnProperty("DynadimMode")) {
        this.hideAll = true;
      } else {
        this.hideAll = false;
      }
    }, 0);
    if (parsedData[0]["properties"].hasOwnProperty("DynadimmerEnabled")) {
      if (
        parsedData[0]["properties"]["DynadimmerEnabled"]["default"] === true
      ) {
        this.disable = false;
      } else if (
        parsedData[0]["properties"]["DynadimmerEnabled"]["default"] ===
        undefined
      ) {
        this.disable = false;
      } else {
        this.disable = true;
      }
    } else {
      this.disable = false;
    }
    setTimeout(() => {
      this.dynaMidPointShiftValueSub = this.getDynaMidPointShiftValueSub =
        this.apiService.getDynaMidPointShiftValue.subscribe((response) => {
          this.midnightShiftEditedValue = response;
          this.verifyDefaults();
          this.generatePayloadDynaDimmer();
        });

      this.graphDynaData = this.apiService.getGraphDynaData.subscribe(
        (response) => {
          this.rows.clear;
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            this.rows.controls[index]["controls"].timeHHMM.setValue(
              element.dyanXTime
            );
            this.rows.controls[index]["controls"].outputLevel.setValue(
              element.dyanYOutput
            );
            this.rows.controls[index]["controls"].fadeTime.setValue(
              element.dynaZfadeTime
            );
            this.addForm.addControl("rows", this.rows);
            this.rowLength = this.rows.length;
          }
          this.verifyDefaults();
          this.validateOrderOfHHMM();
          this.generatePayloadDynaDimmer();
        }
      );
      this.dynaDefaultValue = this.apiService.getDynaDefaultValue.subscribe(
        (response) => {
          if (response === "valid") {
            this.isclearTableValueFieldscalled = false;
            let cntRows = this.rows.length;

            if (cntRows === 10) {
              this.RemoveRow(9);
              this.RemoveRow(8);
              this.RemoveRow(7);
              this.RemoveRow(6);
              this.RemoveRow(5);
              this.RemoveRow(4);
              this.RemoveRow(3);
              this.RemoveRow(2);
              this.RemoveRow(1);
            }

            if (cntRows === 9) {
              this.RemoveRow(8);
              this.RemoveRow(7);
              this.RemoveRow(6);
              this.RemoveRow(5);
              this.RemoveRow(4);
              this.RemoveRow(3);
              this.RemoveRow(2);
              this.RemoveRow(1);
            }

            if (cntRows === 8) {
              this.RemoveRow(7);
              this.RemoveRow(6);
              this.RemoveRow(5);
              this.RemoveRow(4);
              this.RemoveRow(3);
              this.RemoveRow(2);
              this.RemoveRow(1);
            }

            if (cntRows === 7) {
              this.RemoveRow(6);
              this.RemoveRow(5);
              this.RemoveRow(4);
              this.RemoveRow(3);
              this.RemoveRow(2);
              this.RemoveRow(1);
            }

            if (cntRows === 6) {
              this.RemoveRow(5);
              this.RemoveRow(4);
              this.RemoveRow(3);
              this.RemoveRow(2);
              this.RemoveRow(1);
            }

            if (cntRows === 5) {
              this.RemoveRow(4);
              this.RemoveRow(3);
              this.RemoveRow(2);
              this.RemoveRow(1);
            }

            if (cntRows === 4) {
              this.RemoveRow(3);
              this.RemoveRow(2);
              this.RemoveRow(1);
            }

            if (cntRows === 3) {
              this.RemoveRow(2);
              this.RemoveRow(1);
            }

            if (cntRows === 2) {
              this.RemoveRow(1);
            }
            this.addDefaultRowSet();
            //this.apiService.setDynaDefaultColor(true);
            this.apiService.getDefaultDynaDimmerEnabledMode.subscribe(
              (response) => {
                this.dynaEnableMode = response;
                if (response) {
                  this.disable = false;
                } else {
                  this.disable = true;
                }
                this.apiService.sendDynaDimmerEnabledMode(response);
              }
            );
            this.apiService.getDefaultDynaTimeReferencedMode.subscribe(
              (response) => {
                this.dynaTimeReferenceMode = response;
                this.apiService.sendDynaTimeReferenceMode(response);
              }
            );
            this.apiService.getDefaultDynaOverrideMode.subscribe((response) => {
              this.dynaOverrideMode = response;
              this.apiService.sendDynaOveerideMode(response);
            });

            this.midnightShiftEditedValue = 0;
            this.apiService.sendDynaMidPointShiftValue(0);

            this.testInput.nativeElement.setSelectionRange(0, 0);

            this.testInput.nativeElement.focus();

            setTimeout(() => {
              this.testInput.nativeElement.dispatchEvent(
                new KeyboardEvent("keydown", { key: "Tab" })
              );
            }, 0);
          }
        }
      );
      this.apiService.getDynaDimmerAllScenes.subscribe((resp) => {});
      this.apiService.getDynaShowEnabled.subscribe((resp) => {
        if (resp === true) this.showDynaDimmerEnabledProp = true;
        else this.showDynaDimmerEnabledProp = false;
      });
      this.apiService.getDynaShowOverrideEnabled.subscribe((resp) => {
        if (resp === true) this.showDynaOverrideProp = true;
        else this.showDynaOverrideProp = false;
      });
      this.apiService.getDynaShowTimeReference.subscribe((resp) => {
        if (resp === true) {
          this.showDynaTimeReferenceProp = true;
        } else {
          this.showDynaTimeReferenceProp = false;
        }
      });
      this.apiService.getDynaDimmerEnabledMode.subscribe((response) => {
        if (response === true) {
          this.dynaEnableMode = true;
          this.disable = false;
        } else if (
          parsedData[0]["properties"].hasOwnProperty("DynadimmerEnabled") &&
          parsedData[0]["properties"]["DynadimmerEnabled"]["default"] ===
            undefined
        ) {
          this.disable = false;
        } else {
          this.dynaEnableMode = false;
          this.disable = true;
          if (parsedData[0]["properties"].hasOwnProperty("DynadimMode")) {
            this.disable = false;
          }
        }
        //FYZUR this.apiService.sendDynaDimmerEnabledMode(response);
      });

      this.apiService.getDynaOverrideMode.subscribe((response) => {
        if (response === true) {
          this.dynaOverrideMode = true;
        } else this.dynaOverrideMode = false;
      });
      this.apiService.getDynaDependencyProp.subscribe((response) => {
        if (response === false) {
          this.dynaOverrideMessage = false;
        } else this.dynaOverrideMessage = true;
      });
      this.apiService.getDefaultDynaTimeReferencedMode.subscribe((response) => {
        this.dynaTimeReferenceMode = true;
        if (response === true) {
          this.apiService.sendDynaTimeReferenceMode(true);
        } else {
          this.apiService.sendDynaTimeReferenceMode(false);
        }
      });
      this.apiService.getDynaTimeReferencedMode.subscribe((response) => {
        if (response === true) {
          this.dynaTimeReferenceMode = true;
          this.dynaMidNightShiftMode = false;
          //FYZUR this.apiService.sendDynaTimeReferenceMode(true);
        } else if (response === false) {
          this.dynaTimeReferenceMode = false;
          this.dynaMidNightShiftMode = true;
          //FYZURthis.apiService.sendDynaTimeReferenceMode(false);
        }
      });
      this.apiService.getDynaMidPointShiftMode.subscribe((response) => {
        if (this.dynaTimeReferenceMode === false) {
          this.dynaMidNightShiftMode = true;
        } else if (this.dynaTimeReferenceMode === true) {
          this.dynaMidNightShiftMode = false;
        }
      });

      this.apiService.getDynaMidPointShiftValue.subscribe((response) => {
        this.midnightShiftEditedValue = response;
      });
      this.getDynaMidPointSHiftDefaultPropertiesSub =
        this.apiService.getDynaMidPointSHiftDefaultProperties.subscribe(
          (response) => {
            this.dynaMidNightShiftDefaultMinimum = response[0];
            this.dynaMidNightShiftDefaultMaximum = response[1];
            this.dynaMidNightShiftDefaultValue = response[2];
            this.midnightShiftEditedValue = response[2];
            this.apiService.sendDynaMidPointShiftValue(
              this.midnightShiftEditedValue
            );
          }
        );
      this.apiService.getDefaultDynaDimmerEnabledMode.subscribe((response) => {
        this.dynaDefaultEnabled = response;
      });
      this.apiService.getDefaultDynaTimeReferencedMode.subscribe((response) => {
        this.dynaDefaultTimeBased = response;
      });
      this.apiService.getDefaultDynaOverrideMode.subscribe((response) => {
        this.dynaDefaultOverride = response;
      });
      this.apiService.getDefaultDynaDimmerEnabledMode.subscribe(
        (response) => {}
      );
    }, 0);

    this.fileResponse = JSON.parse(localStorage.getItem("configurationData"));
    let saveData = JSON.parse(localStorage.getItem("saveSetData"));
    if (saveData && saveData["resp"] && saveData["resp"]["DynaDimmer"]) {
      this.fileResponse = saveData;
    }
    this.dataToShow = [];
    this.rows.clear();
    this.onAddRow();
    this.defaultColor = true;
    this.addDefaultRowSet();

    ////
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );

    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (
          !isNullOrUndefined(element) &&
          !isNullOrUndefined(element["featureName"]) &&
          element["featureName"].toString().toUpperCase() === "DYNADIMMER"
        ) {
          isConfigEdited = true;
          let DYNADIM: any = element["featureParameters"][0];
          setTimeout(() => {
            this.addDynaLoadedFeatureReadValues(DYNADIM);
          }, 0);
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }

    /////
    if (isConfigEdited) {
    } else {
      setTimeout(() => {
        const dynaDimmerLoaddedFetaure = JSON.parse(
          localStorage.getItem("configurationData")
        );
        if (dynaDimmerLoaddedFetaure) {
          if (
            dynaDimmerLoaddedFetaure.resp &&
            dynaDimmerLoaddedFetaure.resp.Dynadimmer
          ) {
            //this.isEdited = true;
            this.addDynaLoadedFeatureReadValues(
              dynaDimmerLoaddedFetaure.resp.Dynadimmer
            );
          }
        }
      }, 2000);
    }
    setTimeout(() => {
      this.sendDynaDimmerdata(
        JSON.parse(localStorage.getItem("dynaSaveSetData"))
      );
      this.isEdited = true;
    }, 1000);
  }

  sendDynaDimmerdata(finalpayload: any) {
    if (this.isEdited) {
      this.setConfigurationData(finalpayload, "Dynadimmer");
    }
  }

  setConfigurationData(value, keyVal) {
    if (!isNullOrUndefined(value)) {
      let touchDimObj = {};
      var key = keyVal;
      var data = {};
      var addNew = {
        [keyVal]: {},
      };
      data = JSON.parse(localStorage.getItem("configurationData"));
      if (data && !isNullOrUndefined(data["resp"])) {
        touchDimObj = Object.assign({}, value);
        data["resp"][keyVal] = touchDimObj;
      } else {
        data["resp"] = addNew;
        touchDimObj = Object.assign({}, value);
        data["resp"][keyVal] = touchDimObj;
      }
      localStorage.setItem("configurationData", JSON.stringify(data));
    }
  }

  addDynaLoadedFeatureReadValues(dynadimmer: any) {
    //dynadimmer: {Scene1: {DimLevel: 90, FadeTime: 25, Minute: 4, Hour: 0}, MidnightShift: 0, TimeReference: false}

    let dynaDimmerObj = dynadimmer;

    var keys = Object.keys(dynaDimmerObj);
    var values = Object.values(dynaDimmerObj);
    var result = [];
    keys.forEach((key, i) => (result[key] = values[i]));
    if (this.dynaOverrideMessage === false) {
      if (
        result["DynadimmerEnabled"] === true ||
        result["DynadimmerEnabled"] === false
      ) {
        this.apiService.showDynaEnabled(true);
        this.apiService.sendDynaDimmerEnabledMode(result["DynadimmerEnabled"]);
      } else {
        this.apiService.showDynaEnabled(false);
      }
    }
    //if (result["TimeReference"] === true) {
    if (result["TimeReference"] === true || result["TimeReference"] === false) {
      this.apiService.showDynaTimeRefernece(true);
      this.apiService.sendDynaTimeReferenceMode(result["TimeReference"]);
    } else {
      this.apiService.showDynaTimeRefernece(false);
    }

    if (this.showDynaOverrideProp) {
      if (result["OverrideMode"] === true || result["OverrideMode"] === false) {
        this.apiService.showDynaOverrideEnabled(true);
        this.apiService.sendDynaOveerideMode(result["OverrideMode"]);
      } else {
        this.apiService.showDynaOverrideEnabled(false);
      }
    }
    // if (result["MidnightShift"].toString().length>0) {

    // }
    // else {
    //   this.apiService.sendDynaMidPointShiftValue(0);
    // }

    // setTimeout(() => {
    this.apiService.sendDynaMidPointShiftValue(Number(result["MidnightShift"]));
    // }, 1000);

    this.midnightShiftEditedValue = Number(result["MidnightShift"]);
    this.rows.clear();
    if (result["Scene1"] != undefined) {
      this.addNonDefaultScenes(result["Scene1"], 0);
    }
    if (result["Scene2"] != undefined) {
      this.addNonDefaultScenes(result["Scene2"], 1);
    }
    if (result["Scene3"] != undefined) {
      this.addNonDefaultScenes(result["Scene3"], 2);
    }
    if (result["Scene4"] != undefined) {
      this.addNonDefaultScenes(result["Scene4"], 3);
    }
    if (result["Scene5"] != undefined) {
      this.addNonDefaultScenes(result["Scene5"], 4);
    }
    if (result["Scene6"] != undefined) {
      this.addNonDefaultScenes(result["Scene6"], 5);
    }
    if (result["Scene7"] != undefined) {
      this.addNonDefaultScenes(result["Scene7"], 6);
    }
    if (result["Scene8"] != undefined) {
      this.addNonDefaultScenes(result["Scene8"], 7);
    }
    if (result["Scene9"] != undefined) {
      this.addNonDefaultScenes(result["Scene9"], 8);
    }
    if (result["Scene10"] != undefined) {
      this.addNonDefaultScenes(result["Scene10"], 9);
    }
    this.verifyDefaults();
  }
  addNonDefaultScenes(scene: any, sceneNum: number) {
    this.onAddRow();
    this.rows.controls[sceneNum]["controls"].timeHHMM.setValue(
      String(
        this.getCorrectValue(scene.Hour) +
          ":" +
          this.getCorrectValue(scene.Minute)
      )
    );
    this.rows.controls[sceneNum]["controls"].outputLevel.setValue(
      Number(scene.DimLevel)
    );
    this.rows.controls[sceneNum]["controls"].fadeTime.setValue(
      Number(scene.FadeTime)
    );
    this.addForm.addControl("rows", this.rows);
    this.rowLength = this.rows.length;
    localStorage.setItem(
      "dynaScenes",
      JSON.stringify({
        timeHHMM: String(scene.Hour + ":" + scene.Minute),
        outputLevel: Number(scene.DimLevel),
        fadeTime: Number(scene.FadeTime),
      })
    );
    this.validateOrderOfHHMM();
    this.generatePayloadDynaDimmer();
   
  }
  getCorrectValue(data: any) {
    if (data.toString().length === 1) {
      return "0" + data;
    }
    return data;
  }

  ngOnChanges() {
    this.verifyDefaults();
  }
  defaultRows() {
    var lengthOfRow = this.rows.length;

    for (let index = 0; index < lengthOfRow; index++) {
      this.rows.removeAt(index);
    }
  }
  addDefaultRowSet() {
    const dynaDimmerReadSchema = JSON.parse(
      localStorage.getItem("dynaDimmerSchema")
    );
    const scenes =
      dynaDimmerReadSchema[0]["properties"]["NumberOfScenes"]["default"];
    this.defaultColor = false;
    this.apiService.setDynaDefaultColor(this.defaultColor);
    if (scenes > 1) {
      for (let i = 0; i <= scenes - 1; i++) {
        let k = i + 1;
        if (this.isclearTableValueFieldscalled === true) {
          var fadeTimeDefault: any = 0;
          var outputLevelDefault: any = 100;
          this.rows.controls[0]["controls"].timeHHMM.setValue("12:00");
          this.rows.controls[0]["controls"].outputLevel.setValue(
            outputLevelDefault
          );
          this.rows.controls[0]["controls"].fadeTime.setValue(fadeTimeDefault);
          this.addForm.addControl("rows", this.rows);
          this.rowLength = this.rows.length;
          localStorage.setItem(
            "dynaScenes",
            JSON.stringify({
              timeHHMM: "12:00",
              outputLevel: outputLevelDefault,
              fadeTime: fadeTimeDefault,
            })
          );
        } else if (this.isclearTableValueFieldscalled === false) {
          var hourDefault =
            dynaDimmerReadSchema[0]["properties"]["Scene" + `${k}`][
              "properties"
            ]["Hour"]["default"];
          var minuteDefault =
            dynaDimmerReadSchema[0]["properties"]["Scene" + `${k}`][
              "properties"
            ]["Minute"]["default"];
          var fadeTimeDefault =
            dynaDimmerReadSchema[0]["properties"]["Scene" + `${k}`][
              "properties"
            ]["FadeTime"]["default"];
          var outputLevelDefault =
            dynaDimmerReadSchema[0]["properties"]["Scene" + `${k}`][
              "properties"
            ]["DimLevel"]["default"];
          hourDefault = hourDefault <= 9 ? "0" + hourDefault : hourDefault;
          minuteDefault =
            minuteDefault <= 9 ? "0" + minuteDefault : minuteDefault;
          if (i > 0 && i <= scenes - 1) {
            this.onAddRow();
          }
          var time = `${hourDefault}` + ":" + `${minuteDefault}`;
          this.rows.controls[i]["controls"].timeHHMM.setValue(time);
          this.rows.controls[i]["controls"].outputLevel.setValue(
            outputLevelDefault
          );
          this.rows.controls[i]["controls"].fadeTime.setValue(fadeTimeDefault);
          this.addForm.addControl("rows", this.rows);
          this.rowLength = this.rows.length;
          var dataToPush = {};
          dataToPush[i] = {
            timeHHMM: time,
            outputLevel: outputLevelDefault,
            fadeTime: fadeTimeDefault,
          };
          localStorage.setItem("dynaScenes", JSON.stringify(dataToPush));
        }
      }
    } else {
      if (this.isclearTableValueFieldscalled === true) {
        var fadeTimeDefault: any = 0;
        var outputLevelDefault: any = 100;
        this.rows.controls[0]["controls"].timeHHMM.setValue("15:00");
        this.rows.controls[0]["controls"].outputLevel.setValue(
          outputLevelDefault
        );
        this.rows.controls[0]["controls"].fadeTime.setValue(fadeTimeDefault);
        this.addForm.addControl("rows", this.rows);
        this.rowLength = this.rows.length;
        localStorage.setItem(
          "dynaScenes",
          JSON.stringify({
            timeHHMM: "15:00",
            outputLevel: outputLevelDefault,
            fadeTime: fadeTimeDefault,
          })
        );
      } else if (this.isclearTableValueFieldscalled === false) {
        var hourDefault =
          dynaDimmerReadSchema[0]["properties"]["Scene1"]["properties"]["Hour"][
            "default"
          ];
        var minuteDefault =
          dynaDimmerReadSchema[0]["properties"]["Scene1"]["properties"][
            "Minute"
          ]["default"];
        const fadeTimeDefault =
          dynaDimmerReadSchema[0]["properties"]["Scene1"]["properties"][
            "FadeTime"
          ]["default"];
        const outputLevelDefault =
          dynaDimmerReadSchema[0]["properties"]["Scene1"]["properties"][
            "DimLevel"
          ]["default"];
        hourDefault = hourDefault <= 9 ? "0" + hourDefault : hourDefault;
        minuteDefault =
          minuteDefault <= 9 ? "0" + minuteDefault : minuteDefault;
        var time = `${hourDefault}` + ":" + `${minuteDefault}`;
        this.rows.controls[0]["controls"].timeHHMM.setValue(time);
        this.rows.controls[0]["controls"].outputLevel.setValue(
          outputLevelDefault
        );
        this.rows.controls[0]["controls"].fadeTime.setValue(fadeTimeDefault);
        this.addForm.addControl("rows", this.rows);
        this.rowLength = this.rows.length;
        localStorage.setItem(
          "dynaScenes",
          JSON.stringify({
            timeHHMM: time,
            outputLevel: outputLevelDefault,
            fadeTime: fadeTimeDefault,
          })
        );
      }
    }
    this.validateOrderOfHHMM();
    this.generatePayloadDynaDimmer();
  }

  inputValueChanged($event) {
    this.fewFieldValuePresent = false;
    if ($event.key !== "Tab") {
      this.submitted = true;
    }
    if ($event.key === "Backspace" || $event.key === "Delete") {
      this.submitted = false;

      if (this.fewFieldValuePresent === false) {
        this.apiService.sendGraphValidData("valid");
      }
    }
  }

  onKey($event) {
    setTimeout(() => {
      this.validateOrderOfHHMM();
      const dynaDimmerReadSchema = JSON.parse(
        localStorage.getItem("dynaDimmerSchema")
      );
      const formArray = (<FormArray>this.addForm.get("rows")).controls;
      for (let i = 0; i < formArray.length; i++) {
        var controlOutputLevelMinimum =
          dynaDimmerReadSchema[0]["properties"]["Scene" + `${i + 1}`][
            "properties"
          ]["DimLevel"]["minimum"];
        const control = (<any>formArray[i]).controls;
        const controlsArray = ["timeHHMM", "outputLevel", "fadeTime"];
        for (let j = 0; j < controlsArray.length; j++) {
          if (`${controlsArray[j]}` === "outputLevel") {
            control[`${controlsArray[j]}`].valueChanges
              .pipe(distinctUntilChanged())
              .subscribe((data) => {
                if (data) {
                  setTimeout(() => {
                    if (parseInt(data) < controlOutputLevelMinimum) {
                      control[`${controlsArray[j]}`].markAsDirty();
                      control[`${controlsArray[j]}`].markAsTouched();
                      control[`${controlsArray[j]}`].setErrors({
                        invalid: true,
                      });
                    }
                    this.apiService.sendisDynaValid(this.isDynaDataError);
                    //control[`${controlsArray[j]}`].updateValueAndValidity();
                  }, 100);
                }
              });
          } else if (`${controlsArray[j]}` != "outputLevel") {
            control[`${controlsArray[j]}`].valueChanges
              .pipe(distinctUntilChanged())
              .subscribe((data) => {
                if (data) {
                  if (control[`${controlsArray[j]}`].errors !== null) {
                    this.isDynaDataError = true;
                  } else {
                    this.isDynaDataError = false;
                  }
                  this.apiService.sendisDynaValid(this.isDynaDataError);
                  control[`${controlsArray[j]}`].updateValueAndValidity();
                }
              });
          }
        }
      }
      this.verifyDefaults();
    }, 500);
  }
  onMouseLeave() {
    this.validateOrderOfHHMM();
    //this.verifyDefaults();
  }

  onAddRow() {
    this.rows.push(this.createItemFormGroup());
    this.checkAddesRowValuesChanged(this.rows.length - 1);
    this.rowLength = this.rows.length;
    setTimeout(() => {}, 100);
    this.validateOrderOfHHMM();

    setTimeout(() => {
      if (this.rowLength === this.maxRowCount) {
        this.disableAddBtn = true;
      } else {
        this.disableAddBtn = false;
      }
    }, 50);
  }
  validateOrderOfHHMM() {
    this.isDynaDataError = false;
    localStorage.removeItem("dynaScenes");

    let isValidTime: boolean = true;
    let scenesCnt = this.rows.value.length;
    var timeHHForSorting: any[] = [];
    for (let i = 0; i < scenesCnt; i++) {
      if (this.rows.value[i].timeHHMM != null) {
        let tthhmm: string = this.rows.value[i].timeHHMM
          .toString()
          .split(":", "2")[0];
        let tthhmm1: string = this.rows.value[i].timeHHMM.toString();
        let dimlevel1: string = this.rows.value[i].outputLevel.toString();
        let floatTime = this.rows.value[i].timeHHMM
          .toString()
          .replace(":", ".");
        if (
          parseFloat(tthhmm) < 12 &&
          parseFloat(tthhmm) > 11 &&
          this.dynaTimeReferenceMode === false
        ) {
          this.rows.controls[i]["controls"].timeHHMM.setValue("");
          isValidTime = false;
        } else {
          timeHHForSorting.push(tthhmm1 + "_" + dimlevel1);
        }
      }
    }
    if (isValidTime) {
      if (timeHHForSorting != null) {
        // if (this.dynaTimeReferenceMode === true) {
        timeHHForSorting.sort((a, b) => {
          let atime1 = a.toString().split("_")[0].split(":")[0];
          let atime2 = a.toString().split("_")[0].split(":")[1];
          let btime1 = b.toString().split("_")[0].split(":")[0];
          let btime2 = b.toString().split("_")[0].split(":")[1];

          if (atime1.length === 1) atime1 = "0" + atime1;
          if (btime1.length === 1) btime1 = "0" + btime1;

          let finaltimeA = new Date(
            "1900-01-01T" + atime1 + ":" + atime2
          ).getTime();
          let finaltimeB = new Date(
            "1900-01-01T" + btime1 + ":" + btime2
          ).getTime();

          if (finaltimeA === finaltimeB) return 0;

          return finaltimeA < finaltimeB ? -1 : 1;
        });
        // }
        // else
        //   timeHHForSorting.sort()
      }
      if (this.dynaTimeReferenceMode === false) {
        let nonTimeBased15to24: any[] = [];
        let nonTimeBased1to10: any[] = [];

        timeHHForSorting.forEach((elementArr) => {
          if (
            parseFloat(elementArr.split("_")[0].split(":")[0]) >= 12 &&
            parseFloat(elementArr.split("_")[0].split(":")[0]) <= 24
          ) {
            nonTimeBased15to24.push(elementArr);
          }
          if (
            parseFloat(elementArr.split("_")[0].split(":")[0]) >= 0 &&
            parseFloat(elementArr.split("_")[0].split(":")[0]) <= 11
          ) {
            nonTimeBased1to10.push(elementArr);
          }
        });

        nonTimeBased15to24.sort((a, b) => {
          // if (a === b)
          //   return 0;

          // return a < b ? -1 : 1
          // let finaltimeA = new Date("1900-01-01T"+a).getTime();
          // let finaltimeB = new Date("1900-01-01T"+b).getTime();

          let atime1 = a.toString().split("_")[0].split(":")[0];
          let atime2 = a.toString().split("_")[0].split(":")[1];
          let btime1 = b.toString().split("_")[0].split(":")[0];
          let btime2 = b.toString().split("_")[0].split(":")[1];

          if (atime1.length === 1) atime1 = "0" + atime1;
          if (btime1.length === 1) btime1 = "0" + btime1;

          let finaltimeA = new Date(
            "1900-01-01T" + atime1 + ":" + atime2
          ).getTime();
          let finaltimeB = new Date(
            "1900-01-01T" + btime1 + ":" + btime2
          ).getTime();

          if (finaltimeA === finaltimeB) return 0;

          return finaltimeA < finaltimeB ? -1 : 1;
        });
        nonTimeBased1to10.sort((a, b) => {
          // if (a === b)
          //   return 0;

          // return a < b ? -1 : 1
          // let finaltimeA = new Date("1900-01-01T"+a).getTime();
          // let finaltimeB = new Date("1900-01-01T"+b).getTime();

          let atime1 = a.toString().split("_")[0].split(":")[0];
          let atime2 = a.toString().split("_")[0].split(":")[1];
          let btime1 = b.toString().split("_")[0].split(":")[0];
          let btime2 = b.toString().split("_")[0].split(":")[1];

          if (atime1.length === 1) atime1 = "0" + atime1;
          if (btime1.length === 1) btime1 = "0" + btime1;

          let finaltimeA = new Date(
            "1900-01-01T" + atime1 + ":" + atime2
          ).getTime();
          let finaltimeB = new Date(
            "1900-01-01T" + btime1 + ":" + btime2
          ).getTime();

          if (finaltimeA === finaltimeB) return 0;

          return finaltimeA < finaltimeB ? -1 : 1;
        });
        timeHHForSorting = [];

        nonTimeBased15to24.forEach((element) => {
          timeHHForSorting.push(element);
        });

        nonTimeBased1to10.forEach((element) => {
          timeHHForSorting.push(element);
        });
      }
      var finalArrayToSory: any[] = [];
      timeHHForSorting.forEach((element) => {
        for (let i = 0; i < scenesCnt; i++) {
          let tthhmmCheck: string = "";
          let dimOtLlv: string = "";
          if (this.rows.value[i].timeHHMM != null) {
            //tthhmmCheck = this.rows.value[i].timeHHMM.toString().split(":", "2")[0];
            tthhmmCheck = this.rows.value[i].timeHHMM.toString();
            dimOtLlv = this.rows.value[i].outputLevel.toString();
          }
          // if (Number(element) === Number(tthhmmCheck)) {
          if (
            element.split("_")[0] === tthhmmCheck &&
            element.split("_")[1] === dimOtLlv
          ) {
            finalArrayToSory.push(this.rows.value[i]);
            break;
          }
        }
      });

      for (let i = 0; i < finalArrayToSory.length; i++) {
        const element = finalArrayToSory[i];
        this.rows.controls[i]["controls"].timeHHMM.setValue(element.timeHHMM);
        this.rows.controls[i]["controls"].outputLevel.setValue(
          element.outputLevel
        );
        this.rows.controls[i]["controls"].fadeTime.setValue(element.fadeTime);
      }
      localStorage.setItem("dynaScenes", JSON.stringify(finalArrayToSory));
      this.defaultColor = false;
      this.generatePayloadDynaDimmer();
      //localStorage.setItem('dynaSaveSetData', JSON.stringify())
      this.apiService.setFinalDynaDimmerData(JSON.stringify(finalArrayToSory));
    }
    setTimeout(() => {
      if (!this.addForm.valid) {
        this.isDynaDataError = true;
      }
      this.apiService.sendisDynaValid(this.isDynaDataError);
    }, 500);
  }

  textValChanged(value) {
    let newValue: number = Number(value);

    setTimeout(() => {
      if (newValue > 120) {
        this.midnightShiftEditedValue = 120;
      } else if (newValue === -120) {
        this.midnightShiftEditedValue = -120;
      } else if (newValue === 120) {
        this.midnightShiftEditedValue = 120;
      } else if (newValue < -120) {
        this.midnightShiftEditedValue = -120;
      } else {
        if (!(value === "")) {
          this.midnightShiftEditedValue = Number(newValue);
        }
      }

      setTimeout(() => {
        this.apiService.sendDynaMidPointShiftValue(
          this.midnightShiftEditedValue
        );
      }, 1000);

      this.validateOrderOfHHMM();

      this.verifyDefaults();
    }, 0);

    // setTimeout(() => {
    //
    // }, 1000);
  }
  generatePayloadDynaDimmer() {
    let DynadimmerEnabled: any[] = [];
    let MidnightShift: any[] = [];
    let TimeReference: any[] = [];
    let OverrideMode: any[] = [];
    let featureParametersObj: any = null;
    let featureParaSceneObj: any[] = [];
    let Scene1 = null;
    let Scene2 = null;
    let Scene3 = null;
    let Scene4 = null;
    let Scene5 = null;
    let Scene6 = null;
    let Scene7 = null;
    let Scene8 = null;
    let Scene9 = null;
    let Scene10 = null;
    this.apiService.getDynaShowEnabled.subscribe((response) => {
      DynadimmerEnabled = [];
      DynadimmerEnabled.push("DynadimmerEnabled");
      if (response === true) {
        this.apiService.getDynaDimmerEnabledMode.subscribe((rsp) => {
          let enableddyna: boolean = Boolean(rsp);

          DynadimmerEnabled.push(enableddyna);
        });
      } else {
        DynadimmerEnabled.push(false);
      }
    });
    this.apiService.getDynaMidPointShiftValue.subscribe((response) => {
      MidnightShift = [];
      MidnightShift.push("MidnightShift");
      MidnightShift.push(response);
    });
    this.apiService.getDynaShowTimeReference.subscribe((response) => {
      TimeReference = [];
      TimeReference.push("TimeReference");
      if (response === true) {
        this.apiService.getDynaTimeReferencedMode.subscribe((rsp) => {
          let timerefenb: boolean = Boolean(rsp);
          TimeReference.push(timerefenb);
        });
      } else {
        TimeReference.push(false);
      }
    });
    this.getDynaShowOverrideEnabledSub =
      this.apiService.getDynaShowOverrideEnabled.subscribe((response) => {
        OverrideMode = [];
        OverrideMode.push("OverrideMode");
        if (response === true) {
          this.getDynaOverrideModeSub =
            this.apiService.getDynaOverrideMode.subscribe((rsp) => {
              let overrideEnb: boolean = Boolean(rsp);
              OverrideMode.push(overrideEnb);
            });
        } else {
          OverrideMode.push(false);
        }
      });

    let scenesData = JSON.parse(localStorage.getItem("dynaScenes"));

    for (let i = 0; i < scenesData.length; i++) {
      const element = scenesData[i];
      let hour = element.timeHHMM.split(":", 2)[0];
      let minute = element.timeHHMM.split(":", 2)[1];
      let dimLevel = element.outputLevel;
      let fadeTime = element.fadeTime;
      if (i === 0) {
        Scene1 = {
          Hour: Number(hour),
          Minute: Number(minute),
          DimLevel: Number(dimLevel),
          FadeTime: Number(fadeTime),
        };
      }
      if (i === 1) {
        Scene2 = {
          Hour: Number(hour),
          Minute: Number(minute),
          DimLevel: Number(dimLevel),
          FadeTime: Number(fadeTime),
        };
      }
      if (i === 2) {
        Scene3 = {
          Hour: Number(hour),
          Minute: Number(minute),
          DimLevel: Number(dimLevel),
          FadeTime: Number(fadeTime),
        };
      }
      if (i === 3) {
        Scene4 = {
          Hour: Number(hour),
          Minute: Number(minute),
          DimLevel: Number(dimLevel),
          FadeTime: Number(fadeTime),
        };
      }
      if (i === 4) {
        Scene5 = {
          Hour: Number(hour),
          Minute: Number(minute),
          DimLevel: Number(dimLevel),
          FadeTime: Number(fadeTime),
        };
      }
      if (i === 5) {
        Scene6 = {
          Hour: Number(hour),
          Minute: Number(minute),
          DimLevel: Number(dimLevel),
          FadeTime: Number(fadeTime),
        };
      }
      if (i === 6) {
        Scene7 = {
          Hour: Number(hour),
          Minute: Number(minute),
          DimLevel: Number(dimLevel),
          FadeTime: Number(fadeTime),
        };
      }
      if (i === 7) {
        Scene8 = {
          Hour: Number(hour),
          Minute: Number(minute),
          DimLevel: Number(dimLevel),
          FadeTime: Number(fadeTime),
        };
      }
      if (i === 8) {
        Scene9 = {
          Hour: Number(hour),
          Minute: Number(minute),
          DimLevel: Number(dimLevel),
          FadeTime: Number(fadeTime),
        };
      }
      if (i === 9) {
        Scene10 = {
          Hour: Number(hour),
          Minute: Number(minute),
          DimLevel: Number(dimLevel),
          FadeTime: Number(fadeTime),
        };
      }
    }

    var FinaPayloadDyna: any[] = [];

    if (Scene1 != null) featureParaSceneObj.push(Scene1);

    if (Scene2 != null) featureParaSceneObj.push(Scene2);

    if (Scene3 != null) featureParaSceneObj.push(Scene3);

    if (Scene4 != null) featureParaSceneObj.push(Scene4);

    if (Scene5 != null) featureParaSceneObj.push(Scene5);

    if (Scene6 != null) featureParaSceneObj.push(Scene6);

    if (Scene7 != null) featureParaSceneObj.push(Scene7);

    if (Scene8 != null) featureParaSceneObj.push(Scene8);

    if (Scene9 != null) featureParaSceneObj.push(Scene9);

    if (Scene10 != null) featureParaSceneObj.push(Scene10);

    featureParametersObj = {
      DynadimmerEnabled: DynadimmerEnabled[1],
      MidnightShift: Number(MidnightShift[1]),
      TimeReference: TimeReference[1],
      OverrideMode: OverrideMode[1],
      Scene1,
      Scene2,
      Scene3,
      Scene4,
      Scene5,
      Scene6,
      Scene7,
      Scene8,
      Scene9,
      Scene10,
    };
    if (
      this.showDynaDimmerEnabledProp === true &&
      this.showDynaTimeReferenceProp === true &&
      this.showDynaOverrideProp === true
    ) {
      //12 D, T, O
      featureParametersObj = {
        DynadimmerEnabled: DynadimmerEnabled[1],
        MidnightShift: Number(MidnightShift[1]),
        TimeReference: TimeReference[1],
        OverrideMode: OverrideMode[1],
        Scene1,
        Scene2,
        Scene3,
        Scene4,
        Scene5,
        Scene6,
        Scene7,
        Scene8,
        Scene9,
        Scene10,
      };
    } else if (
      this.showDynaDimmerEnabledProp === true &&
      this.showDynaTimeReferenceProp === true &&
      this.showDynaOverrideProp === false
    ) {
      //11 D, T
      featureParametersObj = {
        DynadimmerEnabled: DynadimmerEnabled[1],
        MidnightShift: Number(MidnightShift[1]),
        TimeReference: TimeReference[1],
        Scene1,
        Scene2,
        Scene3,
        Scene4,
        Scene5,
        Scene6,
        Scene7,
        Scene8,
        Scene9,
        Scene10,
      };
    } else if (
      this.showDynaDimmerEnabledProp === true &&
      this.showDynaTimeReferenceProp === false &&
      this.showDynaOverrideProp === true
    ) {
      //10 D, O
      featureParametersObj = {
        DynadimmerEnabled: DynadimmerEnabled[1],
        MidnightShift: Number(MidnightShift[1]),
        OverrideMode: OverrideMode[1],
        Scene1,
        Scene2,
        Scene3,
        Scene4,
        Scene5,
        Scene6,
        Scene7,
        Scene8,
        Scene9,
        Scene10,
      };
    } else if (
      this.showDynaDimmerEnabledProp === false &&
      this.showDynaTimeReferenceProp === true &&
      this.showDynaOverrideProp === true
    ) {
      featureParametersObj = {
        MidnightShift: Number(MidnightShift[1]),
        TimeReference: TimeReference[1],
        OverrideMode: OverrideMode[1],
        Scene1,
        Scene2,
        Scene3,
        Scene4,
        Scene5,
        Scene6,
        Scene7,
        Scene8,
        Scene9,
        Scene10,
      };
    } else if (
      this.showDynaDimmerEnabledProp === false &&
      this.showDynaTimeReferenceProp === true &&
      this.showDynaOverrideProp === false
    ) {
      //76206 T Only
      featureParametersObj = {
        MidnightShift: Number(MidnightShift[1]),
        TimeReference: TimeReference[1],
        Scene1,
        Scene2,
        Scene3,
        Scene4,
        Scene5,
        Scene6,
        Scene7,
        Scene8,
        Scene9,
        Scene10,
      };
    } else if (
      this.showDynaDimmerEnabledProp === true &&
      this.showDynaTimeReferenceProp === false &&
      this.showDynaOverrideProp === false
    ) {
      //09
      featureParametersObj = {
        DynadimmerEnabled: DynadimmerEnabled[1],
        MidnightShift: Number(MidnightShift[1]),
        Scene1,
        Scene2,
        Scene3,
        Scene4,
        Scene5,
        Scene6,
        Scene7,
        Scene8,
        Scene9,
        Scene10,
      };
    }

    featureParametersObj;
    if (featureParametersObj.Scene1 == null) {
      delete featureParametersObj.Scene1;
    }
    if (featureParametersObj.Scene2 == null) {
      delete featureParametersObj.Scene2;
    }
    if (featureParametersObj.Scene3 == null) {
      delete featureParametersObj.Scene3;
    }
    if (featureParametersObj.Scene4 == null) {
      delete featureParametersObj.Scene4;
    }
    if (featureParametersObj.Scene5 == null) {
      delete featureParametersObj.Scene5;
    }
    if (featureParametersObj.Scene6 == null) {
      delete featureParametersObj.Scene6;
    }
    if (featureParametersObj.Scene7 == null) {
      delete featureParametersObj.Scene7;
    }
    if (featureParametersObj.Scene8 == null) {
      delete featureParametersObj.Scene8;
    }
    if (featureParametersObj.Scene9 == null) {
      delete featureParametersObj.Scene9;
    }
    if (featureParametersObj.Scene10 == null) {
      delete featureParametersObj.Scene10;
    }

    FinaPayloadDyna.push(featureParametersObj);

    let JSONDynaObject = {
      featureName: "dynadim",
      featureParameters: FinaPayloadDyna,
    };
    localStorage.setItem("dynaSaveSetData", JSON.stringify(FinaPayloadDyna));
    this.sendDynaDimmerdata(FinaPayloadDyna[0]);
  }
  checkAddesRowValuesChanged(index) {
    this.rows.controls[index]["controls"].timeHHMM.valueChanges.subscribe(
      (value) => {
        setTimeout(() => {
          if (this.addForm.valid) {
            if (this.rowLength === this.maxRowCount) {
              this.disableAddBtn = true;
            } else {
              this.disableAddBtn = false;
            }
          } else this.disableAddBtn = true;
        }, 100);

        setTimeout(() => {
          if (this.addForm.valid) {
            this.disableAddAboveBtn = false;
            this.disableAddBelowBtn = false;
          } else {
            this.disableAddAboveBtn = true;
            this.disableAddBelowBtn = true;
          }
        }, 100);
      }
    );
    this.rows.controls[index]["controls"].outputLevel.valueChanges.subscribe(
      (value) => {
        this.submitted = false;
        this.tableValueSubmit = true;
        setTimeout(() => {
          if (this.addForm.valid) {
            if (this.rowLength === this.maxRowCount) {
              this.disableAddBtn = true;
            } else {
              this.disableAddBtn = false;
            }
          } else {
            this.disableAddBtn = true;
          }
        }, 100);

        setTimeout(() => {
          if (this.addForm.valid) {
            this.disableAddAboveBtn = false;
            this.disableAddBelowBtn = false;
          } else {
            this.disableAddAboveBtn = true;
            this.disableAddBelowBtn = true;
          }
        }, 100);
      }
    );
    this.rows.controls[index]["controls"].fadeTime.valueChanges.subscribe(
      (value) => {
        this.submitted = false;
        this.tableValueSubmit = true;
        setTimeout(() => {
          if (this.addForm.valid) {
            if (this.rowLength === this.maxRowCount) {
              this.disableAddBtn = true;
            } else {
              this.disableAddBtn = false;
            }
          } else this.disableAddBtn = true;
        }, 100);

        setTimeout(() => {
          if (this.addForm.valid) {
            this.disableAddAboveBtn = false;
            this.disableAddBelowBtn = false;
          } else {
            this.disableAddAboveBtn = true;
            this.disableAddBelowBtn = true;
          }
        }, 100);
      }
    );

    if (this.rows.length > this.maxRowCount - 1) {
      this.verifyDefaults();
    }
    this.validateOrderOfHHMM();
  }

  createItemFormGroup(): FormGroup {
    var isDisabledForm = this.disable === true ? true : false;
    return this.fb.group({
      timeHHMM: [
        null,
        [
          Validators.required,
          Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"),
        ],
      ],
      outputLevel: [
        null,
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(0),
          Validators.max(100),
        ],
      ],
      fadeTime: [
        null,
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(0),
          Validators.max(255),
        ],
      ],
    });
  }

  clearTableValueFields() {
    this.clearValues();
    setTimeout(() => {
      this.isclearTableValueFieldscalled = true;
      this.addDefaultRowSet();
    }, 100);
    this.defaultColor = false;
    // this.apiService.setDynaDefaultColor(this.defaultColor);
    this.disableAddBtn = false;
    this.disableAddAboveBtn = false;
    this.disableAddBelowBtn = false;
  }

  clearValues() {
    this.unsubscribe.next();
    this.isNumericCreatedRows = false;
    this.rows.clear();
    this.onAddRow();
    //this.dataToShow = [];
    //this.dataToShow.push({ hour: 0, power: 100 })
    this.rows.controls[0]["controls"].timeHHMM.setValue("");
    this.rows.controls[0]["controls"].outputLevel.setValue("");
    this.rows.controls[0]["controls"].fadeTime.setValue("");
    this.defaultColor = true;
    //this.apiService.setDynaDefaultColor(this.defaultColor);
    //this.apiService.sendGraphIsEditedData([{ hour: 0, power: 100 }]);
    //this.apiService.sendDynaGraphData(this.graphRepresentationType, this.dataToShow);
    this.tableValueSubmit = false;
    this.isTableValuesChanged = false;
  }

  enableDynaGraph(value) {
    this.dynaEnableMode = value;
    if (this.dynaEnableMode) {
      this.dynaEnableMode = true;
      this.disable = false;
      this.apiService.sendDynaDimmerEnabledMode(true);
    } else {
      this.dynaEnableMode = false;
      this.disable = true;
      this.apiService.sendDynaDimmerEnabledMode(false);
    }
    this.verifyDefaults();
    this.validateOrderOfHHMM();
  }
  timeBaseclicked() {
    this.apiService.sendDynaTimeReferenceMode(this.dynaTimeReferenceMode);
    this.verifyDefaults();
    this.validateOrderOfHHMM();
  }
  toggleTimeReference(value) {
    this.testInput.nativeElement.setSelectionRange(0, 0);

    this.testInput.nativeElement.focus();

    setTimeout(() => {
      this.testInput.nativeElement.dispatchEvent(
        new KeyboardEvent("keydown", { key: "Tab" })
      );
    }, 0);

    if (Boolean(value) === true) {
      this.dynaTimeReferenceMode = true;
      this.dynaMidNightShiftMode = false;
      this.apiService.sendDynaTimeReferenceMode(true);
      this.apiService.sendDynaMidPointShiftMode(false);
    } else if (Boolean(value) === false) {
      this.dynaTimeReferenceMode = false;
      this.dynaMidNightShiftMode = true;

      this.apiService.sendDynaTimeReferenceMode(false);
      this.apiService.sendDynaMidPointShiftMode(true);
    }

    this.verifyDefaults();
    this.validateOrderOfHHMM();
  }

  toggleOverrideMode(value) {
    if (value === true) {
      this.dynaOverrideMode = true;
    } else {
      this.dynaOverrideMode = false;
    }
    this.apiService.sendDynaOveerideMode(this.dynaOverrideMode);

    this.verifyDefaults();
    this.validateOrderOfHHMM();
  }
  verifyDefaults() {


    let defaultCheck = true;
    let sceneChnaged = false;
    const dynaDimmerReadSchema = JSON.parse(
      localStorage.getItem("dynaDimmerSchema")
    );
    const scenes =
      dynaDimmerReadSchema[0]["properties"]["NumberOfScenes"]["default"];
    if (this.rows.length === 1) {
      var time = this.rows.controls[0]["controls"].timeHHMM.value;
      var outpt = this.rows.controls[0]["controls"].outputLevel.value;
      var fadetime = this.rows.controls[0]["controls"].fadeTime.value;
      var hourDefault =
        dynaDimmerReadSchema[0]["properties"]["Scene1"]["properties"]["Hour"][
          "default"
        ];
      var minuteDefault =
        dynaDimmerReadSchema[0]["properties"]["Scene1"]["properties"]["Minute"][
          "default"
        ];
      const fadeTimeDefault =
        dynaDimmerReadSchema[0]["properties"]["Scene1"]["properties"][
          "FadeTime"
        ]["default"];
      const outputLevelDefault =
        dynaDimmerReadSchema[0]["properties"]["Scene1"]["properties"][
          "DimLevel"
        ]["default"];
      hourDefault = hourDefault <= 9 ? "0" + hourDefault : hourDefault;
      minuteDefault = minuteDefault <= 9 ? "0" + minuteDefault : minuteDefault;
      if (hourDefault <= 9) {
        hourDefault = parseInt(hourDefault);
        if (time != `${hourDefault}` + ":" + `${minuteDefault}`) {
          defaultCheck = false;
        }
      } else if (
        !isNullOrUndefined(time) &&
        time != `${hourDefault}` + ":" + `${minuteDefault}`
      ) {
        defaultCheck = false;
      }
      if (
        !isNullOrUndefined(outpt) &&
        String(outpt) != outputLevelDefault.toString()
      ) {
        defaultCheck = false;
      }
      if (
        !isNullOrUndefined(fadetime) &&
        String(fadetime) != fadeTimeDefault.toString()
      ) {
        defaultCheck = false;
      }
    } else if (this.rows.length > scenes) {
      defaultCheck = false;
    } else {
      for (let i = 0; i <= scenes - 1; i++) {
        let k = i + 1;
        var time = this.rows.controls[i]["controls"].timeHHMM.value;
        var outpt = this.rows.controls[i]["controls"].outputLevel.value;
        var fadetime = this.rows.controls[i]["controls"].fadeTime.value;
        var hourDefault =
          dynaDimmerReadSchema[0]["properties"]["Scene" + `${k}`]["properties"][
            "Hour"
          ]["default"];
        var minuteDefault =
          dynaDimmerReadSchema[0]["properties"]["Scene" + `${k}`]["properties"][
            "Minute"
          ]["default"];
        var fadeTimeDefault =
          dynaDimmerReadSchema[0]["properties"]["Scene" + `${k}`]["properties"][
            "FadeTime"
          ]["default"];
        var outputLevelDefault =
          dynaDimmerReadSchema[0]["properties"]["Scene" + `${k}`]["properties"][
            "DimLevel"
          ]["default"];
        hourDefault = hourDefault <= 9 ? "0" + hourDefault : hourDefault;
        minuteDefault =
          minuteDefault <= 9 ? "0" + minuteDefault : minuteDefault;
        if (hourDefault <= 9) {
          hourDefault = parseInt(hourDefault);
          minuteDefault = parseInt(minuteDefault);
          hourDefault = hourDefault <= 9 ? "0" + hourDefault : hourDefault;
          minuteDefault =
            minuteDefault <= 9 ? "0" + minuteDefault : minuteDefault;
          if (
            !isNullOrUndefined(time) &&
            time != `${hourDefault}` + ":" + `${minuteDefault}`
          ) {
            defaultCheck = false;
          }
        } else if (
          !isNullOrUndefined(time) &&
          time != `${hourDefault}` + ":" + `${minuteDefault}`
        ) {
          defaultCheck = false;
        }
        if (
          !isNullOrUndefined(outpt) &&
          String(outpt) != outputLevelDefault.toString()
        ) {
          defaultCheck = false;
        }
        if (
          !isNullOrUndefined(fadetime) &&
          !isNullOrUndefined(fadeTimeDefault)
        ) {
          if (String(fadetime) != fadeTimeDefault.toString()) {
            defaultCheck = false;
          }
        }
      }
    }

    if (
      this.showDynaDimmerEnabledProp != undefined &&
      this.showDynaDimmerEnabledProp
    ) {
      if (
        this.dynaDefaultEnabled != undefined &&
        !(this.dynaDefaultEnabled === this.dynaEnableMode)
      ) {
        defaultCheck = false;
      }
    }
    if (
      this.showDynaTimeReferenceProp != undefined &&
      this.showDynaTimeReferenceProp
    ) {
      if (
        this.dynaDefaultTimeBased != undefined &&
        !(this.dynaDefaultTimeBased === this.dynaTimeReferenceMode)
      ) {
        defaultCheck = false;
      }
    }
    if (this.showDynaOverrideProp != undefined && this.showDynaOverrideProp) {
      if (
        this.dynaDefaultOverride != undefined &&
        !(this.dynaDefaultOverride === this.dynaOverrideMode)
      ) {
        defaultCheck = false;
      }
    }
    if (
      this.midnightShiftEditedValue != undefined &&
      (!(Number(this.midnightShiftEditedValue) === 0) ||
        this.midnightShiftEditedValue === null)
    )
      defaultCheck = false;

    if (
      !(Number(this.midnightShiftEditedValue) === 0) ||
      this.midnightShiftEditedValue === null
    )
      defaultCheck = false;
    // if (sceneChnaged === true) {
    //   defaultCheck = false;
    // }
    // if(this.dynaDefaultEnabled===this.dynaEnableMode && this.dynaDefaultTimeBased === this.dynaTimeReferenceMode && this.dynaDefaultOverride=== this.dynaOverrideMode && this.midnightShiftEditedValue===0 && sceneChnaged===false)
    // this.apiService.setDynaDefaultColor(true);
    // else
    // this.apiService.setDynaDefaultColor(false);
    // setTimeout(() => {
    this.apiService.setDynaDefaultColor(defaultCheck);
    this.apiService.setDynaDimmerEdited(true);
    // }, 500);
  }

  AddRow() {
    //this.apiService.setDynaDefaultColor(false);
    this.onAddRow();
    let rowCnt = this.rows.length;
    if (rowCnt > 1 && rowCnt < this.maxRowCount + 1) {
      setTimeout(() => {
        this.validateOrderOfHHMM();
        this.addIncrementDefaultValues(rowCnt);
      }, 10);
    }
    if (rowCnt >= this.maxRowCount) {
      this.disableAddBtn = true;
      this.disableAddAboveBtn = true;
      this.disableAddBelowBtn = true;
    }
    this.testInput.nativeElement.setSelectionRange(0, 0);

    this.testInput.nativeElement.focus();

    setTimeout(() => {
      this.testInput.nativeElement.dispatchEvent(
        new KeyboardEvent("keydown", { key: "Tab" })
      );
    }, 0);
  }
  addIncrementDefaultValues(rowCnt: number) {
    var rowCntRead = rowCnt - 2;

    var time = this.rows.controls[rowCntRead]["controls"].timeHHMM.value;
    var outpt = this.rows.controls[rowCntRead]["controls"].outputLevel.value;
    var fadeTime = this.rows.controls[rowCntRead]["controls"].fadeTime.value;

    const dynaDimmerReadSchema = JSON.parse(
      localStorage.getItem("dynaDimmerSchema")
    );
    this.defaultColor = false;
    this.apiService.setDynaDefaultColor(this.defaultColor);
    if (
      this.dynaTimeReferenceMode === false ||
      this.dynaTimeReferenceMode === true
    ) {
      var sceneval = rowCntRead + 2;

      if (
        (sceneval === 2 && this.isclearTableValueFieldscalled) ||
        sceneval === 3 ||
        sceneval === 4 ||
        sceneval === 5 ||
        sceneval === 6 ||
        sceneval === 7 ||
        sceneval === 8 ||
        sceneval === 9 ||
        sceneval === 10 ||
        rowCnt === 2
      ) {
        var fadeTimeDefault: number = 0;
        var outputLevelDefault: number =
          Number(this.rows.controls[rowCntRead]["controls"].outputLevel.value) +
          10;
        if (outputLevelDefault > 100)
          outputLevelDefault = outputLevelDefault - 100;
        var timeInc: string[] =
          this.rows.controls[rowCntRead]["controls"].timeHHMM.value.split(":");
        timeInc[0] = timeInc[0].replace(/^0+/, "");

        timeInc[1] = Number(timeInc[1]).toString();
        if (Number(timeInc[0]) <= 9) {
          var time = Number(timeInc[0]) + 1;
          if (time < 10) {
            timeInc[0] = "0" + time.toString();
          } else {
            timeInc[0] = time.toString();
          }
        } else if (Number(timeInc[0]) === 23) {
          var time = Number(0);
          timeInc[0] = "0" + time.toString();
        } else if (Number(timeInc[0]) > 9 && Number(timeInc[0]) <= 23) {
          var time = Number(timeInc[0]) + 1;
          timeInc[0] = time.toString();
        }
        if (Number(timeInc[1]) <= 9) {
          var sec = Number(timeInc[1]);
          if (sec != 10) {
            timeInc[1] = "0" + sec.toString();
          } else {
            timeInc[1] = sec.toString();
          }
        } else {
          timeInc[1] = Number(timeInc[1]).toString();
        }
        var hourDefault: any = timeInc[0].concat(":" + timeInc[1]);
        time = hourDefault;
        outpt = outputLevelDefault;
        fadeTime = fadeTimeDefault;
      } else if (sceneval === 1 && this.isclearTableValueFieldscalled) {
        var fadeTimeDefault: number = 0;
        var outputLevelDefault: number = 100;
        this.rows.controls[0]["controls"].timeHHMM.setValue("12:00");
        this.rows.controls[0]["controls"].outputLevel.setValue(
          outputLevelDefault
        );
        this.rows.controls[0]["controls"].fadeTime.setValue(fadeTimeDefault);
        this.addForm.addControl("rows", this.rows);
        this.rowLength = this.rows.length;
        localStorage.setItem(
          "dynaScenes",
          JSON.stringify({
            timeHHMM: "12:00",
            outputLevel: outputLevelDefault,
            fadeTime: fadeTimeDefault,
          })
        );
      } else {
        var hourDefault: any =
          dynaDimmerReadSchema[0]["properties"]["Scene" + `${sceneval}`][
            "properties"
          ]["Hour"]["default"];
        var minuteDefault =
          dynaDimmerReadSchema[0]["properties"]["Scene" + `${sceneval}`][
            "properties"
          ]["Minute"]["default"];
        var outputLevelDefault: number =
          dynaDimmerReadSchema[0]["properties"]["Scene" + `${sceneval}`][
            "properties"
          ]["DimLevel"]["default"];
        var fadeTimeDefault: number =
          dynaDimmerReadSchema[0]["properties"]["Scene" + `${sceneval}`][
            "properties"
          ]["FadeTime"]["default"];

        hourDefault =
          Number(hourDefault) <= 9
            ? "0" + Number(hourDefault).toString()
            : Number(hourDefault).toString();
        hourDefault = hourDefault.replace(/^0+/, "");
        if (hourDefault != Number(10).toString()) {
          hourDefault = "0" + hourDefault;
        } else {
          hourDefault = hourDefault;
        }
        minuteDefault =
          Number(minuteDefault) <= 9
            ? "0" + Number(minuteDefault).toString()
            : Number(minuteDefault).toString();
        minuteDefault = minuteDefault.replace(/^0+/, "");
        if (minuteDefault != Number(10).toString()) {
          minuteDefault = "0" + minuteDefault;
        } else {
          minuteDefault = minuteDefault;
        }
        time = `${hourDefault}` + ":" + `${minuteDefault}`;
        outpt = outputLevelDefault;
        fadeTime = fadeTimeDefault;
      }
    }
    var rowCntWrite = rowCnt - 1;
    this.rows.controls[rowCntWrite]["controls"].timeHHMM.setValue(time);
    this.rows.controls[rowCntWrite]["controls"].outputLevel.setValue(outpt);
    this.rows.controls[rowCntWrite]["controls"].fadeTime.setValue(fadeTime);
  }
  moMouseAddEnter() {
    if (!this.addForm.valid) {
      this.disableAddBtn = true;
      this.disableAddAboveBtn = true;
      this.disableAddBelowBtn = true;
    } else {
      this.disableAddBtn = false;
      this.disableAddAboveBtn = false;
      this.disableAddBelowBtn = false;
    }
    if (this.rows.length >= this.maxRowCount) {
      this.disableAddBtn = true;
      this.disableAddAboveBtn = true;
      this.disableAddBelowBtn = true;
    } else {
      this.disableAddBtn = false;
      this.disableAddAboveBtn = false;
      this.disableAddBelowBtn = false;
    }
  }
  RemoveRow(index) {
    this.rows.removeAt(index);
    this.rowLength = this.rows.length;

    if (this.rowLength < this.maxRowCount) {
      this.disableAddBtn = false;
    }

    //this.defaultColor = false;

    const dynaDimmerReadSchema = JSON.parse(
      localStorage.getItem("dynaDimmerSchema")
    );
    const scenes =
      dynaDimmerReadSchema[0]["properties"]["NumberOfScenes"]["default"];

    if (this.rowLength == scenes) {
      this.validateOrderOfHHMM();
      this.verifyDefaults();
    }
  }

  onRightClick($event, index) {
    this.selectedRow = index;
    event.stopPropagation();
    this.menuState = true;
    this.positionMenu(event);

    if (
      this.rowLength === this.maxRowCount ||
      this.rowLength > this.maxRowCount
    ) {
      this.disableAddBtn = true;
      this.disableAddAboveBtn = true;
      this.disableAddBelowBtn = true;
    }
  }

  addRowPosition(rowType) {
    if (this.rowLength < this.maxRowCount) {
      if (rowType === "Above")
        this.rows.insert(this.selectedRow, this.createItemFormGroup());

      if (rowType === "Below")
        this.rows.insert(this.selectedRow + 1, this.createItemFormGroup());

      this.rowLength = this.rows.length;

      setTimeout(() => {
        if (this.rowLength === this.maxRowCount) {
          this.disableAddBtn = true;
          this.disableAddAboveBtn = true;
          this.disableAddBelowBtn = true;
        } else {
          this.disableAddBtn = false;
          this.disableAddAboveBtn = false;
          this.disableAddBelowBtn = false;
        }
        if (!this.addForm.valid) {
          this.disableAddBtn = true;
          this.disableAddAboveBtn = true;
          this.disableAddBelowBtn = true;
          this.verifyDefaults();
        } else {
          this.disableAddBtn = false;
          this.disableAddAboveBtn = false;
          this.disableAddBelowBtn = false;
          this.verifyDefaults();
        }
      }, 0);

      this.checkAddesRowValuesChanged(this.selectedRow);
    }
  }

  getPosition(e) {
    let posx = 0;
    let posy = 0;
    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx =
        e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      posy =
        e.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }
    return { x: posx, y: posy };
  }

  positionMenu(e) {
    this.menuPosition = this.getPosition(e);
    this.menuPositionX = this.menuPosition.x;
    this.menuPositionY = this.menuPosition.y;
    this.cdRef.detectChanges();
    this.menuWidth = this.contextMenu.nativeElement.offsetWidth;
    this.menuHeight = this.contextMenu.nativeElement.offsetHeight;
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    if (Math.abs(this.windowWidth - this.menuPositionX) < this.menuWidth) {
      this.menuPositionX = this.windowWidth - this.menuWidth - 250 + "px";
    } else {
      //this.menuPositionX = this.menuPositionX - 250+ "px";
      this.menuPositionX = 190 + "px";
    }
    if (Math.abs(this.windowHeight - this.menuPositionY) < this.menuHeight) {
      this.menuPositionY = this.windowHeight - this.menuHeight - 50 + "px";
    } else {
      this.menuPositionY = this.menuPositionY - 50 + "px";
    }
  }
  @HostListener("document:click", ["$event"])
  public documentClick(event: Event): void {
    this.menuState = false;
  }
  @HostListener("document:contextmenu", ["$event"])
  public documentRClick(event: Event): void {
    this.menuState = false;
  }

  ngOnDestroy() {
    this.unsubscribeGraphType.next();
    this.unsubscribeNumeric.next();
    // this.subscription.unsubscribe();
    if (this.dynaMidPointShiftValueSub) {
      this.dynaMidPointShiftValueSub.unsubscribe();
    }
    if (this.getDynaMidPointShiftValueSub) {
      this.getDynaMidPointShiftValueSub.unsubscribe();
    }
    if (this.getDynaMidPointSHiftDefaultPropertiesSub) {
      this.getDynaMidPointSHiftDefaultPropertiesSub.unsubscribe();
    }
    if (this.graphDynaData) {
      this.graphDynaData.unsubscribe();
    }
    if (this.dynaDefaultValue) {
      this.dynaDefaultValue.unsubscribe();
    }
    if (this.getDynaOverrideModeSub) {
      this.getDynaOverrideModeSub.unsubscribe();
    }

    if (this.getDynaShowOverrideEnabledSub) {
      this.getDynaShowOverrideEnabledSub.unsubscribe();
    }
  }

  calculateMidnightShiftTime() {
    const modalRef = this.modalService.open(MidnightShiftCalculationComponent, {
      backdrop: "static",
      keyboard: false,
      windowClass: "select-device-modal",
      centered: true,
    });
  }
}
